<template>

  <div>
    <v-text-field
        light flat
        v-model="timeLocal"
        :label="label"
        :rules="rules"
        @focus="onFocus"
        @blur="onBlur"
        :background-color="backgroundColor"
        :outlined="outlined"

    />

  </div>

</template>

<script>

  export default {
    props: {
      value: {
        default: false,
        type: String
      },
      mode: {
        default: 'ap',
        type: String
      },
      rules: {
          type: Array,
          default: () => []
      },  
      label: {
        default: 'Time',
        type: String
      },
      outlined: {
          default: false,
          type: Boolean
      },
      backgroundColor: {
          default: 'white',
          type: String
      },
    },
    components: {
    },
    data: () => ({
      initialized: false,
    }),
    mounted() {
      console.log('this.value', this.value)
      this.$emit('input', this.value)
      setTimeout(() => {
        this.onBlur()
      }, 250);
    },
    methods: {
      switchType(time, value) {
        let element = this.$el.querySelector('input')
        element.setAttribute('type', time ? 'time':'text')
        if (value) {
          console.log('setting value', value)
          element.value = value
        }
      },
      onFocus(event) {
        console.log('focus time', this.value)
        console.log('element value', event.value)
        this.switchType(true)
      },
      onBlur(event) {
        let element = this.$el.querySelector('input')
console.log('blur value', element.value)        
        this.switchType(false, this.formatAp(element.value))
      },
      formatAp(time) {
        if (time.indexOf(':')>=0) {
          let pieces = time.split(':')
          let ap='AM'
          
          let pieces0 = parseInt(pieces[0])
          if (pieces0 >= 12) {
            if (pieces0 > 12) {
              pieces[0] = this.twoDigit(parseInt(pieces[0]-12))
            }
            ap='PM'
          }
          return `${pieces[0]}:${pieces[1]} ${ap}`
        }
      },
      twoDigit (num) {
          let str = num + ''
          if (str.length === 1) {
              str = '0' + str
          }
          return str
      },

    },
    computed: {
      timeLocal: {
          get: function() {
              return this.value;
          },
          set: function(newValue) {
            this.$emit('input', newValue)
          }
      }      
    },
    watch: {
      value (newValue, oldValue) {
        setTimeout(() => {
          this.onBlur()
        }, 250);
      }
    }
  }
</script>

