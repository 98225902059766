<template>
    <div>
      <v-list
        dense
        class="pa-0 ma-0"
        style="height: 200px;overflow-y:auto;"
      >
        <template
          v-for="(item,i) in colors"
        >

          <v-list-item 
            :key="`user_${item.id}_${i}`"
            @click="change(item)"
            :class="`contact pa-0 ma-0`"
            :style="`background-color: ${item.bg};color: ${item.fg}`"
          >

            <v-list-item-content
              class="pa-0 ma-0"
            >
              <v-list-item-title
                class="pa-0 ma-0"
              >
                {{provider ? `${provider.first_name} ${provider.last_name}` : item.bg}}
              </v-list-item-title>
             
            </v-list-item-content>       
          </v-list-item>

        </template>      

      </v-list>

    </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

  export default {
    props: {
      groupId : {
        type: String,
        default: ''
      },    
      userIndexEdits : {
        type: Object,
        default: () => {}
      }, 
      provider : {
        type: Object,
        default: () => {}
      },               
    },
    data: () => ({
      menu: false,
    }),
    mounted() {     
    },
    methods: {
        change(item) {
            let idx=item.id
            this.$emit('close', {provider_id: this.provider.id, index: idx})
        }
    },
    computed: {
      ...mapGetters(
          'schedule',['getColors',]
      ), 

      colorIndex : {
          get: function() {      

            if (this.provider) {
              let index = this.userIndexEdits[this.provider.id]
              if (index >= 0) {
                return `id${index}`
              } else {
                return `id${this.provider.user_index}`
              }
            }
            return null
          },
          set: function(newValue) {   
            let idx = parseInt(newValue.replace('id',''))
            //set this further up the chain.  We will emit the close event and eventually it will update the userIndexEdits
            //this.$set(this.userIndexEdits,this.provider.id, idx)
          }
      },             
      colors () {

        let group = this.$store.getters['schedule/getGroupById'](this.groupId)

        let used_indexes = {}

        let provider_id = this.provider.id
        if (group) {
          let user_indexes = group.user_indexes
          for (let id in user_indexes) {
            let index = user_indexes[id]

            if (index >= 0 && id !== provider_id) {
              used_indexes[`id${index}`] = true
            }
          }
        }

        let arr = []
        for (let i=0;i<this.getColors.length;i++) {
          let used = used_indexes[`id${i}`]

          if (!used) {

            let c = this.getColors[i]
            arr.push({
              fg: c[1],
              bg: c[0],
              id: i,
              key: `id${i}`,
              name: c[0]
              })

          }
        }

        return arr
      }

    },
    watch: {

    }

  }
</script>

