<template>

    <div >

        <div
            v-if="personalCoverage && personalCoverage.length"
            :key="`item_${date}`"
            :id="`shift_${item.id}_${date}`"
            @click.stop="selectShift(item)"
            :class="`contact pa-0 ma-0`"
            :style="item.style"
        >

            <v-tooltip bottom
                        nudge-bottom="5"
                        color="ycmd-dark-blue"
                        >
                    <template v-slot:activator="{ on, attrs }">

                    <span
                    v-bind="attrs"
                    v-on="on"                    
                    class="pa-0 ma-0"
                    >
                        {{item.name}}
                    </span>

                </template>
             
                <div
                    v-html="toolTipHtml"
                ></div>                
             
            </v-tooltip>

        </div>

    </div>

</template>

<script>
import {mapActions, mapGetters} from "vuex";

    export default {
        name: 'scheduleShift',
        components: {
        },
        props: {
            providers: {
                type: Array,
                default: () => []
            },
            date: {
                type: String,
                default: ''
            },
            personalCoverage: {
                type: [],
                default: () => []
            },    
            providerMap: {
                type: Object,
                default: () => {}
            },                         
            dayWidth: {
                type: Number,
                default: 165
            },         
        },
        data: () => ({
            //selectedDuration: '',
        }),
        created() {

        },
        async mounted() {

        },
        watch: {
        },
        methods: {
            getNameHtml(id) {
                let provider = this.providerMap[id]
                if (provider) {
                    let color = this.getColors[provider.user_index]
                    let style

                    if (color) {
                        style = `style="color: ${color[1]};background-color: ${color[0]};"`
                    }
                    return `<span ${style}> ${provider.first_name} ${provider.last_name} </span>`
                }
            },

            pcHtml(pc) {
                let html = `${this.getNameHtml(pc.personal_coverer)} covers for ${this.getNameHtml(pc.personal_coveree)}`
                return html
            },
            selectShift (shift) {
                this.$emit('shiftClick',{date: this.date, shift: shift})
            },
            twoDigit (num) {
                let str = num + ''
                if (str.length === 1) {
                    str = '0' + str
                }
                return str
            },

            getAssignmentGradient (personalCoverage) {

                let diff = 5

                let j = 0
                let i = 0
                let newGradient = ''

                let coverer = this.providerMap[personalCoverage.personal_coverer]
                let covererColorTemp = this.getColors[this.getColors.length-1]

                if (coverer && coverer.user_index >= 0) {
                    covererColorTemp = this.getColors[coverer.user_index]
                }

                coverer.fg = covererColorTemp[1]
                coverer.bg = covererColorTemp[0]

                let coveree = this.providerMap[personalCoverage.personal_coveree]
                let covereeColorTemp = this.getColors[this.getColors.length-1]
                
                if (coveree && coveree.user_index >= 0) {
                    covereeColorTemp = this.getColors[coveree.user_index]
                }

                coveree.fg = covereeColorTemp[1]
                coveree.bg = covereeColorTemp[0]

                while (i <= 100) {
//                    gradientStyle = 'linear-gradient(45deg,#911eb4 25%, #e6194b 25%, #e6194b 50%, #911eb4 50%, #911eb4 75%, #e6194b 75%, #e6194b 100%)'                   
                    if (newGradient) {
                        newGradient +=', '
                    }

                    let odd = (j++ % 2)
                    
                    if (i > 0 ) {
                        if (odd) {
                            newGradient += ` ${coveree.bg} ${i}% `
                            if (i < 100) {
                                newGradient += `, ${coverer.bg} ${i}% `
                            }
                        } else {
                            newGradient += ` ${coverer.bg} ${i}% `
                            if (i < 100) {
                                newGradient += `, ${coveree.bg} ${i}% `
                            }
                        }
                    }

                    i += diff

                }

                let coverageGradient = `linear-gradient(45deg,${newGradient})`
                return coverageGradient


            },


            calculateStyle () {
                let coverages = this.uniqueCoverages

                let gradients = new Array(coverages.length)

                for (let i = 0;i<coverages.length;i++) {
                    gradients[i] = this.getAssignmentGradient(coverages[i])
                }

                let ts = "text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;"
                let gradientStyle
                let style

                if (gradients.length === 1) {                    
                    gradientStyle = gradients[0]
                    style = `${ts}color: #ffffff;width: 100%;height: 100%;background-image:${gradientStyle}`
                } else {
                  
                    let increment = 100 / gradients.length

                    gradientStyle = ''
                                        
                    let backgroundPosition=""
                    let backgroundSize=""

                    for (let i=0;i<gradients.length;i++) {
                
                        let pixels = (i*increment / 100) * this.dayWidth

                        //let current = `${i*increment}%`
                        let currentPixels = `${pixels}px`

                        if (gradientStyle) {
                            gradientStyle += ','
                        }
                        gradientStyle += gradients[i]

                        if (backgroundPosition) {
                            backgroundPosition += ','
                        }
                        backgroundPosition += `left ${currentPixels} bottom 100% `

                        if (backgroundSize) {
                            backgroundSize += ','
                        }
                        //backgroundSize += `${(i+1)*increment}% 100%`
                        backgroundSize += `${(increment/100) * this.dayWidth}px 100%`

                    }

                    style = `${ts}color: #ffffff;width: 100%;height: 100%;background-image:${gradientStyle};background-position: ${backgroundPosition};background-size: ${backgroundSize};background-origin: content-box;`

                }

                return style

            },
                                 
        },
        computed: {
            ...mapGetters(
                'schedule',['getColors',]
            ),   
            item () {
                let ret = {
                    name: 'Personal Coverage',
                    style: this.calculateStyle()
                }
                return ret
            },
            uniqueCoverages () {
                let map = {}

                if (this.personalCoverage && this.personalCoverage.length) {
                    this.personalCoverage.map(p => {
                        let key = `${p.personal_coverer}|${p.personal_coveree}`
                        map[key] = p
                    })
                }

                let arr = []

                for (let key in map) {
                    arr.push(map[key])
                }
                
                return arr
            },
            toolTipHtml () {
                //let html = ''

                let pcHtml=''
                if (this.personalCoverage && this.personalCoverage.length) {
                    this.personalCoverage.map(p => {
                        pcHtml += `<div>${this.pcHtml(p)}</div>`                        
                    })
                }
                console.log('pcHtml', pcHtml)
                return pcHtml

            }
                        
        }
    }
</script>
<style scoped>

.location-select >>> label {
    padding-left: 12px;
}
.location-select >>> .v-select__selections {
    padding-left: 12px;
}




</style>