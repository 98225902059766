<template>

  <div v-if="true || (!fullscreen && $vuetify.breakpoint.smAndUp)" class="portal-header">
    <v-app-bar app dark dense text clipped-right class="transparent elevation-0 pa-0 flex-wrap" >

      <v-tooltip bottom
                 nudge-bottom="0"
                 nudge-right="32"
                 color="ycmd-black"
                 :disabled="$vuetify.breakpoint.xsOnly"
                 content-class="fade-0 pa-4 pt-2 pb-2"
                 v-if="!isAppBarOpen"
                 >
        <template v-slot:activator="{ on }">
          <v-btn icon dark v-on="on"
                 @click.stop="toggleMenuLocal"
                 ref="toggleMenu"
                 class="ml-1 mr-2"                
                 >
            <v-icon :size="22">{{ 'menu' }}</v-icon>
          </v-btn>
        </template>
        <span>{{ isAppBarOpen ? 'Close Menu' : 'Open Menu' }}</span>
      </v-tooltip>
      <div class="d-flex align-center flex-wrap">

        <template v-if="filteredList && filteredList.length > 0">

          <div class="pl-4 uppercase" style="width: 160px" v-if="$vuetify.breakpoint.mdAndUp" >
            <transition name="fade-in-transition" mode="out-in">
              <div :key="`app_id_${ appIndex }`">{{ filteredList[appIndex] ? filteredList[appIndex].name : 'N/A' }}</div>
            </transition>
          </div>

          <v-btn-toggle mandatory borderless
                        class="transparent ml-4">

            <template v-for="(item, i) in filteredList">
              <v-tooltip bottom
                         nudge-bottom="0"
                         nudge-right="32"
                         color="ycmd-black"
                         :disabled="$vuetify.breakpoint.xsOnly"
                         :key="`key${i}`"
                         content-class="fade-0 pa-4 pt-2 pb-2">
                <template v-slot:activator="{ on }">
                  <v-btn icon dark v-on="on"
                         :min-height="36" :max-height="36"
                         :class="appIndex === i ? 'br-2 ycmd-light-blue' : 'transparent'"
                         @click="switchApps(item, i)">
                    <v-icon :size="18">{{ item.icon }}</v-icon>
                  </v-btn>
                </template>
                <span>{{ item ? item.name : 'N/A' }}</span>
              </v-tooltip>
            </template>

          </v-btn-toggle>

          <v-tooltip bottom
                     nudge-bottom="0"
                     nudge-right="32"
                     color="ycmd-black"
                     :disabled="$vuetify.breakpoint.xsOnly"
                     content-class="fade-0 pa-4 pt-2 pb-2">
            <template v-slot:activator="{ on }">
              <v-btn icon dark
                     v-on="on"
                     :href="legacySchedulingUrl"
                     target="_blank"
                     v-if="$vuetify.breakpoint.mdAndUp && showLegacyScheduling"
                     >
                <v-icon :size="18">calendar_month</v-icon>
              </v-btn>
            </template>
            <span>Legacy Scheduling</span>
          </v-tooltip>

        </template>

      </div>
      <v-spacer />

      <div class="d-flex align-right justify-end flex-wrap pa-0">
        
          <div class="d-flex align-right pl-2 pr-2 flex-wrap" style="max-width: 250px;">

            <div v-if="$vuetify.breakpoint.mdAndUp && profile" >
<!--
              <transition name="fade-in-transition" mode="out-in">
-->
                      <template v-if="groups.length === 1">
                        <div class="full-width pl-3">{{ groupSelected.group_name }}</div>
                      </template>

                      <template v-else>
                        <template>
                          <v-select hide-details
                                    v-model="selectedGroup"
                                    :loading="`${isSwitchingUser ? 'white' : false}`"
                                    :disabled="isSwitchingUser"
                                    :items="groups"
                                    item-text="group_name"
                                    class="pa-0 ma-0"
                                    return-object />
                        </template>

                      </template>
            

            </div>
          </div>

      </div>

      <div class="d-flex align-right justify-end flex-wrap pa-0">
        
          <div class="d-flex align-right pl-2 pr-2 flex-wrap" style="min-width: 300px;max-width: 300px;">
            <chat-status />
          </div>

      </div>

    </v-app-bar>

  </div>

</template>

<script>

    import {mapActions, mapState, mapGetters} from "vuex";

    import { routeBasedOnRole } from '@/methods/globalMethods';
    import SubscriptionService from '@/methods/subscriptionInstance'

    import CHANGE_USER from '@/graphql/mutations/phoenix/ycmdAuthenticateSwitchUser.js';

    import ChatStatus from "@/views/apps/chat/components/chat-status.vue";


    export default {
        name: 'portalHeader',
        data: () => ({
            isSwitchingUser: false
        }),
        apollo: {
            $client: 'phoenixClient'
        },
        components: {
          'chat-status': ChatStatus
        },
        methods: {
            ...mapActions('profile', ['setProfile']),
            ...mapActions('menu', ['toggleMenu', 'openMenu']),
            ...mapActions('app', ['changeApp', 'changeItem']),
            ...mapActions('group', ['onSelectedGroup', 'initGroups']),
            ...mapActions('rightMenu', ['openRightMenu','closeRightMenu','collapseRightMenu', 'enableRightMenu', 'disableRightMenu']),
            ...mapActions('chat', ['loadRooms']),
            toggleMenuLocal({nativeEvent}) {
              if (this.$vuetify.breakpoint.xs) {
                this.openMenu()
              } else {
                this.toggleMenu()
              }
              this.$nextTick(() => {
                if (this.$refs && this.$refs.toggleMenu && this.$refs.toggleMenu.$el) {
                  this.$refs.toggleMenu.$el.blur()
                }
              })
            },

            checkItemCondition(item) {
                if (item.disabledCondition) {
                    //console.log('SETTINGS: ', item.name,  item.disabledCondition(this));
                    return item.disabledCondition(this)
                }
                return false
            },
            switchApps(item, i) {

                if(this.isRightMenuExpanded)
                  this.collapseRightMenu();

                this.changeApp(i);
            },
            route(name, transition) {

                this.$router.push({
                 name: name,
                 params: {
                   transition: transition || this.transition
                 }
                }).catch(err => { console.log('router error: ', err) });

            },
            async switchUser(newUser, oldUser) {

                // console.log('switching user to: ', newUser);

                this.isSwitchingUser = true;

                try {
                    const response = await this.$apollo.mutate({
                        mutation: CHANGE_USER,
                        variables: {
                            user_id: newUser.user_id
                        },
                        fetchPolicy: 'no-cache',
                        errorPolicy: 'all'
                    });

                    this.isSwitchingUser = false;

                    console.log('switch user result: ', response);

                    const { ycmdAuthenticateSwitchUser } = response.data;

                    if (ycmdAuthenticateSwitchUser) {
                        const {
                            token,
                            refresh_token,
                            first_name,
                            last_name,
                            user_id,
                            reference_id,
                            group_id,
                            group_name,
                            group,
                            roles,
                            legacy_roles: legacy_roles,
                            linked_groups,
                            linked_users,
                            settings,
                            online_status
                        } = ycmdAuthenticateSwitchUser;
                        // save phoenix token
                        localStorage.setItem("accessToken", token);
                        //vmm 
                        // save refresh token
                        localStorage.setItem("refreshToken", refresh_token);
                        // save username (user_id)
                        localStorage.setItem("userID", user_id);

                        await this.setProfile({
                            firstName: first_name || '',
                            lastName: last_name || '',
                            userID: user_id,
                            referenceID: reference_id,
                            group: {
                                id: group_id,
                                name: group_name,
                                voicemail: group ? group.voicemail: null,
                                forwarding: group.forwarding,
                                settings: group.settings
                            },
                            roles: roles,
                            legacy_roles: legacy_roles,
                            avatar: null,
                            settings: settings
                        });
                        // set groups
                        this.initGroups({
                            myGroup: {
                                group_id: group_id,
                                group_name: group_name,
                                user_id: user_id,
                                settings: group.settings
                            },
                            groups: linked_groups || [],
                            myUser: {
                                id: user_id,
                                roles: roles
                            },
                            linkedUsers: linked_users || []
                        });

                        routeBasedOnRole(this.route);
                        // location.reload();
                        // initiate the subscription
                        let chatUid = this.getHasChat ? user_id: null

                        SubscriptionService.init({userID: chatUid, groupID: group_id});

                        if (this.getHasChat) {
                          //clear out current room
                          this.$store.dispatch('chat/clearCurrentRoom');

                          this.enableRightMenu();
                          this.loadRooms()
                        }
                        else {   
                          this.disableRightMenu();
                        }

                    }
                    else if (response.errors && response.errors.length > 0) {
                        this.selectedGroup = oldUser;
                        if (response.errors[0].message) {
                            this.$toasted.error(`There was an error switching groups - ${response.errors[0].message}`);
                        } else {
                            this.$toasted.error('There was an error switching accounts');
                        }
                    }
                    else {
                        // data did not come back
                        console.log('ycmdAuthenticateSwitchUser data did not come back: ', response);
                        this.selectedGroup = oldUser;
                        this.$toasted.error(`GENERIC - Error switching accounts`);
                    }
                }
                catch(e) {
                    this.isSwitchingUser = false;
                    this.selectedGroup = oldUser;
                    console.log('CAUGHT - error for ycmdAuthenticateSwitchUser - ', e);
                    this.$toasted.error('Caught an error trying to switch accounts');
                    // defaultErrorHandler(e);
                }

            }
        },
        watch: {
            groupSelected(newVal, oldVal) {
                // console.log('user id comparison: ', newVal.user_id, oldVal.user_id);
                // console.log('my group in watch?: ', this.myGroup.user_id);
                // check that you haven't clicked same route AND you aren't moving to your own group
                if ((newVal.user_id !== oldVal.user_id) && (this.myGroup.user_id !== newVal.user_id)) {
                    this.switchUser(newVal, oldVal);
                }
            },
            chatBadgeCount(val) {
                // console.log('chatbadgecount watcher: ', val);
            }
        },
        computed: {
            ...mapState('rightMenu', ['isRightMenuEnabled','isRightMenuOpen','isRightMenuExpanded']),
            ...mapState({
                fullscreen: state => state.layout.fullscreen,
                profile: state => state.profile.info,
                appIndex: state => state.app.index,
                apps: state => state.app.list,
                myGroup: state => state.group.myGroup,
                groups: state => state.group.list,
                adminGroups: state => state.group.adminList,
                groupSelected: state => state.group.selected,
                isMenuOpen: state => state.menu.open,
                chatBadgeCount: state => state.chat.chatBadgeCount,
            }),
            ...mapGetters(
                'app', ['filteredList'],
            ),
            ...mapGetters('profile', ['getLegacyRoles', 'getHasChat']),
            selectedGroup: {
                get () {
                    //console.log('getting selected group: ', this.groupSelected);
                    return this.groupSelected
                },
                set (value) {
                    // set to store
                    this.$store.commit('group/changeGroup', value, { root: true })
                }
            },
            legacyRoleMap () {
                let map = {}
                let roles = this.getLegacyRoles
                console.log('roles', roles)
                if (roles && roles.length) {
                    roles.map(r => {
                        map[r] = true
                    })
                }
                return map
            },
            roleMap () {
              let roles = new Set(JSON.parse(localStorage.getItem("userRoles")));

              return roles
            },
            showLegacyScheduling () {

              if (this.roleMap.has('scheduling.legacy') ) {
                return true
              }
              return false
            },
            isAppBarOpen () {
              if (this.$vuetify.breakpoint.xs) {
                return false
              }
              return this.isMenuOpen 
            },
            legacySchedulingUrl () {
              let url = this.$store.getters['app/v2SchedulingUrl']
              return url
            }
        }
    }
</script>

<style scoped>

  .v-app-bar >>> .v-toolbar__content {
    padding: 0 !important;
  }

</style>
