<template>
  <v-dialog
      v-model="value"
      persistent
      :max-width="maxWidth"
      >
      <v-card
               
      >
          <v-card-title
            class="text-h5"
          >
            {{title}}
          </v-card-title>

          <v-card-text
            style="min-height: 500px;"
          >
            <shift-detail
              v-if="selectedShift"
              :shift="selectedShift"
              :filters="filters"
              :pod="pod"
              :group_id="group_id"
              @cancel="cancelDetail"
              :key="`shiftdetail_${selectedShift.id}`"
            />
            <shift-list
              v-else
              :shifts="shifts"
              :filters="filters"
              @select="doSelect"
            />
      
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
              <v-btn
                @click="cancel()"
              >
                Close
              </v-btn>              

          </v-card-actions>          
      </v-card>

  </v-dialog>

</template>

<script>
import {mapActions, mapGetters} from "vuex";
import scheduleShiftList from '@/views/apps/scheduling-new/components/scheduleShiftList';
import scheduleShiftDetail from '@/views/apps/scheduling-new/components/scheduleShiftDetail';

  export default {
    props: {
      value: {
        default: false,
        type: Boolean
      },
      'max-width': {
        default: '500',
        type: String
      },
      shifts: {
          type: Array,
          default: () => []
      },
      filters: {
          type: Array,
          default: () => []
      },      
      title: {
        default: 'Shifts',
        type: String
      },
      facilities: {
          type: Array,
          default: () => []
      }, 
      pod: {
          type: Object,
          default: () => {}
      }, 
      group_id: {
          type: String,
          default: ''
      },                        
    },
    components: {
        'shift-list': scheduleShiftList,   
        'shift-detail': scheduleShiftDetail
    },    
    data: () => ({
      selectedShift: null
    }),
    mounted() {
    },
    methods: {
      ...mapGetters('schedule',['getFacilityById','getGroupId']),
      cancelDetail () {
        this.selectedShift = null
      },
      doSelect (shift) {
        this.selectedShift = shift
      },
      cancel () {
        this.$emit('input', false)
      },
    },
    computed: {

    },
    watch: {
        value (newValue, oldValue) {
          this.selectedShift = null
        },
    }
  }
</script>

