'use strict';
const colors = [
    ['#e6194b', '#ffffff', '#000000'],
    ['#3cb44b', '#ffffff', '#000000'],
    ['#ffe119', '#000000', '#000000'],
    ['#0082c8', '#ffffff', '#46f0f0'],
    ['#f58231', '#ffffff', '#000000'],
    ['#911eb4', '#ffffff', '#cc99ff'],
    ['#46f0f0', '#000000', '#000000'],
    ['#f032e6', '#ffffff', '#000000'],
    ['#d2f53c', '#000000', '#000000'],
    ['#fabebe', '#000000', '#000000'],
  
    ['#008080', '#ffffff', '#000000'],
    ['#e6beff', '#000000', '#000000'],
    ['#aa6e28', '#ffffff', '#000000'],
    ['#fffac8', '#000000', '#000000'],
    ['#800000', '#ffffff', '#ff9999'],
    ['#aaffc3', '#000000', '#000000'],
    ['#808000', '#ffffff', '#000000'],
    ['#ffd8b1', '#000000', '#000000'],
    ['#000080', '#ffffff', '#9999ff'],
    ['#f3f3f3', '#000000', '#000000'],
  
    ['#714fd2', '#fff', '#50b02d'],
    ['#60e1a5', '#000', '#1e629f'],
    ['#edd575', '#000', '#8a1271'],
    ['#f68df0', '#000', '#726b09'],
    ['#141b1f', '#fff', '#e0ebe7'],
    ['#28361e', '#fff', '#d3c9e1'],
    ['#51242b', '#fff', '#dbb4ae'],
    ['#31286f', '#fff', '#99d790'],
    ['#288f51', '#fff', '#7098d7'],
    ['#b38326', '#fff', '#d94ca9'],
    ['#cc20d9', '#fff', '#d1df26'],
    ['#2fc8ec', '#000', '#13d0ab'],
    ['#a0b18b', '#000', '#634e74'],
    ['#c697a4', '#000', '#684639'],
    ['#a7a8d7', '#000', '#285828'],
    ['#0e2514', '#fff', '#dae0f1'],
    ['#422b13', '#fff', '#ecbdd6'],
    ['#511561', '#fff', '#daea9e'],
    ['#147d83', '#fff', '#7cebe5'],
    ['#77a810', '#fff', '#bf57ef'],
    ['#825769', '#fff', '#a88f7d'],
    ['#5963a1', '#fff', '#5ea669'],
    ['#62b96d', '#000', '#46519d'],
    ['#cc9471', '#000', '#8e3356'],
    ['#bc82dc', '#000', '#5e7d23'],
    ['#96e9e2', '#000', '#166269'],
    ['#262c07', '#fff', '#f2d3f8'],
    ['#4d072e', '#fff', '#f8d9b2'],
    ['#2f3646', '#fff', '#b9d0bf'],
    ['#366135', '#fff', '#9f9eca'],
    ['#7f4a39', '#fff', '#c68091'],
    ['#6e399f', '#fff', '#94c660'],
    ['#37c3a4', '#fff', '#3ca9c8'],
    ['#d1d744', '#000', '#b528bb'],
    ['#e656b9', '#000', '#a97c19'],
    ['#6ca5f1', '#000', '#0e9346'],
    ['#b7ccb2', '#000', '#37334c'],
    ['#211412', '#fff', '#eddee0'],
    ['#261a3a', '#fff', '#d1e5c5'],
    ['#1f5642', '#fff', '#a9cce0'],
    ['#756d21', '#fff', '#de8ad6'],
    ['#972083', '#fff', '#dfca68'],
    ['#1c76bd', '#fff', '#42e39d'],
    ['#53e515', '#fff', '#581aea'],
    ['#a47778', '#000', '#885c5b'],
    ['#8f82ba', '#000', '#527d45'],
    ['#90cdaf', '#000', '#32506f'],
    ['#ddcfa1', '#000', '#5e2250'],
    ['#280b27', '#fff', '#f4f3d7'],
    ['#0f3545', '#fff', '#baf0e0'],
    ['#35660f', '#fff', '#bf99f0'],
    ['#8a0d22', '#fff', '#f28a75'],
    ['#4d496e', '#fff', '#94b691'],
    ['#4d8c64', '#fff', '#738ab2'],
    ['#ac894d', '#fff', '#b2538e'],
    ['#b858c3', '#000', '#9ca73c'],
    ['#68c3d4', '#000', '#2b9786'],
    ['#b6e37a', '#000', '#581c85'],
    ['#ee90ad', '#000', '#6f2d11'],
    ['#04062f', '#fff', '#d0fbd2'],
    ['#223225', '#fff', '#cdd0dd'],
    ['#4c3a2a', '#fff', '#d5b3c4'],
    ['#5a2f68', '#fff', '#c3d097'],
    ['#318587', '#fff', '#78cecc'],
    ['#86a930', '#fff', '#ac56cf'],
    ['#ce2c74', '#fff', '#d37931'],
    ['#3a57e1', '#fff', '#1ec53b'],
    ['#4eef5c', '#000', '#101fb1'],
    ['#bfaa9f', '#000', '#60404c'],
    ['#c4add2', '#000', '#44522d'],
    ['#102321', '#fff', '#dcedef'],
    ['#383e16', '#fff', '#e3c1e9'],
    ['#5b1a40', '#fff', '#e5caa4'],
    ['#1a397c', '#fff', '#83e5a2'],
    ['#1fa018', '#fff', '#665fe7'],
    ['#c63912', '#fff', '#ed3960'],
    ['#7c6496', '#fff', '#819b69'],
    ['#6dae9e', '#000', '#518292'],
    ['#c2c379', '#000', '#853c86'],
    ['#d489bf', '#000', '#76612b'],
    ['#9cbce3', '#000', '#1c633c'],
    ['#0f2a09', '#fff', '#dbd5f6'],
    ['#49100b', '#fff', '#f4b6bb'],
    ['#2b0a6b', '#fff', '#b5f594'],
    ['#3c5a4f', '#fff', '#a5b7c3'],
    ['#767041', '#fff', '#be89b7'],
    ['#96438a', '#fff', '#bcb069'],
    ['#4288b8', '#fff', '#47bd8d'],
    ['#77cd4e', '#000', '#5b32b1'],
    ['#dd5f67', '#000', '#a02922'],
    ['#8c73ea', '#000', '#2d8c15'],
    ['#8af4bc', '#000', '#0b3d75'],
    ['#1f1c14', '#fff', '#ebe0e8'],
    ['#361e36', '#fff', '#e1e1c9'],
    ['#244551', '#fff', '#aedbcf'],
    ['#496f28', '#fff', '#b190d7'],
    ['#8f283d', '#fff', '#d78470'],
    ['#3026b3', '#fff', '#56d94c'],
    ['#20d95e', '#fff', '#2663df'],
    ['#eca02f', '#000', '#d01384'],
    ['#ac8bb1', '#000', '#6f744e'],
    ['#97c0c6', '#000', '#396861'],
    ['#c4d7a7', '#000', '#452858'],
    ['#250e16', '#fff', '#f1e2da'],
    ['#131642', '#fff', '#bdecc1'],
    ['#156123', '#fff', '#9eadea'],
    ['#834714', '#fff', '#eb7cb0'],
    ['#7f10a8', '#fff', '#c6ef57'],
    ['#578282', '#fff', '#7da8a8'],
    ['#8ea159', '#fff', '#935ea6'],
    ['#b9628b', '#000', '#9d6f46'],
    ['#7184cc', '#000', '#338e46'],
    ['#82dc87', '#000', '#23297d'],
    ['#e9b196', '#000', '#691631'],
    ['#1d072c', '#fff', '#e9f8d3'],
    ['#074d43', '#fff', '#b2eef8'],
    ['#44462f', '#fff', '#cdb9d0'],
    ['#613550', '#fff', '#cab99e'],
    ['#39517f', '#fff', '#80c698'],
    ['#429f39', '#fff', '#6860c6'],
    ['#c35137', '#fff', '#c83c56'],
    ['#8644d7', '#000', '#6abb28'],
    ['#56e6bd', '#000', '#1980a9'],
    ['#f1ef6c', '#000', '#930e91'],
    ['#ccb2c6', '#000', '#4c4633'],
    ['#121921', '#fff', '#deede5'],
    ['#213a1a', '#fff', '#ccc5e5'],
    ['#56221f', '#fff', '#e0a9ab'],
    ['#3c2175', '#fff', '#a4de8a'],
    ['#209765', '#fff', '#68addf'],
    ['#bda41c', '#fff', '#e342ca'],
    ['#e515cd', '#fff', '#ead31a'],
    ['#7793a4', '#000', '#5b8877'],
    ['#96ba82', '#000', '#59457d'],
    ['#cd9096', '#000', '#6f3732'],
    ['#aca1dd', '#000', '#2c5e22'],
    ['#0b2818', '#fff', '#d7e4f4'],
    ['#45350f', '#fff', '#f0bae0'],
    ['#640f66', '#fff', '#edf099'],
    ['#0d6c8a', '#fff', '#75f2d4'],
    ['#5c6e49', '#fff', '#a391b6'],
    ['#8c4d5b', '#fff', '#b28273'],
    ['#514dac', '#fff', '#56b253'],
    ['#58c379', '#000', '#3c5da7'],
    ['#d4a668', '#000', '#972b68'],
    ['#d27ae3', '#000', '#73851c'],
    ['#90e5ee', '#000', '#116f65'],
    ['#1f2f04', '#fff', '#ebd0fb'],
    ['#322228', '#fff', '#ddd3cd'],
    ['#2a2d4c', '#fff', '#b3d5b7'],
    ['#2f6838', '#fff', '#97a1d0'],
    ['#875631', '#fff', '#ce789d'],
    ['#8430a9', '#fff', '#abcf56'],
    ['#2ccec8', '#fff', '#31cdd3'],
    ['#bbe13a', '#000', '#9f1ec5'],
    ['#ef4e9f', '#000', '#b16210'],
    ['#9fa6bf', '#000', '#406048'],
    ['#add2ae', '#000', '#2d2e52'],
    ['#231610', '#fff', '#efdce2'],
    ['#2c163e', '#fff', '#d7e9c1'],
    ['#1a5b50', '#fff', '#a4dae5'],
    ['#737c1a', '#fff', '#dc83e5'],
    ['#a0186f', '#fff', '#e7b65f'],
    ['#1256c6', '#fff', '#39ed7c'],
    ['#699664', '#fff', '#6f699b'],
    ['#ae776d', '#000', '#92515b'],
    ['#9879c3', '#000', '#5b863c'],
    ['#89d4bd', '#000', '#2b5f76'],
    ['#e3e09c', '#000', '#631c60'],
    ['#2a0923', '#fff', '#f6efd5'],
    ['#0b2b49', '#fff', '#b6f4d6'],
    ['#226b0a', '#fff', '#ac94f5'],
    ['#5a3d3c', '#fff', '#c3a5a5'],
    ['#504176', '#fff', '#98be89'],
    ['#439670', '#fff', '#6997bc'],
    ['#b8a242', '#fff', '#bd47a7'],
    ['#cd4ec3', '#000', '#b1a732'],
    ['#5fb1dd', '#000', '#22a074'],
    ['#a1ea73', '#000', '#43158c'],
    ['#f48a97', '#000', '#75180b'],
    ['#16141f', '#fff', '#e2ebe0'],
    ['#1e3628', '#fff', '#c9d3e1'],
    ['#514324', '#fff', '#dbaecc'],
    ['#6b286f', '#fff', '#d3d790'],
    ['#287a8f', '#fff', '#70d7c1'],
    ['#70b326', '#fff', '#964cd9'],
    ['#d92050', '#fff', '#df5526'],
    ['#312fec', '#000', '#15d013'],
    ['#8bb195', '#000', '#4e5874'],
    ['#c6b197', '#000', '#683952'],
    ['#cea7d7', '#000', '#4f5828'],
    ['#0e2425', '#fff', '#daf1f0'],
    ['#324213', '#fff', '#ddbdec'],
    ['#611533', '#fff', '#eabc9e'],
    ['#142283', '#fff', '#7ceb8b'],
    ['#10a824', '#fff', '#576cef'],
    ['#826857', '#fff', '#a87d8e'],
    ['#8959a1', '#fff', '#8ea65e'],
    ['#62b9b3', '#000', '#46979d'],
    ['#b9cc71', '#000', '#7c338e'],
    ['#dc82b2', '#000', '#7d5323'],
    ['#96ace9', '#000', '#16692d'],
    ['#072c07', '#fff', '#d3d3f8'],
    ['#4d1a07', '#fff', '#f8b2c4'],
    ['#3b2f46', '#fff', '#c5d0b9'],
    ['#356158', '#fff', '#9ec1ca'],
    ['#7a7f39', '#fff', '#c280c6'],
    ['#9f397e', '#fff', '#c6a460'],
    ['#3770c3', '#fff', '#3cc875'],
    ['#58d744', '#000', '#3c28bb'],
    ['#e66856', '#000', '#a9192b'],
    ['#a06cf1', '#000', '#42930e'],
    ['#b2ccc3', '#000', '#33444c'],
    ['#212012', '#fff', '#eddeec'],
    ['#3a1a34', '#fff', '#e5dfc5'],
    ['#1f3d56', '#fff', '#a9e0c7'],
    ['#397521', '#fff', '#a18ade'],
    ['#972022', '#fff', '#df6968'],
    ['#451cbd', '#fff', '#6be342'],
    ['#15e581', '#fff', '#1a86ea'],
    ['#a49b77', '#000', '#885b7e'],
    ['#ba82b7', '#000', '#7d7a45'],
    ['#90bacd', '#000', '#326f5b'],
    ['#badda1', '#000', '#3b225e'],
    ['#280b10', '#fff', '#f4dcd7'],
    ['#150f45', '#fff', '#c0f0ba'],
    ['#0f6630', '#fff', '#99baf0'],
    ['#8a5e0d', '#fff', '#f275c6'],
    ['#6b496e', '#fff', '#b2b691'],
    ['#4d808c', '#fff', '#73b2a7'],
    ['#82ac4d', '#fff', '#8753b2'],
    ['#c35877', '#000', '#a75b3c'],
    ['#686ad4', '#000', '#2b972d'],
    ['#7ae394', '#000', '#1c3685'],
    ['#eec090', '#000', '#6f1141'],
    ['#25042f', '#fff', '#f1fbd0'],
    ['#223232', '#fff', '#cddddd'],
    ['#414c2a', '#fff', '#cbb3d5'],
    ['#682f47', '#fff', '#d0b097'],
    ['#313e87', '#fff', '#78ce86'],
    ['#30a93d', '#fff', '#5663cf'],
    ['#ce682c', '#fff', '#d3316d'],
    ['#a53ae1', '#000', '#89c51e'],
    ['#4eefdf', '#000', '#10a2b1'],
    ['#b9bf9f', '#000', '#5b4060'],
    ['#d2adc2', '#000', '#52422d'],
    ['#101623', '#fff', '#dcefe2'],
    ['#183e16', '#fff', '#c2c1e9'],
    ['#5b291a', '#fff', '#e5a4b3'],
    ['#4b1a7c', '#fff', '#b4e583'],
    ['#18a080', '#fff', '#5fc7e7'],
    ['#c1c612', '#fff', '#e739ed'],
    ['#966487', '#fff', '#9b8c69'],
    ['#6d89ae', '#000', '#51926d'],
    ['#86c379', '#000', '#493c86'],
    ['#d49089', '#000', '#762b32'],
    ['#b59ce3', '#000', '#36631c'],
    ['#092a1e', '#fff', '#d5eaf6'],
    ['#49430b', '#fff', '#f4b6ed'],
    ['#6b0a5c', '#fff', '#f5e594'],
    ['#3c4d5a', '#fff', '#a5c3b6'],
    ['#527641', '#fff', '#9989be'],
    ['#964347', '#fff', '#bc6d69'],
    ['#5d42b8', '#fff', '#62bd47'],
    ['#4ecd8c', '#000', '#3270b1'],
    ['#ddbe5f', '#000', '#a02281'],
    ['#ea73e8', '#000', '#8c8915'],
    ['#8ad6f4', '#000', '#0b7556'],
    ['#191f14', '#fff', '#e5e0eb'],
    ['#361e22', '#fff', '#e1cdc9'],
    ['#282451', '#fff', '#b2dbae'],
    ['#286f41', '#fff', '#90a9d7'],
    ['#8f6828', '#fff', '#d770af'],
    ['#a226b3', '#fff', '#c9d94c'],
    ['#20bed9', '#fff', '#26dfc3'],
    ['#9dec2f', '#000', '#8113d0'],
    ['#b18b97', '#000', '#745a4e'],
    ['#979ac6', '#000', '#39683b'],
    ['#a7d7b1', '#000', '#283258'],
    ['#25190e', '#fff', '#f1dae5'],
    ['#361342', '#fff', '#e1ecbd'],
    ['#156061', '#fff', '#9eeaea'],
    ['#648314', '#fff', '#cc7ceb'],
    ['#a81055', '#fff', '#ef9c57'],
    ['#575f82', '#fff', '#7da885'],
    ['#59a15e', '#fff', '#5e63a6'],
    ['#b98062', '#000', '#9d4664'],
    ['#a871cc', '#000', '#6b8e33'],
    ['#82dcd0', '#000', '#23727d'],
    ['#dde996', '#000', '#5e1669'],
    ['#2c071d', '#fff', '#f8e9d3'],
    ['#071e4d', '#fff', '#b2f8c9'],
    ['#30462f', '#fff', '#bab9d0'],
    ['#613e35', '#fff', '#ca9ea7'],
    ['#5a397f', '#fff', '#a1c680'],
    ['#399f84', '#fff', '#60abc6'],
    ['#c3c337', '#fff', '#c83cc8'],
    ['#d744b0', '#000', '#bb9428'],
    ['#5699e6', '#000', '#19a95c'],
    ['#87f16c', '#000', '#280e93'],
    ['#ccb4b2', '#000', '#4c3335'],
    ['#171221', '#fff', '#e3edde'],
    ['#1a3a2d', '#fff', '#c5d8e5'],
    ['#564f1f', '#fff', '#e0a9d8'],
    ['#75216a', '#fff', '#ded38a'],
    ['#206897', '#fff', '#68dfb0'],
    ['#52bd1c', '#fff', '#7942e3'],
    ['#e51524', '#fff', '#ea291a'],
    ['#8077a4', '#000', '#64885b'],
    ['#82ba9c', '#000', '#455f7d'],
    ['#cdbc90', '#000', '#6f325e'],
    ['#dda1dd', '#000', '#5d5e22'],
    ['#0b2028', '#fff', '#d7f4ec'],
    ['#29450f', '#fff', '#d4baf0'],
    ['#660f21', '#fff', '#f0ab99'],
    ['#140d8a', '#fff', '#7cf275'],
    ['#496e55', '#fff', '#919db6'],
    ['#8c724d', '#fff', '#b27398'],
    ['#9f4dac', '#fff', '#a4b253'],
    ['#58b6c3', '#000', '#3ca79a'],
    ['#aad468', '#000', '#6d2b97'],
    ['#e37a9f', '#000', '#85401c'],
    ['#9098ee', '#000', '#116f18'],
    ['#042f0c', '#fff', '#d0d8fb'],
    ['#322922', '#fff', '#ddcdd4'],
    ['#422a4c', '#fff', '#ccd5b3'],
    ['#2f6867', '#fff', '#97cfd0'],
    ['#728731', '#fff', '#b978ce'],
    ['#a9306b', '#fff', '#cf9156'],
    ['#2c4fce', '#fff', '#31d355'],
    ['#3ae141', '#000', '#1e25c5'],
    ['#ef804e', '#000', '#b11043'],
    ['#b19fbf', '#000', '#536040'],
    ['#add2cc', '#000', '#2d4c52'],
    ['#212310', '#fff', '#eddcef'],
    ['#3e162f', '#fff', '#e9dac1'],
    ['#1a315b', '#fff', '#a4e5bb'],
    ['#237c1a', '#fff', '#8c83e5'],
    ['#a03018', '#fff', '#e75f77'],
    ['#6212c6', '#fff', '#88ed39'],
    ['#649687', '#fff', '#698c9b'],
    ['#aead6d', '#000', '#925191'],
    ['#c379b1', '#000', '#86743c'],
    ['#89aed4', '#000', '#2b7650'],
    ['#ace39c', '#000', '#2c1c63'],
    ['#2a0a09', '#fff', '#f6d5d6'],
    ['#1e0b49', '#fff', '#c9f4b6'],
    ['#0a6b41', '#fff', '#94cbf5'],
    ['#5a553c', '#fff', '#c3a5be'],
    ['#764171', '#fff', '#beb889'],
    ['#437796', '#fff', '#69bc9e'],
    ['#6db842', '#fff', '#7347bd'],
    ['#cd4e5b', '#000', '#b13f32'],
    ['#745fdd', '#000', '#37a022'],
    ['#73eaa6', '#000', '#15488c'],
    ['#f4d48a', '#000', '#750b54'],
    ['#1e141f', '#fff', '#eaebe0'],
    ['#1e3136', '#fff', '#c9e1dc'],
    ['#3b5124', '#fff', '#c5aedb'],
    ['#6f2839', '#fff', '#d7a190'],
    ['#2b288f', '#fff', '#72d770'],
    ['#26b34f', '#fff', '#4c75d9'],
    ['#d98820', '#fff', '#df268d'],
    ['#cb2fec', '#000', '#afd013'],
    ['#8baeb1', '#000', '#4e7471'],
    ['#b5c697', '#000', '#573968'],
    ['#d7a7b9', '#000', '#583a28'],
    ['#0e1025', '#fff', '#daf1dc'],
    ['#13421a', '#fff', '#bdc5ec'],
    ['#613515', '#fff', '#ea9ebe'],
    ['#601483', '#fff', '#c8eb7c'],
    ['#10a8a1', '#fff', '#57e8ef'],
    ['#798257', '#fff', '#9f7da8'],
    ['#a1597e', '#fff', '#a6835e'],
    ['#6278b9', '#000', '#469d5c'],
    ['#71cc72', '#000', '#33358e'],
    ['#dc9b82', '#000', '#7d233d'],
    ['#c396e9', '#000', '#446916'],
    ['#072c25', '#fff', '#d3f1f8'],
    ['#474d07', '#fff', '#f2b2f8'],
    ['#462f3e', '#fff', '#d0c8b9'],
    ['#354661', '#fff', '#9ecaaf'],
    ['#417f39', '#fff', '#8980c6'],
    ['#9f4839', '#fff', '#c6606e'],
    ['#7037c3', '#fff', '#75c83c'],
    ['#44d7a7', '#000', '#288bbb'],
    ['#e6dd56', '#000', '#a919a0'],
    ['#f16cd6', '#000', '#93770e'],
    ['#b2c0cc', '#000', '#334c40'],
    ['#162112', '#fff', '#e2deed'],
    ['#3a1a1a', '#fff', '#e5c5c5'],
    ['#2e1f56', '#fff', '#b8e0a9'],
    ['#21754e', '#fff', '#8ab7de'],
    ['#978020', '#fff', '#df68c7'],
    ['#bd1cb1', '#fff', '#e3d842'],
    ['#159fe5', '#fff', '#1aeaa4'],
    ['#89a477', '#000', '#6d5b88'],
    ['#ba8289', '#000', '#7d4c45'],
    ['#9890cd', '#000', '#3a6f32'],
    ['#a1ddb9', '#000', '#223a5e']
  ]
  



module.exports = { 
    colors
};
