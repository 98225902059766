<template>

    <div class="view">

        <v-overlay :value="isLoading" :absolute="true" color="#2389e8" opacity="0.3">
            <div class="d-flex align-center justify-center" >
                <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
            </div>
        </v-overlay>

        <i-card :viewIndex="card.index"
                :viewCount="1"
                :leftPanel="card.panels.left"
                :rightPanel="card.panels.right"
                :topPanel="card.panels.top"
                :bottomPanel="card.panels.bottom"
                class="i-card">

            <div class="i-card__main pa-2 pt-0">

                <div class="i-card__main__header pa-2 pl-0">

                    <div class="d-flex align-center flex-grow-1">

                        <v-tabs dark
                                background-color="transparent"
                                v-model="headerTabs">

                            <v-tabs-slider color="ycmd-green"></v-tabs-slider>

                            <v-tab href="#tab-userInfo">Profile</v-tab>
                            <v-tab href="#tab-changePassword">Password</v-tab>
                            <!-- <v-tab href="#tab-settings">Settings</v-tab> -->
                        </v-tabs>

                        <v-spacer />

                        <v-btn dark text small
                               :disabled="!initDataLoaded"
                               @click="onPressSave"
                               class="ycmd-light-blue mr-2">
                            Save
                        </v-btn>
                    </div>
                </div>

                <div class="i-card__main__content white pa-2 br-2">
                    <refresh-init-content v-if="!isLoading && !initDataLoaded" :refreshHandler="getProfile"/>
                    <div v-else class="i-card__scrollable">

                        <div class="list-container">

                            <v-tabs-items v-model="headerTabs">

                                <v-tab-item value="tab-userInfo">

                                    <v-form
                                          ref="editProfileForm"
                                          v-model="userData.validEditProfile"
                                          class="">

                                        <edit-info-page :userData.sync="userData" :originalValues="originalValues" :refreshDates="refreshDates">

                                        </edit-info-page>

                                    </v-form>

                                </v-tab-item>

                                <v-tab-item value="tab-changePassword">

                                    <v-form
                                          ref="changePasswordForm"
                                          v-model="passwordData.validChangePassword"
                                          class="">

                                        <edit-password-page :passwordData.sync="passwordData" :changePasswordForm="this.$refs.changePasswordForm">

                                        </edit-password-page>

                                    </v-form>

                                </v-tab-item>

                                <!-- <v-tab-item value="tab-settings">
                                    <edit-profile-settings>
                                    </edit-profile-settings>
                                </v-tab-item> -->

                            </v-tabs-items>

                        </div>

                    </div>

                </div>

            </div>

        </i-card>

    </div>

</template>

<script>
    import {mapGetters, mapActions} from "vuex";
    import {formatSimple} from '@/methods/formatters';

    import {defaultErrorHandler} from '@/graphql/handler/errorHandler';

    import * as cloneDeep from 'lodash/cloneDeep';

    import SectionTitle from '@/components/sections/section-title';
    import SectionContent from '@/components/sections/section-content';
    import EditInfoPage from './editInfo/page';
    import EditPasswordPage from './editPassword/page';
    import EditProfileSettings from './settings/page';
    import RefreshInitContent from '@/components/loader/refresh-init';

    import GET_PROFILE from '@/graphql/queries/phoenix/ycmdProfileGet';
    import UPDATE_PROFILE from '@/graphql/mutations/phoenix/ycmdProfileUpdate';
    import CHANGE_PASSWORD from '@/graphql/mutations/phoenix/ycmdAuthenticateChangePassword';
    import dataClient from '@/graphql/clients/axios';

    export default {
        name: 'groupEditProfile',
        components: {
            'section-title': SectionTitle,
            'section-content': SectionContent,
            'edit-info-page': EditInfoPage,
            'edit-password-page': EditPasswordPage,
            'edit-profile-settings': EditProfileSettings,
            'refresh-init-content': RefreshInitContent
        },
        props: {
            params: {
                type: Object
            }
        },
        apollo: {
            $client: 'phoenixClient'
        },
        data() {
            return {
                card: {
                    index: 0,
                    panels: { left: false, right: false, top: false, bottom: false }
                },
                headerTabs: null,
                initDataLoaded: false,
                isLoading: false,

                userData: {
                    validEditProfile: false,
                    first_name: '',
                    last_name: '',
                    middle_initial: '',
                    address: '',
                    addressCont: '',
                    city: '',
                    state: '',
                    zip: '',
                    email: ''
                },

                passwordData: {
                    validChangePassword: false,
                    showOldPassword: false,
                    oldPassword: '',
                    showNewPassword: false,
                    newPassword: '',
                    showNewPasswordConfirm: false,
                    newPasswordConfirm: ''
                },

                rules: {
                    required: value => !!value || 'Required.',
                    min: v => v && v.length >= 8 || 'Min 8 characters',
                    phone: number => {
                      const pattern = /^(?:(?:\+?1\s*(?:[.-]\s*)?)?(?:\(\s*([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9])\s*\)|([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9]))\s*(?:[.-]\s*)?)?([2-9]1[02-9]|[2-9][02-9]1|[2-9][02-9]{2})\s*(?:[.-]\s*)?([0-9]{4})(?:\s*(?:#|x\.?|ext\.?|extension)\s*(\d+))?$/gm;
                      return pattern.test(number) || 'Invalid phone number';
                    },
                    email: email => {
                      const pattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                      return pattern.test(email) || 'Invalid email';
                    },
                    passwordMatch: password => {
                      return this.passwordData.newPassword === this.passwordData.newPasswordConfirm || 'New passwords do not match';
                    }
                },
                originalValues: {},
                refreshDates: 0
            }
        },
        activated() {

        },
        mounted() {
            this.getProfile();
        },
        methods: {
            ...mapActions('profile', ['enableChatNotifications']),
            convertDateTime(date, time) {

                if (!date) {
                    return null
                }
                let dt = new Date(date.replaceAll('-', '/')) //don't convert to utc
                console.log('dt', dt)
                if (!time) {
                    return dt
                }
                let pieces = time.split(':')
                console.log('pieces', pieces)
                dt.setHours(parseInt(pieces[0]))
                console.log('dt2', dt)
                dt.setMinutes(parseInt(pieces[1]))
                console.log('dt3', dt)
                return dt.toISOString()
            },
            convertUserData(obj) {
                let ret = {}

                for (let p in obj) {
                    if (obj.hasOwnProperty(p)) {
                        switch (p) {

                            case 'settings':
                                
                                if (!obj.settings.out_of_office) {
                                    obj.settings.out_of_office = {}
                                }
                                
                                ret.out_of_office_ooo = obj.settings.out_of_office && obj.settings.out_of_office.id ? true : false
                                ret.out_of_office_message = obj.settings.out_of_office && obj.settings.out_of_office.message ? obj.settings.out_of_office.message : null

                                if (obj.settings.out_of_office.start) {
                                    ret.out_of_office_startDate = formatSimple(obj.settings.out_of_office.start,'yyyy-MM-dd')
                                    ret.out_of_office_startTime = formatSimple(obj.settings.out_of_office.start,'HH:mm:ss')
                                }
                                if (obj.settings.out_of_office.end) {
                                    ret.out_of_office_endDate = formatSimple(obj.settings.out_of_office.end,'yyyy-MM-dd')
                                    ret.out_of_office_endTime = formatSimple(obj.settings.out_of_office.end,'HH:mm:ss')
                                }

                                ret.explicit_message_confirmation = obj.settings.explicit_message_confirmation ? true : false 
				                ret.explicit_message_confirmation_can_override = obj.settings.explicit_message_confirmation_can_override ? true : false 		

                                ret.enable_chat_notifications = obj.settings.enable_chat_notifications === null ? true : obj.settings.enable_chat_notifications

                                break

                            default:
                                ret[p] = obj[p]
                                break
                        }
                    }
                }

                this.refreshDates += 1
                return ret
            },
            route(name, transition) {

                this.$router.push({
                    name: name,
                    params: {
                        transition: transition || this.transition
                    }
                }).catch(err => { console.log('router error: ', err) });
            },
            selectAllProviders() {
              this.selectedProviders = this.searchedProviders;
            },
            onPressSave() {
                console.log('headerTabs: ', this.headerTabs);
                switch(this.headerTabs) {
                    case 'tab-userInfo':
                        this.saveProfile();
                    break
                    case 'tab-changePassword':
                        this.changePassword();
                    break;
                }
            },
            async changePassword() {
                // validation check

                this.$refs.changePasswordForm.validate();
                if (!this.passwordData.validChangePassword) return;

                this.isLoading = true;

                try {

                    const response = await dataClient('ycmdAuthenticateChangePassword', {
                          current_password: this.passwordData.oldPassword || null,
                          password: this.passwordData.newPasswordConfirm || null
                        })
                    this.isLoading = false;

                    if (response.status == 'success') {
                        this.$refs.changePasswordForm.reset();
                        this.$toasted.success(`Successfully updated profile!`);
                    } else if (response.error) {
                        if (response.error.message) {
                            this.$toasted.error(`There was an error changing password - ${response.error.message}`);
                        } else {
                            this.$toasted.error(`There was an error changing password`);
                        }
                    } else if (response.errors && response.errors.length > 0) {
                        if (response.error.message) {
                            this.$toasted.error(`There was an error changing password - ${response.errors[0].message}`);
                        } else {
                            this.$toasted.error(`There was an error changing password`);
                        }
                    } else {

                        // data did not come back
                        console.log('ycmdProfileUpdate data did not come back: ', response);
                        this.$toasted.error(`There was an error.`);
                    }
                } catch (e) {
                    this.isLoading = false;
                    // defaultErrorHandler(e);
                    console.log('Catch error: ', e);
                }
            },
            async saveProfile() {
                // validation check

                this.$refs.editProfileForm.validate();
                if (!this.userData.validEditProfile) return;

                this.isLoading = true;

                let payload = {
                            first_name: this.userData.first_name,
                            middle_initial: this.userData.middle_initial,
                            last_name: this.userData.last_name,
                            email: this.userData.email,
                            address: {
                                street: this.userData.street,
                                street2: this.userData.street2,
                                city: this.userData.city,
                                state: this.userData.state,
                                zip_code: this.userData.zip_code
                            },
                            mobile_number: this.userData.mobile_number
                }

                let promises = []
                promises.push(dataClient('ycmdProfileUpdate', payload))

                let out_of_office
                if (this.userData && this.userData.out_of_office_ooo) {
                    out_of_office = {
                        message: this.userData.out_of_office_message,
                        start: this.convertDateTime(this.userData.out_of_office_startDate, this.userData.out_of_office_startTime),
                        end: this.convertDateTime(this.userData.out_of_office_endDate, this.userData.out_of_office_endTime),
                        id: 'enable'
                    }
                } else {
                    out_of_office = {
                        message: this.userData.out_of_office_message,
                        start: this.convertDateTime(this.userData.out_of_office_startDate, this.userData.out_of_office_startTime),
                        end: this.convertDateTime(this.userData.out_of_office_endDate, this.userData.out_of_office_endTime),
                        id: 'disable'
                    }
                }
                promises.push(dataClient('ycmdUserSettings',{
                    out_of_office,
                    enable_chat_notifications: this.userData.enable_chat_notifications,
                    room_settings: []
                }))


                try {

                    let results = await Promise.all(promises)

                    this.isLoading = false;

                    let response_ycmdProfileUpdate = results[0]

                    let response_settings = results[1]
                    if (response_settings) {
                        this.enableChatNotifications({enable_chat_notifications: response_settings.enable_chat_notifications});    
                    }

                    if (response_ycmdProfileUpdate) {
                        this.$toasted.success(`Successfully updated profile!`);
                        this.originalValues.mobile_number = this.userData.mobile_number // only thing UI currenty depends on
                    } else if (results[0].errors && results[0].errors.length > 0) {
                        if (response.errors[0].message) {
                            this.$toasted.error(`There was an error updating profile - ${results[0].errors[0].message}`);
                        } else {
                            this.$toasted.error(`There was an error updating profile`);
                        }
                    } else {
                        // data did not come back
                        console.log('ycmdProfileUpdate data did not come back: ', response_ycmdProfileUpdate);
                        this.$toasted.error(`There was an error.`);
                    }

                } catch (e) {
                    this.isLoading = false;
                    // defaultErrorHandler(e);
                    console.log('Catch error: ', e);
                }
            },
            async getProfile() {

                this.isLoading = true;

                try {

                    let profile = await dataClient('ycmdProfileGet', {
                            accountType: 'all'
                    })

                    // update
                    if (profile) {
                        this.userData = this.convertUserData(profile);

                        // set loaded
                        this.initDataLoaded = true;
                        this.originalValues = {...cloneDeep(this.userData)};
                    } else if (profile.errors && profile.errors.length > 0) {
                        if (profile.errors[0].message) {
                            this.$toasted.error(`There was an error getting the profile - ${response.errors[0].message}`);
                        } else {
                            this.$toasted.error(`There was an error getting the profile`);
                        }
                    } else {
                        // data did not come back
                        console.log('ycmdProfileGet data did not come back: ', profile);
                        this.$toasted.error(`There was an error.`);
                    }
              
                } catch (e) {              
                    // defaultErrorHandler(e);
                    console.log('Catch error: ', e);
                } finally {
                    this.isLoading = false
                }
            },
            changeView(view, params) {

                this.$emit('onChangeView', {view: view, params: params});
            },
            slide(direction) {
                this.card.panels[direction] = !this.card.panels[direction];
            },
        },
        computed: {
            ...mapGetters(
                'profile', ['getUserID']
            )
        },
        watch: {
            selectedGroup(val) {
                this.clearFields(true);

                if (!val || !val.id) return;

                this.getBillingGroup(val);
            },
            getUserID(value) {
                this.getProfile();
            }
        }
    }

</script>

<style scoped>

    .item-box {
        background-color: #fff;
        border-bottom: #177ad5 solid 1px;
    }

    .item-box.odd {
        background-color: #ECEFF1;
    }

    .item-box:hover {
        background-color: rgba(255, 255, 255, 0.8)
    }

</style>
