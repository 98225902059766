<template>

    <div class="i-card__panel i-card__panel__bottom ycmd-blue-vertical-gradient pa-2 pt-0">

        <v-overlay :value="Boolean(isLoading)" :absolute="true" color="#2389e8" opacity="0.3">
            <div class="d-flex align-center justify-center" >
                <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
            </div>
        </v-overlay>

        <div class="i-card__panel__header">

            <div class="d-flex align-center flex-grow-1">

                <v-tooltip bottom
                           nudge-bottom="10"
                           nudge-right="50"
                           :disabled="$vuetify.breakpoint.xsOnly"
                           color="ycmd-black"
                           content-class="fade-0 pa-4 pt-2 pb-2">
                    <template v-slot:activator="{ on }">
                        <v-btn icon dark v-on="on" @click="setPanel('bottom', false)" class="ml-2 mr-2">
                            <v-icon :size="20">close</v-icon>
                        </v-btn>
                    </template>
                    <span>Cancel</span>
                </v-tooltip>

                <div class="white--text fs-14 ml-3">
                    New Message
                </div>

                <v-spacer />

                <v-tooltip bottom
                           nudge-bottom="0"
                           nudge-right="32"
                           color="ycmd-black"
                           :disabled="$vuetify.breakpoint.xsOnly"
                           content-class="fade-0 pa-4 pt-2 pb-2">
                    <template v-slot:activator="{ on }">
                        <v-btn icon light small
                               @click="sendMessage"
                               v-on="on"
                               class="ycmd-green ml-4 mr-0">
                            <v-icon color="white" :size="18">chevron_right</v-icon>
                        </v-btn>
                    </template>
                    <span>Send Message</span>
                </v-tooltip>

            </div>
            <div class="d-flex align-center justify-end ml-2">

            </div>
        </div>

        <div class="i-card__panel__content white br-2 pa-2">

            <div class="i-card__scrollable">

                <v-container fluid class="ycmd-light-gray pa-0 b b-1 br-2">

                    <v-form ref="messageSendForm" v-model="validSendForm"
                          class="d-flex flex-column full-width full-height pl-4 pr-4 pt-2">

                        <v-row>
                            <v-col cols="12" sm="6" md="6">
                                <div class="label-title pb-2">Physician Name</div>
                                <ycmd-provider-search
                                    :includeGroupHeaders="true"
                                    nameFormat="last"
                                    :rules="[rules.required]"
                                    :providerSearchSelected.sync="sendMessageData.to_user_id"
                                    :facilityId="providerSearchFacility"
                                    :initialValue="initialProvider"
                                />
                            </v-col>
                            <v-col cols="12" sm="6" md="6">
                                <div class="label-title pb-2">Person You Are Paging For</div>
                                <v-text-field light solo dense flat outlined
                                    placeholder="Leave blank if paging for yourself"
                                    v-model="sendMessageData.guest_name" />
                            </v-col>
                            <v-col cols="6" sm="6" md="4" lg="4">
                                <div class="label-title pb-2">Patient Type</div>
                                <v-select light dense flat outlined
                                    v-model="local_patient_status"
                                    :items="patientTypeList"
                                    item-text="name"
                                    item-value="value"
                                    background-color="white"
                                    :key="`ptl_${refresh}`"
                                    />
                            </v-col>
                            <template v-if="local_patient_status && local_patient_status === 'established'" >
                                <v-col cols="6" sm="6" md="4">
                                    <div class="label-title pb-2">Patient</div>
                                    <ycmd-patient-search
                                        :rules="[rules.required]"
                                        :userId="sendMessageData.to_user_id"
                                        :facility_id="sendMessageData && sendMessageData.facility_id ? this.sendMessageData.facility_id.id : null"
                                        :patientSearchSelected.sync="sendMessageData.patient_id"
                                        nameFormat="last"
                                    />
                                </v-col>
                            </template>
                            <template v-else-if="local_patient_status && local_patient_status === 'new'">
                                <v-col cols="6" sm="6" md="4" lg="3">
                                    <div class="label-title pb-2">First Name</div>
                                    <v-text-field light solo dense flat outlined
                                                  :rules="[rules.required]"
                                                  v-model="sendMessageData.patient_fname" />
                                </v-col>
                                <v-col cols="2" sm="2" md="1" lg="1">
                                    <div class="label-title pb-2">Initial</div>
                                    <v-text-field light solo dense flat outlined style="min-width: 34px;"
                                                  v-model="sendMessageData.patient_initial"
                                                  :maxlength="1"/>
                                </v-col>
                                <v-col cols="10" sm="6" md="4" lg="3">
                                    <div class="label-title pb-2">Last Name</div>
                                    <v-text-field light solo dense flat outlined
                                                  :rules="[rules.required]"
                                                  v-model="sendMessageData.patient_lname" />
                                </v-col>
                                <v-col cols="12" sm="12" md="12" v-if="!nurseView">
                                    <div class="label-title">Established</div>
                                    <div class="d-flex">
                                        <v-checkbox
                                          v-model="sendMessageData.establishedPatient"
                                          :label="`Established Patient (Patient not in system)`"
                                          class="flex-grow-0"
                                        ></v-checkbox>
                                        <v-spacer />
                                    </div>
                                </v-col>
                            </template>
                            <v-col cols="6" sm="6" md="4">
                                <!-- (mm/dd/yyyy)  -->
                                <div class="label-title pb-2">Patient DOB</div>
                                <v-text-field light solo dense flat outlined
                                            v-mask="toggleMaskDOB"
                                            placeholder="mm/dd/yyyy"
                                            :rules="[rules.required, rules.dob]"
                                            v-model="sendMessageData.patient_dob" />
                            </v-col>
                            <v-col cols="6" sm="6" md="4" v-if="!nurseView">
                                <div class="label-title pb-2">Patient Location</div>
                                <ycmd-facility-search
                                    :key="`facility-search-${reload}`"
                                    :disabled="(sendMessageData.to_user_id && sendMessageData.to_user_id.id ? false : true)"
                                    :groupIDS="(this.sendMessageData.to_user_id && this.sendMessageData.to_user_id.group_id ? [this.sendMessageData.to_user_id.group_id] : null)"
                                    :rules="[rules.required]"
                                    :facilitySearchSelected.sync="sendMessageData.facility_id"/>
                            </v-col>
                            <v-col cols="6" sm="6" md="4" v-if="!nurseView">
                                <div class="label-title pb-2">Callback Number</div>
                                <v-text-field light solo dense flat outlined
                                              v-mask="masks.phone"
                                              :rules="[rules.required, rules.phone]"
                                              v-model="callback" />
                            </v-col>
                            <v-col cols="6" sm="6" md="4" v-if="!nurseView">
                                <div class="label-title pb-2">Extension</div>
                                <v-text-field light solo dense flat outlined
                                              v-model="sendMessageData.extension" />
                            </v-col>
                        </v-row>



                        <v-row
                            v-if="nurseView"
                        >
                            <transition name="slide-x-reverse-transition">

                            <v-col
                                cols="12"
                                sm="6"
                                md="6"
                                v-if="showNurseFacilityDropdown"
                            >
                                <div class="label-title pb-2">Location</div>

                                <ycmd-facility-search
                                    v-model="sendMessageData.facility_id"
                                    :rules="[rules.required]"
                                    :initialValue="defaultLocation"
                                />
                            </v-col>
                            </transition>

                            <v-col cols="12" sm="6" md="6">
                                <div
                                    class="label-title pb-2"
                                >
                                    <span
                                        class="pr-5"
                                    >{{callbackLabel}}</span>
                                    <span
                                        text
                                        class="pl-5 text-decoration-underline"
                                        @click="doChangeNurseFacility"
                                        style="cursor: pointer !important;"
                                        v-if="!showNurseFacilityDropdown"
                                    >
                                        Change Location
                                    </span>
                                </div>
                                <v-text-field light solo dense flat outlined
                                    placeholder="Callback number"
                                    v-model="callback"
                                    />
                            </v-col>
                        </v-row>



                        <v-row>
                            <!-- Custom Fields -->
                            <v-col
                                v-for="(field, i) in customFields"
                                :key="`custom_field_${i}`"
                                cols="12" sm="12" md="6" lg="4">

                                <div class="d-flex flex-row align-center">
                                    <div class="label-title pb-2">{{field.label || 'Custom Field'}}</div>
                                    <v-spacer />
                                    <div class="label-title pb-2" style="font-size: 10px;">{{field.description || ''}}</div>
                                </div>
                                <v-select v-if="field.field_type === 'single_select'"
                                    light dense flat outlined
                                    v-model="field.value"
                                    placeholder="Select an option"
                                    :items="field.options"
                                    :rules="[(field.required ? rules.required : true)]"
                                    item-text="value"
                                    background-color="white"
                                    return-object />
                                <v-text-field v-else-if="field.field_type === 'input'"
                                    light dense flat outlined
                                    v-model="field.value"
                                    :rules="[(field.required ? rules.required : true)]"
                                    placeholder="Type answer here"
                                    background-color="white"
                                    return-object />
                                <!-- <div v-if="field.description" class="label-title pb-2">{{field.description || 'Custom Description'}}</div> -->

                            </v-col>
                        </v-row>

                        <v-row class="mt-4">
                            <v-col cols="12" sm="12" md="12">
                                <div class="label-title pb-2">Message</div>
                                <v-textarea
                                        light dense flat outlined
                                        :rules="[rules.required]"
                                        class="small-input"
                                        auto-grow
                                        v-model="sendMessageDataNoWatch.contents"
                                        rows="3"
                                        background-color="white" />
                            </v-col>
                            <v-col cols="12" sm="12" md="12" v-if="!nurseView">
                                <div class="label-title">Bypass On-Call Coverage</div>
                                <div class="d-flex">
                                    <v-checkbox
                                      v-model="sendMessageData.bypass_coverage"
                                      :tabindex="0"
                                      :label="`This will by pass on-call coverage, but procedures will still be followed`"
                                    ></v-checkbox>
                                    <v-spacer />
                                </div>
                            </v-col>
                        </v-row>

                    </v-form>

                </v-container>

            </div>

        </div>

    </div>

</template>

<script>
    import {mapGetters} from "vuex";
    import _ from "lodash";
    import { format, parseISO, formatISO } from 'date-fns';

    //import {patientType} from '@/data/communications/constants';
    import { formatPhoneNumber } from '@/methods/globalMethods';

    import YCMDFacilitySearch from '@/components/searches/ycmd-facility';
    import YCMDProviderSearch from '@/components/searches/ycmd-provider';
    import YCMDPatientSearch from '@/components/searches/ycmd-patient';

    import SEND_MESSAGE from '@/graphql/mutations/phoenix/ycmdMessageSend';
    import GET_MESSAGE_FIELDS from '@/graphql/queries/phoenix/ycmdMessageFields';
    import {inactivityTimer} from '@/graphql/handler/inactivityTimer'
    import dataClient from '@/graphql/clients/axios';

    export default {
        name: 'messagesNewMessage',
        components: {
            'ycmd-facility-search': YCMDFacilitySearch,
            'ycmd-provider-search': YCMDProviderSearch,
            'ycmd-patient-search': YCMDPatientSearch
        },
        props: {
            submitAdvancedSearch: Function,
            setPanel: Function,
            newMessageProvider: Object
        },
        apollo: {
            $client: 'phoenixClient'
        },
        data() {
            return {
                format,
                parseISO,
                //patientType,
                card: {
                    index: 0,
                    panels: { left: false, right: false, top: false, bottom: false }
                },
                rules: {
                    required: value => !!value || 'Required.',
                    min: v => v.length >= 8 || 'Min 8 characters',
                    phone: number => {
                        // const pattern = /^(?:(?:\+?1\s*(?:[.-]\s*)?)?(?:\(\s*([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9])\s*\)|([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9]))\s*(?:[.-]\s*)?)?([2-9]1[02-9]|[2-9][02-9]1|[2-9][02-9]{2})\s*(?:[.-]\s*)?([0-9]{4})(?:\s*(?:#|x\.?|ext\.?|extension)\s*(\d+))?$/gm;
                        const pattern = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
                        return pattern.test(number) || 'Invalid number. Please use (###) ###-####';
                    },
                    dob: date => {
                        return !!this.formatDOB(date) || 'Invalid date. Please use mm/dd/yyy.';
                        // const pattern = /^\d{2}\/\d{2}\/\d{4}$/;
                        // return pattern.test(date) || 'Invalid date. Please use mm/dd/yyy.';
                    }
                },
                masks: {
                    phone: '(###) ###-####',
                    dob: '##/##/####'
                },
                isLoading: false,
                validSendForm: false,
                physicianName: '',
                pagingFor: '',

                customFields: {},

                sendMessageData: {
                    to_user_id: null,
                    guest_name: null,
                    patient_id: null,
                    patient_fname: null,
                    patient_lname: null,
                    patient_initial: null,
                    patient_dob: null,
                    facility_id: null,
                    callback: null,
                    extension: null,
                    bypass_coverage: null,
                    message_answers: null,

                    // unsure about these
                    guest_name: null,
                    unit_shortcode: null,

                    // still needs endpoint
                    establishedPatient: false,
                },
                sendMessageDataNoWatch: {
                    contents: null
                },
                reload: 0,
                changeNurseFacility: false,
                callback: null,
                callbackLabel: null,
                showNurseFacilityDropdown: false,
                local_patient_status: null,
                refresh: 0,
                patientTypeList: [
                    {
                        name: 'Established',
                        value: 'established'
                    },
                    {
                        name: 'New',
                        value: 'new'
                    }
                ]
            }
        },
        activated() {
        },
        created() {

        },
        async mounted() {
            this.clearForm();
            if (this.nurseView) {
                //we need to look up the default facility for nurses
                let dl = this.defaultLocation

                let pieces = dl.split('|')
                
                let locationArray = await dataClient('ycmdFacilitySearch', {
                    facility_id: pieces[0]
                })

                console.log('locationArray', locationArray)

                if (locationArray && locationArray.length) {

                    locationArray[0].shortcode = pieces[1]
                    locationArray[0].facilityName = locationArray[0].name
                    locationArray[0].name = `${locationArray[0].shortcode}`                    

                    let unit = locationArray[0].units.find(u => {
                        return u.shortcode == pieces[1]
                    })

                    if (unit) {
                        locationArray[0].new_patients = unit.new_patients
                        locationArray[0].default_patient_status = unit.default_patient_status
                        if (unit.default_patient_status === 'est') {
                            unit.default_patient_status = 'established'
                        }
                        locationArray[0].name = `${locationArray[0].shortcode} ${unit.name}`    
                        locationArray[0].unitName = unit.name                
                    }

                    this.sendMessage.facility_id = locationArray[0]
                    //this.refresh += 1
                }
                this.patientTypeList = this.getPatientTypeList()
            }
        },
        computed: {
            initialProvider () {
                return this.newMessageProvider ? this.newMessageProvider : null
            },
            toggleMaskDOB: function() {
                return (this.sendMessageData.patient_id && this.sendMessageData.patient_id.dob) ? null : this.masks.dob
            },
            legacyRoleMap () {
                let map = {}
                let roles = this.getLegacyRoles
                console.log('roles', roles)
                if (roles && roles.length) {
                    roles.map(r => {
                        map[r] = true
                    })
                }
                return map
            },
            nurseView () {
                /*
                if (this.legacyRoleMap['provider']) {
                    return false
                }
                if (this.legacyRoleMap['member']) {
                    return false
                }
                if (this.legacyRoleMap['nurse']) {
                    return true
                }
                
                return false
                */
               return true
            },

            providerSearchFacility () {
                if (this.nurseView) {
                    if (this.sendMessageData.facility_id ) {
                        return this.sendMessageData.facility_id.id
                    }
                }

                return null
            },
            defaultLocation () {
                if (this.newMessageProvider && this.newMessageProvider.location) {
                    return this.newMessageProvider.location
                }

                let preferences = this.getPreferences
                
                let location = preferences.find(p => {
                    return p.name == 'location'
                })

                return location ? location.value : null
            },
            ...mapGetters('profile', ['getLegacyRoles', 'getPreferences'])
        },
        watch: {
            sendMessageData: {
                handler: _.debounce(function (val, oldVal) {
                    // console.log('new val: ', val);
                    // check required fields
                    if (!val.to_user_id) {
                        // if required field is missing reset custom fields
                        if (!_.isEmpty(this.customField)) {
                            this.customFields = {};
                        }
                    } else {
                        this.getMessageFields();
                    }
                    inactivityTimer()
                }, 200),
                deep: true,
                immediate: false
            },
            customFields: {
                handler: _.debounce(function (val, oldVal) {
                    // console.log('CUSTOM FIELDS CHANGED: ', oldVal, val);
                    // check required fields
                    if (this.sendMessageData.to_user_id) {
                        this.getMessageFields();
                    }
                }, 200),
                deep: true,
                immediate: false
            },            
            'local_patient_status': {
                handler: function (val, oldVal) {
                    // re-validate form
                    this.$nextTick(() => {
                        this.$refs.messageSendForm.resetValidation()
                    })
                    // reset to default values (switch)
                    this.sendMessageData.patient_fname = null;
                    this.sendMessageData.patient_lname = null;
                    this.sendMessageData.patient_initial = null;
                    this.sendMessageData.establishedPatient = false;
                    this.sendMessageData.patient_id = null;
                    this.sendMessageData.patient_dob = null;
                },
                deep: false,
                immediate: false
            },            
            'sendMessageData.patient_id': {
                handler: function (val, oldVal) {
                    // check to fill DOB (group does this)
                    if (val && val.dob) {
                        this.sendMessageData.patient_dob = val.dob;
                    } else {
                        // needs nextTick reset for masks
                        this.$nextTick(() => {
                            this.sendMessageData.patient_dob = null;
                        })
                    }
                },
                deep: true,
                immediate: false
            },
            'sendMessageData.facility_id': {
                handler: function (val, oldVal) {
                    console.log('val', val)      
                    // check to fill callback number (group does this)
                    this.callbackLabel = 'Callback'

                    //computed reactivity gets messed up with deep objects
                    this.showNurseFacilityDropdown = this.shouldShowNurseFacilityDropdown()

                    if (this.sendMessageData) {
                        this.local_patient_status =  this.getDefaultPatientStatus()
                        this.refresh += 1
                    }

                    if (val) {
                        if (this.nurseView) {
                            this.callbackLabel = `${val.facilityName} ${val.unitName} Callback`
                        }
                        console.log('new fac', val)

                        this.patientTypeList = this.getPatientTypeList()
                    }

                    if (val && val.number) {
                        this.callback = formatPhoneNumber(this.stripPhoneNumber(val.number));
                    } else {
                        this.callback = null
                    }

                },
                deep: true,
                immediate: false
            },
            'sendMessageData.to_user_id': {
                handler: function (val, oldVal) {
                    // console.log('to user id: ', val);
                    if (this.sendMessageData.facility_id) {
                        if (!this.nurseView) {
                            this.sendMessageData.facility_id = null;
                        }
                    }
                    // check if we just cleared to user form
                    if (!val) {
                        this.customFields = {};
                    }
                },
                deep: true,
                immediate: false
            }
        },
        methods: {
            getPatientTypeList () {

                let facility = this.sendMessage && this.sendMessage.facility_id ? this.sendMessage.facility_id : null
                console.log('selectedFacility', facility)

                let list = [
                    {
                        name: 'Established',
                        value: 'established'
                    }
                ]
                
                if (!facility || facility.new_patients || !this.nurseView) {
                    list.push(
                        {
                            name: 'New',
                            value: 'new'
                        }
                    )
                }                
                
                return list
            },

            doChangeNurseFacility () {
                this.changeNurseFacility = true
                this.showNurseFacilityDropdown = this.shouldShowNurseFacilityDropdown()
            },
            getDefaultPatientStatus () {
                if (!this.sendMessage || !this.sendMessage.facility_id || !this.sendMessage.facility_id.id) {                            
                    return 'established'
                }
                console.log('sendMessageData.facility_id', this.sendMessage.facility_id)

                if (this.sendMessage.facility_id.default_patient_status === 'new' && !this.sendMessage.facility_id.new_patients) {
                    //facility doesn't allow new patients.  This can't be default even though it is in mongo sometimes

                    return 'established'
                }

                return this.sendMessage.facility_id.default_patient_status
            },
            shouldShowNurseFacilityDropdown () {
                if (!this.sendMessage || !this.sendMessage.facility_id || !this.sendMessage.facility_id.id) {                            
                    return true
                }
                if (this.changeNurseFacility){
                    return true
                }
                return false
            },
            route(name, transition) {

              this.$router.push({
                name: name, params: {
                  transition: transition
                }
              }).catch(err => { console.log('router error: ', err) });
            },
            slide(direction) {
                this.card.panels[direction] = !this.card.panels[direction];
            },
            stripPhoneNumber(number) {
                if (typeof number === 'string') {
                    return number.replace(/\D/g,'');
                }
                return number;
            },
            formatDOB(date) {
                try {
                    return new Date(date).toISOString();
                } catch {
                    return null
                }
            },
            clearForm() {
                //this.$refs.messageSendForm.reset();             
                this.sendMessageData = {
                    to_user_id: null,
                    guest_name: null,
                    patient_id: null,
                    patient_fname: null,
                    patient_lname: null,
                    patient_initial: null,
                    patient_dob: null,
                    facility_id: null,
                    callback: null,
                    extension: null,
                    bypass_coverage: null,
                    message_answers: null,
                    //patient_status: this.getDefaultPatientStatus(),

                    // unsure about these
                    guest_name: null,
                    unit_shortcode: null,

                    // still needs endpoint
                    establishedPatient: false,
                }

                this.facilitySearchSelected = null
                this.reload++
                this.customFields = {}
                this.sendMessageDataNoWatch = {
                    contents: null
                }

                this.callback = null
                this.callbackLabel = null
                this.showNurseFacilityDropdown = this.shouldShowNurseFacilityDropdown()

                this.local_patient_status = null
/*
                // reset init values
                this.$nextTick(() => {
                    this.sendMessageData.patient_status = this.defaultPatientType;
                    // reset masks
                    //this.sendMessageData.callback = null;
                })
*/                
            },
            getMessageFields : _.debounce(async function getMessageFields() {

                try {
                    const response = await this.$apollo.query({
                        query: GET_MESSAGE_FIELDS,
                        variables: {
                            to_user_id: this.sendMessageData.to_user_id ? this.sendMessageData.to_user_id.id : null,
                            guest_name: this.sendMessageData.guest_name ? this.sendMessageData.guest_name : null,
                            patient_status: this.sendMessageData.patient_status,
                            patient_id: this.sendMessageData.patient_id ? this.sendMessageData.patient_id.id : null,
                            patient_fname: this.sendMessageData.patient_fname ? this.sendMessageData.patient_fname : null,
                            patient_lname: this.sendMessageData.patient_lname ? this.sendMessageData.patient_lname : null,
                            patient_dob: (this.sendMessageData.patient_dob && this.formatDOB(this.sendMessageData.patient_dob)) ? this.formatDOB(this.sendMessageData.patient_dob) : null,
                            facility_id: this.sendMessageData.facility_id ? this.sendMessageData.facility_id.id : null,
                            unit_shortcode: this.sendMessageData.facility_id ? this.sendMessageData.facility_id.shortcode : null,
                            callback: this.callback,
                            extension: this.sendMessageData.extension ? this.sendMessageData.extension : null,
                            bypass_coverage: this.sendMessageData.bypass_coverage ? this.sendMessageData.bypass_coverage : null,
                            direct_admit: this.sendMessageData.establishedPatient ? this.sendMessageData.establishedPatient : null,
                            message_answers: this.customFields ? this.parseCustomFields(this.customFields) : null
                        },
                        fetchPolicy: 'no-cache',
                        errorPolicy: 'all'
                    });

                    // console.log('response: ', response);

                    const { ycmdMessageFields } = response.data

                    if (ycmdMessageFields) {
                        if (ycmdMessageFields.fields.length) {
                            // new custom fields came back
                            const newCustomFields = {};
                            for (let field in ycmdMessageFields.fields) {
                                const currentField = ycmdMessageFields.fields[field];
                                if (this.customFields[currentField.id]) {
                                    newCustomFields[currentField.id] = this.customFields[currentField.id];
                                } else {
                                    newCustomFields[currentField.id] = currentField;
                                }
                            }
                            if (!_.isEqual(newCustomFields, this.customFields)) {
                                this.customFields = newCustomFields;
                            }
                        } else {
                            // ycmdMessageFields came back but no custom fields, reset custom fields
                            if (!_.isEmpty(this.customField)) {
                                this.customFields = {};
                            }
                        }
                    } else {
                        // data did not come back
                        console.log('ycmdMessageSend did not come back: ', response);
                    }
                } catch (e) {
                    // this.$toasted.error(`Caught an error trying to get custom fields`);
                    console.log('WARNING: Caught error - ', e);
                    // defaultErrorHandler(e);
                }

            }, 200),
            async sendMessage() {               
                // validation
                this.$refs.messageSendForm.validate();
                if (!this.validSendForm) return;

                // show loader
                this.isLoading = true;

                try {
                    let facility_id = this.sendMessageData.facility_id
                    //let callback = this.sendMessageData.callback
                    let extension = this.sendMessageData.extension

                    const response = await this.$apollo.mutate({
                        mutation: SEND_MESSAGE,
                        variables: {
                            to_user_id: this.sendMessageData.to_user_id ? this.sendMessageData.to_user_id.id : null,
                            guest_name: this.sendMessageData.guest_name ? this.sendMessageData.guest_name : null,
                            patient_status: this.sendMessageData.patient_status,
                            patient_id: this.sendMessageData.patient_id ? this.sendMessageData.patient_id.id : null,
                            patient_fname: this.sendMessageData.patient_fname ? this.sendMessageData.patient_fname : null,
                            patient_lname: this.sendMessageData.patient_lname ? this.sendMessageData.patient_lname : null,
                            patient_initial: this.sendMessageData.patient_initial ? this.sendMessageData.patient_initial : null,
                            patient_dob: (this.sendMessageData.patient_dob && this.formatDOB(this.sendMessageData.patient_dob)) ? this.formatDOB(this.sendMessageData.patient_dob) : null,
                            facility_id: facility_id ? facility_id.id : null,
                            unit_shortcode: facility_id ? facility_id.shortcode : null,
                            callback: this.callback,
                            extension: extension,
                            contents: this.sendMessageDataNoWatch.contents ? this.sendMessageDataNoWatch.contents : null,
                            bypass_coverage: this.sendMessageData.bypass_coverage ? this.sendMessageData.bypass_coverage : null,
                            direct_admit: this.sendMessageData.establishedPatient ? this.sendMessageData.establishedPatient : null,
                            message_answers: this.customFields ? this.parseCustomFields(this.customFields) : null,
                        },
                        fetchPolicy: 'no-cache',
                        errorPolicy: 'all'
                    });
                    this.isLoading = false;
                    // check errors
                    // console.log('response: ', response);

                    const { ycmdMessageSend } = response.data

                    let value
                    
                    if (facility_id) {
                        value = `${facility_id.id}|${facility_id.shortcode}`
                    }

                    if (ycmdMessageSend) {
                        this.$store.commit('profile/setPreference',
                            {
                                name: 'location',
                                value: value
                            }
                        )
                        this.clearForm();
                        this.$toasted.success(`${ycmdMessageSend.message ? ycmdMessageSend.message : 'Successfully sent message'}`);
                    } else {
                        // data did not come back
                        let errorMessage = `There was an error sending the message`
                        if (response.errors && response.errors.length) {
                            errorMessage = response.errors[0].message
                            if (errorMessage.startsWith('[{"field')) {
                                let eobj = JSON.stringify(response.errors[0].message)
                                errorMessage = eobj.message
                            }
                        }
                        this.$toasted.error(errorMessage);
                        console.log('ycmdMessageSend did not come back: ', response);
                    }
                } catch (e) {
                    this.isLoading = false;
                    this.$toasted.error(`Caught an error sending message`);
                    console.log('WARNING: Caught error - ', e);
                    // defaultErrorHandler(e);
                }

            },
            parseCustomFields(data) {
                let filteredFields = [];
                for (let item in data) {
                    const currentItem = data[item];
                    if (currentItem.id && currentItem.value) {
                        filteredFields.push({
                            id: currentItem.id,
                            value: currentItem.value.id || currentItem.value
                        })
                    }
                }
                return filteredFields.length ? filteredFields : null
            }
        }
    }

</script>

<style scoped>

    .item-box {
        background-color: #fff;
        border-bottom: #177ad5 solid 1px;
    }

    .item-box.odd {
        background-color: #ECEFF1;
    }

    .item-box:hover {
        background-color: rgba(255, 255, 255, 0.8)
    }

</style>
