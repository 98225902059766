<template>

<div>

  <v-menu
    v-model="menu"
    bottom
    right
    transition="scale-transition"
    origin="top left"
    :close-on-click="false"
    :close-on-content-click="false"
    class="filter-container"
    id="schedule_filter_select"
  >
    <template v-slot:activator="{ on }">
        <label 
            class="v-label v-label--active theme--light"
            style="font-size: 10px;font-weight: 400;"
            v-if="label"
            >{{label}}
        </label>

        <div
            v-on="on"
            :class="`pa-3 ${backgroundColor}`"
            style="cursor:pointer;"
        ><span>{{filterName}}</span><v-icon class="float-right">arrow_drop_down</v-icon></div>
        
    </template>
    <v-card>
      <v-card-text
        class="pa-0 ma-0"
      >
        <v-data-table
            :headers="headers"
            :items="items"
            :hide-default-footer="true"
            :disable-pagination="true"
            no-data-text="No Shifts"
            :show-expand="true"
            :item-class="row_classes"
            :fixed-header="true"
            :expanded="expanded"
            id="filter_table"
            height="200"
            style="max-width: 500px;"
            @click:row="rowClick"
        >

            <template v-slot:expanded-item="{ headers, item }">
            <td
                :colspan="headers.length"
                class="pa-0 ma-0 fs-10 pl-5 font-weight-light "    
            >

                        <div                            
                        >
                            <h5>Date Created</h5>
                            <span
                            class="pl-5"
                            >{{formatDate(item.date_created)}}</span>
                        </div>


                        <div v-if="item.rules && item.rules.length"
                            v-html="rulesHtml(item.rules)"
                        />

          

            </td>
            
            </template>

            <template v-slot:top>    
                <v-row
                    class="pa-0 ma-0 ycmd-very-lightest-gray"
                >                
                <v-col
                    class="pa-0 ma-0 shrink"

                >
                    <v-text-field
                        class="pl-5 ma-0 fs-12"
                        dense
                        hide-details
                        v-model="dataFilter"
                        label="Name Filter"
                        clearable
                        style="width: 215px;"
                    ></v-text-field>
                </v-col>
                <v-spacer />
                <v-col
                    class="pa-0 ma-0 shrink"
                >

                    <v-text-field
                        class="pl-5 ma-0 fs-12"
                        dense
                        hide-details
                        v-model="dataFilter2"
                        label="Created By Filter"
                        clearable
                        style="width: 215px;"
                    ></v-text-field>
                </v-col>

                </v-row>                
            </template>

        </v-data-table>

        <div>
        <v-btn 
            text
            @click="close(-1)"
            class="fs-12"
        >
            Close
        </v-btn>
        </div>

      </v-card-text>

    </v-card>

  </v-menu>
</div>  
</template>


<script>
import {mapActions, mapGetters} from "vuex";
import {formatSimple} from '@/methods/formatters';

    export default {
        components: {
        },
        props: {
            value: {
                //type: String,
                default: null
            },            
            rules: {
                type: Array,
                default: () => []
            },            
            group_id: {
                type: String,
                default: ''
            },  
            pod_id: {
                type: String,
                default: ''
            },                                                
            outlined: {
                default: false,
                type: Boolean
            },
            backgroundColor: {
                default: 'white',
                type: String
            },
            filters: {
                type: Array,
                default: () => []
            },               
            filter_id: {
                type: String,
                default: ''
            },                                                
            label: {
                type: String,
                default: ''
            },  
        },
        data: () => ({
            menu: false,
            expanded: [],
            dataFilter: '',
            dataFilter2: ''
        }),
        created() {

        },
        async mounted() {
            //this.focusValue = null
            //this.localValue = this.value
        },
        watch: {
            menu (newValue, oldValue) {
                this.dataFilter = ''
                if (newValue) {

                    setTimeout(() => {
                        this.moveToFilter()
                    }, 100);

                    setTimeout(() => {
                        this.moveToFilter()
                    }, 300);
                    
                    setTimeout(() => {
                        this.moveToFilter()
                    }, 700);                    
                }
            }
            /*
            selectedProvider (newValue, oldValue) {
                if (newValue) {
                    this.$emit('providerSelected', newValue)
                    if (this.clearOnSelect) {
                        this.selectedProvider = null
                    }
                }
                this.$emit('input', newValue)
            },
            */

        },
        methods: {
            formatDate(dt) {

                let formatted = formatSimple(dt,'yyyy-MM-dd hh:mm a')
                
                return formatted
            },
            nameFilter(value) {
                if (!this.dataFilter) {
                    return true
                }

                return value.toLowerCase().includes(this.dataFilter.toLowerCase())
            },  
            createdByFilter(value) {
                if (!this.dataFilter2) {
                    return true
                }

                return value.toLowerCase().includes(this.dataFilter2.toLowerCase())
            },                        
            rowClick(p1,p2) {
                debugger
                this.$emit('change', {filter_id: p2.item.id})
                this.menu = false
            }, 
            moveToFilter() {
                /*
                if (this.filter_id) {
                    this.$vuetify.goTo(`tr.filter_id_${this.filter_id}`)

                }
                */
                
                if (this.filter_id) {      
                    let row

                    let el = this.$el
                    let i=0
                    while (el && ++i < 1000) {
                        //console.log('el tag', el.tagName)
                        //console.log('el id', el.id)
                        //console.log('el id', el.innerHTML)

                        let tableElement = el.querySelector('#filter_table')

                        if (tableElement) {
                            //console.log('tableElement', tableElement.outerHTML)
                            i=2000
                            row = tableElement.querySelector(`tr.filter_id_${this.filter_id}`)                            
                        }
                        el = el.parentElement
                    }


                    if (row) {
                        el = row
                        i=0
                        while (el && ++i < 1000) {
                            if (el.classList.contains("v-data-table__wrapper")) {
                                this.$vuetify.goTo(row,{container: el, duration: 0})
                                i=2000
                            }

                            el = el.parentElement
                        }

                    }
                }
            },
            expand(row) {
                debugger
                this.$set(this.expanded,row.index,true)
            },
            row_classes(item) {

                let classes = ['pa-0', 'ma-0', 'pointer', `filter_id_${item.id}`]
                
                if (item.odd) {
                    classes.push('odd')
                }

                if (item.id === this.filter_id) {
                    classes.push('selected')
                }

                return classes
            }, 
            close() {
                this.menu = false
            },
            rulesHtml (rules) {

                if (rules && rules.length) {
                    let options = ''
                    rules.map(r => {
                        options += `<li>${r}</li>`
                    })
                    let html = `<h5>Rules</h5><ul>${options}</ul>`
                    return html
                }

                return null
            },   
        },
        computed: {
            filterName () {
                if (this.filters && this.filters.length && this.filter_id) {
                    let id = this.filter_id
                    let filter = this.filters.find(f => {
                        return f.id === id
                    })

                    return filter.name
                }
                return ''
            },
            headers () {
                let headers = [    
                    {
                        text: 'Name',
                        value: 'name',
                        width: '45%',
                        sortable: true,
                        cellClass:"fs-8 cellheight18",
                        class:"fs-8",
                        filter: this.nameFilter
                    },
                    
                    {
                        text: 'Type',
                        value: 'source',
                        width: '20%',
                        cellClass:"fs-8 cellheight18",   
                        class:"fs-8",                     
                        sortable: true,
                    },

                    {
                        text: 'Created by',
                        value: 'created_by_name',
                        width: '35%',
                        cellClass:"fs-8 cellheight18",   
                        class:"fs-8",                     
                        sortable: true,
                        filter: this.createdByFilter
                    },                    
                    /*
                    { 
                        text: '', 
                        value: 'actions',
                        cellClass:"fs-8 cellheight18",    
                    },
                    */
                    { text: '', 
                        value: 'data-table-expand',
                        cellClass:"fs-8 filterTableExpand"
                    },                   

                ]

                return headers
            },             
            items () {
                let arr = []
                debugger
                this.filters.map(f => {
                    f.created_by_name = `${f.created_by_first_name} ${f.created_by_last_name}`
                    arr.push(f)
                })

                return arr
            }  
        }
    }
</script>
<style>
#filter_table th {
    height: 18px !important;
}

#filter_table td.cellheight18,#filter_table td.filterTableExpand
{
  height: 18px !important;
}
#filter_table td.filterTableExpand
{
  height: 18px !important;
}
#filter_table td.filterTableExpand button
{
  font-size:12px !important;
}
#filter_table tr.selected 
{
  background-color: #ebf3f9;
}

</style>