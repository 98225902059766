import { render, staticRenderFns } from "./create_password.vue?vue&type=template&id=224d6f1c&scoped=true"
import script from "./create_password.vue?vue&type=script&lang=js"
export * from "./create_password.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "224d6f1c",
  null
  
)

export default component.exports