const state = {
    auth: {
        session: '',
        challengeName: '',
        username: '',
        password: ''
    },
    tokens: {
        accessToken: '',
        idToken: '',
        refreshToken: '',
        tokenType: '',
        expiresIn: 0
    },
    info: {
        firstName: '',
        lastName: '',
        userID: '',
        referenceID: '',
        organization: {
            id: '',
            name: ''
        },
        group: {
            id: '',
            name: '',
            voicemail: {
              mailboxes: []
            }
        },
        role: '',
        avatar: null,
        online: true,
    },
    settings: {
        enableChatNotifications: true,
        rooms: [],
        inactivityTimeoutMinutes: null,
        preferences: [],
        messageView: null,
        messageGridFontAdjust: 0,
        preferenceMap: {}
    },
    //roles: [],
    legacy_roles: [],
    toDoPressed: false,
    openBottomPanel: null,
};

const getters = {
  getAuthenticateBegin: state => state.auth,
  getTokens: state => state.tokens,
  getLegacyRoles: state => state.legacy_roles,
  getRoles: state => () => {
    let roles = localStorage.userRoles ? JSON.parse(localStorage.userRoles) :  []
    return roles
  },
  getLegacyRoles: state => state.legacy_roles,
  getToDoPressed: state => state.toDoPressed,
  getFirstName: state => state.info.firstName,
  getLastName: state => state.info.lastName,
  getUserID: state => state.info.userID,
  getChatRoomNotifications: state => state.settings.rooms,
  getChatNotificationsByRoom: state => {
    return state.settings.rooms
  },
  getChatNotificationsEnabled: state => state.settings.enableChatNotifications,
  getInactivityTimeoutMinutes: state => state.settings.inactivityTimeoutMinutes,
  getPreferences: state => state.settings.preferences,
  openBottomPanel: state => state.openBottomPanel,
  getLast5Providers: state => {
    let arr = []
    let last5 = state.settings.preferences.find(p => {
      return p.name == 'last_5_providers'
    })        

    if (last5) {
      try {
        arr = JSON.parse(last5.value)
      } 
      catch (err) {
        console.log('error parsing last 5', err)
      }
    }
    return arr

  },
  getGroup: state => {
    return state.info.group
  },
  getMessageView: state => state.settings.messageView,
  getMessageGridFontAdjust: state => state.settings.messageGridFontAdjust,
  getHasChat: state => {
    let found = false
    
    let roles = localStorage.userRoles ? JSON.parse(localStorage.userRoles) :  []

    if (roles && roles.length) {
      found = (roles.findIndex(role => role === 'chat') >= 0) ? true : false
    } 
    return found
  },
  hasRole: (state) => (role) => {
    let found = false
    let roles = localStorage.userRoles ? JSON.parse(localStorage.userRoles) :  []

    if (roles && roles.length) {
      found = (roles.findIndex(r => role === r) >= 0) ? true : false
    }

    return found
  },
  hasLegacyRole: (state) => (role) => {
    let found = false
    if (state.legacyRoles && state.legacyRoles.length) {
      found = (state.legacyRoles.findIndex(r => role === r) >= 0) ? true : false
    }

    return found
  },
  getPreference: (state) => (key, defaultValue) => {
    let value
    
    if (state && state.settings && state.settings.preferenceMap) {
      value = state.settings.preferenceMap[key]
    }

    if (typeof(value) == 'undefined') {
      value = defaultValue
    }
    return value
  },
};

const actions = {

    setProfile: ({commit}, info) => {
        const {
            firstName,
            lastName,
            referenceID,
            userID,
            organization,
            group,
            roles,
            legacy_roles,
            role,
            avatar,
            online,
            settings
        } = info;
        commit('setFirstName', firstName);
        commit('setLastName', lastName);
        commit('setReferenceID', referenceID);
        commit('setUserID', userID);
        commit('setOrganization', organization);
        commit('setGroup', group);
        commit('setRoles', roles);
        commit('app/setFilteredList', {roles}, { root: true })
        commit('setLegacyRoles', legacy_roles);
        commit('setRole', role);
        commit('setAvatar', avatar);
        commit('setOnline', online === 'online');
        commit('setPreferences', settings && settings.preferences ? settings.preferences: [])
        commit('setMessageView', settings ? settings.message_view: null);

        commit('setMessageGridFontAdjust', settings && settings.message_grid_font_adjustment? parseInt(settings.message_grid_font_adjustment): 0);
    },

    setChatRoomNotifications: ({commit}, rooms) => {

      commit('setChatRoomNotifications', rooms);
    },
    enableChatNotifications: ({commit}, settings) => {

      const { enable_chat_notifications } = settings;

      commit('enableChatNotifications', enable_chat_notifications);
    },
    setInactivityTimeoutMinutes: ({commit}, inactivityTimeoutMinutes) => {
      commit('inactivityTimeoutMinutes', inactivityTimeoutMinutes);
    }
    /*
    ,
    getRoleByName: ({commit}, name) => {

      return state.roles.includes(name)
    }
    */
};

const mutations = {
  resetState(state) {
    state.auth = {
      session: '',
      challengeName: '',
      username: '',
      password: ''
  }
  state.tokens = {
      accessToken: '',
      idToken: '',
      refreshToken: '',
      tokenType: '',
      expiresIn: 0
  }
  state.info = {
      firstName: '',
      lastName: '',
      userID: '',
      referenceID: '',
      organization: {
          id: '',
          name: ''
      },
      group: {
          id: '',
          name: '',
          voicemail: {
            mailboxes: []
          }
      },
      role: '',
      avatar: null,
      online: true,
  }
  state.settings = {
      enableChatNotifications: true,
      rooms: [],
      inactivityTimeoutMinutes: null,
      preferences: [],
      messageView: null,
      messageGridFontAdjust: 0
  }
  state.roles = []
  state.legacy_roles = []
  state.toDoPressed = false
  state.openBottomPanel = null
},
  setFirstName(state, firstName) {
    state.info.firstName = firstName;
  },

  setLastName(state, lastName) {
    state.info.lastName = lastName;
  },

  setUserID(state, userID) {
    state.info.userID = userID;
  },

  setReferenceID(state, referenceID) {
    state.info.referenceID = referenceID;
  },

  setOrganization(state, organization) {
    state.info.organization = organization;
  },

  setGroup(state, group) {
    state.info.group = group;
  },
  
  setForwarding (state, forwarding) {
    state.info.group.forwarding = forwarding;
  },

  setRole(state, role) {
      state.info.role = role;
  },

  setAvatar(state, avatar) {
    state.info.avatar = avatar;
  },

  change (state, profile) {
    state.current = profile;
  },

  setAuthenticateBegin(state, data) {
    [state.auth.session, state.auth.challengeName, state.auth.username] = [data.session, data.challengeName, data.username];
  },

  setPassword(state, data) {
    [state.auth.username, state.auth.password] = [data.username, data.password];
  },

  setTokens(state, data) {
    [state.tokens.accessToken, state.tokens.idToken, state.tokens.refreshToken, state.tokens.tokenType, state.tokens.expiresIn] = [data.accessToken, data.idToken, data.refreshToken, data.tokenType, data.expiresIn];
  },

  setRoles(state, data) {
    localStorage.setItem("userRoles", JSON.stringify([...data]));
    //state.roles = data; //new Set(data);
  },

  setOnline(state, status) {

    state.info.online = status;
  },
  setPreferences(state, preferences) {

    if (state && state.settings) {
      if (!state.settings.preferenceMap) {
        state.settings.preferenceMap = {}
      }
    }    
    
    state.settings.preferences = preferences;

    preferences.map(p => {
      let key = p.id
      if (!key) {
        key = p.name
      }
      state.settings.preferenceMap[key] = p.value 
    })
  },
  setPreference(state, data) {
    if (state && state.settings) {
      if (!state.settings.preferenceMap) {
        state.settings.preferenceMap = {}
      }
    }

    let index = state.settings.preferences.findIndex(p => {
      if (p.id === data.id) {
        return true
      }
      if (p.name === data.name) {
        return true
      }
    })

    if (index >= 0) {
      state.settings.preferences[index] = data
    } else {
      state.settings.preferences.push(data)
    }

    let key = data.id
    if (!key) {
      key = data.name
    }
    state.settings.preferenceMap[key] = data.value     

  },
  setChatRoomNotifications(state, rooms) {

    state.settings.rooms = rooms;
  },

  enableChatNotifications(state, enable_chat_notifications) {
    state.settings.enableChatNotifications = enable_chat_notifications;
  },

  inactivityTimeoutMinutes(state, inactivityTimeoutMinutes) {
    state.settings.inactivityTimeoutMinutes = inactivityTimeoutMinutes;
  },

  setLegacyRoles(state, data) {
    state.legacy_roles = data; //new Set(data);
  },
  setOpenBottomPanel(state, data) {
    state.openBottomPanel = data
  },
  setMessageView(state, view) {
    state.settings.messageView = view;
  },
  setMessageGridFontAdjust(state, view) {
    state.settings.messageGridFontAdjust = view;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
