<template>

    <div >
        <template
            v-for="(item,i) in items"
        >
            <schedule-shift-day-detail 
                :key="`shift_${item.shift.id}_${i}`"
                :shift="item.shift"
                :assignments="item.assignments"
                :group_id="group_id"
                :providerMap="providerMap"
                :date="date"
                @shiftDayEdit="onShiftDayEdit"
            />
        </template>      

    </div>

</template>

<script>

import {mapActions, mapGetters} from "vuex";
import scheduleShiftDayDetail from '@/views/apps/scheduling-new/components/scheduleShiftDayDetail';

    export default {
        name: 'scheduleShiftDay',
        components: {
            'schedule-shift-day-detail': scheduleShiftDayDetail
        },
        props: {
            shifts: {
                type: Array,
                default: () => []
            },
            date: {
                type: String,
                default: ''
            },
            assignments: {
                type: Object,
                default: () => {}
            }, 
            personalCoverage: {
                type: Object,
                default: () => {}
            },    
            providerMap: {
                type: Object,
                default: () => {}
            },  
            group_id: {
                type: String,
                default: ''
            },                                            
        },
        data: () => ({
            //selectedDuration: '',
        }),
        created() {

        },
        async mounted() {

        },
        watch: {
        },
        methods: {
            onShiftDayEdit (params) {
                this.$emit('shiftDayEdit',params)
            },
            twoDigit (num) {
                let str = num + ''
                if (str.length === 1) {
                    str = '0' + str
                }
                return str
            },
            getCoverage(assignment) {

                let userCoverageArray = this.personalCoverage[assignment.user_id]
                let coverage
                if (userCoverageArray && userCoverageArray.length) {

                    //console.log('personalCoverage', this.personalCoverage)
                    //console.log('assignment', assignment)

                    let startDateKey = `${this.date}T00:00:00.000Z`
                    let endDateKey = `${this.date}T23:59:59.999Z`

                    let personal = userCoverageArray.find(c => {

                        if (startDateKey >= c.start && startDateKey <= c.end) {
                            return true
                        }
                        if (endDateKey >= c.start && endDateKey <= c.end) {
                            return true
                        }
                    })

                    if (personal) {

                        let coverer = this.providerMap[personal.personal_coverer]

                        let color = this.getColors[this.getColors.length-1]
                        
                        if (coverer && coverer.user_index >= 0) {
                            color = this.getColors[coverer.user_index]
                        }
                        if (color) {
                            
                            coverage = {
                                user_id: personal.personal_coverer,
                                id: assignment.id,
                                fg: color[1],
                                bg: color[0],
                                border: color[2]
                            }

                        }
                    }

                } 

                return coverage
            }                              
        },
        computed: {
            ...mapGetters(
                'schedule',['getColors',]
            ),   
            items () {
                let ret = []

                if (this.shifts && this.shifts.length) {
                    this.shifts.map(s => {
                        //console.log(`shift ${s.id}`, this.assignments[s.id])
                        let assignments = this.assignments[s.id]

                        ret.push({
                            shift: s,
                            assignments
                        })
                    })

                }

                return ret
            }
        }
    }
</script>
<style scoped>

.location-select >>> label {
    padding-left: 12px;
}
.location-select >>> .v-select__selections {
    padding-left: 12px;
}




</style>