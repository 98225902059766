<template>

    <div class="view">

        <i-card :viewIndex="card.index"
                :viewCount="1"
                :leftPanel="card.panels.left"
                :rightPanel="card.panels.right"
                :topPanel="card.panels.top"
                :bottomPanel="card.panels.bottom"
                class="i-card">

            <div class="i-card__main">

                <div class="i-card__main__header">

                    <v-spacer />
                    <input-search 
                        :hideSearchInput="true" :hasAdvancedSearch="true"
                                :openRightPanel="_ => card.panels.right = true"/>

                    <v-tooltip bottom
                        v-if="hasPatientTransferReport"
                        nudge-bottom="0"
                        nudge-right="40"
                        color="ycmd-black"
                        content-class="fade-0 pa-4 pt-2 pb-2">
                        <template v-slot:activator="{ on }">
                            <v-btn icon dark rounded
                                @click="downloadReport()"
                                :loading="isLoadingDownload"
                                v-on="on"
                                class="mr-4"
                                :disabled="sending"
                                >
                                <v-icon :size="22" color="">cloud_download</v-icon>
                            </v-btn>
                        </template>
                        <span>Download Report</span>
                    </v-tooltip>

                </div>

                <div class="i-card__main__content br-2 pb-2 pr-2">

                    <sub-header-content v-if="items.length">

                        <template v-slot:header-start>

                            <v-row no-gutters>
                                <v-col cols="6" sm="6" md="6" lg="6">
                                    <div class="fixed-header__column--group fixed-header__text">From
                                        <v-btn icon color="ycmd-green" class="hidden">
                                            <v-icon :size="16">arrow_downward</v-icon>
                                        </v-btn>
                                    </div>
                                </v-col>
                                <v-col cols="6" sm="6" md="6" lg="6">
                                    <div class="fixed-header__column--group fixed-header__text">To
                                        <v-btn icon color="ycmd-blue" class="hidden">
                                            <v-icon :size="16">arrow_downward</v-icon>
                                        </v-btn>
                                    </div>
                                </v-col>
                            </v-row>

                            <!-- Spacing for list buttons -->
                            <div class="scrollbar-width-padding" style="width: 20px;">&nbsp;</div>

                        </template>

                        <template v-slot:header-end>

                            <counter :count="currentItems.toString()" :total="totalItems.toString()"/>

                        </template>

                    </sub-header-content>

                    <div class="i-card__scrollable">

                        <div class="list-container">

                            <v-container fluid v-for="(item, i) in items" :key="`item_${i}`" :class="`list-item flex-shrink-0 pa-3`">

                                <div class="d-flex flex-grow-1">

                                        <div class="d-flex flex-grow-1 flex-column">

                                            <v-row no-gutters>

                                                <v-col cols="12" sm="12" md="6" lg="6">
                                                    <div :class="`list-item__row d-flex justify-space-between ${ $vuetify.breakpoint.smAndDown ? 'pb-0' : '' }`">
                                                        <div class="label-title">Patient Name</div>
                                                        <div class="label-value">
                                                            {{ `${item.patient && (item.patient.first_name || item.patient.last_name) ? `${item.patient.first_name} ${item.patient.last_name}` : 'N/A'}` }}
                                                        </div>
                                                    </div>
                                                    <div :class="`list-item__row d-flex justify-space-between ${ $vuetify.breakpoint.smAndDown ? 'pb-0' : '' }`">
                                                        <div class="label-title">Transfered By</div>
                                                        <div class="label-value">
                                                            {{ (item.transferred_by && (item.transferred_by.first_name || item.transferred_by.last_name)) ? `${item.transferred_by.first_name} ${item.transferred_by.last_name}` : 'N/A' }}
                                                        </div>
                                                    </div>
                                                    <div :class="`list-item__row d-flex justify-space-between ${ $vuetify.breakpoint.smAndDown ? 'pb-0' : '' }`">
                                                        <div class="label-title">Transfered From</div>
                                                        <div class="label-value">
                                                            {{ (item.from_facility && item.from_facility.name) || 'N/A' }}
                                                        </div>
                                                    </div>
                                                </v-col>
                                                <v-col cols="12" sm="12" md="6" lg="6">
                                                    <div :class="`list-item__row d-flex justify-space-between ${ $vuetify.breakpoint.smAndDown ? 'pb-0' : '' }`">
                                                        <div class="label-title">Transfered To</div>
                                                        <div class="label-value">
                                                            {{ (item.to_facility && item.to_facility.name) ? item.to_facility.name : 'N/A' }}
                                                        </div>
                                                    </div>
                                                    <div :class="`list-item__row d-flex justify-space-between ${ $vuetify.breakpoint.smAndDown ? 'pb-0' : '' }`">
                                                        <div class="label-title">Date Transfered</div>
                                                        <div class="label-value">
                                                            {{ item.date_transferred ? format(parseISO(item.date_transferred), 'MM-dd-yyyy h:mm a') : 'N/A' }}
                                                        </div>
                                                    </div>
                                                    <div :class="`list-item__row d-flex justify-space-between ${ $vuetify.breakpoint.smAndDown ? 'pb-0' : '' }`">
                                                        <div class="label-title">Date Received</div>
                                                        <div class="label-value">
                                                            {{ item.date_received ? format(parseISO(item.date_received), 'MM-dd-yyyy h:mm a') : 'N/A' }}
                                                        </div>
                                                    </div>
                                                    <div :class="`list-item__row d-flex justify-space-between ${ $vuetify.breakpoint.smAndDown ? 'pb-0' : '' }`">
                                                        <div class="label-title">Received By</div>
                                                        <div class="label-value">
                                                            {{ (item.received_by && (item.received_by.first_name || item.received_by.last_name)) ? `${item.received_by.first_name} ${item.received_by.last_name}` : 'N/A' }}
                                                        </div>
                                                    </div>
                                                </v-col>

                                            </v-row>

                                        </div>

                                    </div>

                            </v-container>

                            <infinite-loader :infiniteHandler="infiniteHandler"
                                            :infiniteId="infiniteId"
                                            :refreshHandler="refreshData"/>

                        </div>

                    </div>

                </div>

            </div>

            <template slot="right-panel">

                <patients-advanced-search :advancedSearch.sync="advancedSearch"
                    :submitAdvancedSearch="submitAdvancedSearch"
                    :clearAdvancedSearch="clearAdvancedSearch"/>

            </template>

            <template slot="bottom-panel">

                <div class="i-card__panel i-card__panel__bottom ycmd-blue-vertical-gradient pa-2 pr-0 pt-0">

                    <div class="i-card__panel__header">

                        <div class="d-flex align-center flex-grow-1">

                            <v-tooltip bottom
                                       nudge-bottom="10"
                                       nudge-right="50"
                                       :disabled="$vuetify.breakpoint.xsOnly"
                                       color="ycmd-black"
                                       content-class="fade-0 pa-4 pt-2 pb-2">
                                <template v-slot:activator="{ on }">
                                    <v-btn icon dark v-on="on" @click="card.panels.bottom = false" class="ml-2 mr-2">
                                        <v-icon :size="20">close</v-icon>
                                    </v-btn>
                                </template>
                                <span>Cancel</span>
                            </v-tooltip>

                            <div class="white--text fs-14 ml-3">
                                New Message
                            </div>
                        </div>
                        <div class="d-flex align-center justify-end ml-2">

                        </div>
                    </div>

                    <div class="i-card__panel__content white br-2 br_tr-0 pa-2">

                        <div class="i-card__scrollable">
                            <div class="pa-3">
                                Content
                            </div>
                        </div>
                    </div>
                </div>

            </template>

        </i-card>

    </div>

</template>

<script>

    import {mapActions, mapState, mapGetters} from "vuex";
    import { format, parseISO } from 'date-fns';
    import Loading from 'vue-loading-overlay';
    import InfiniteLoader from '@/components/loader/infinite-loader';
    import {defaultErrorHandler} from '@/graphql/handler/errorHandler';

    import SubHeaderContent from '@/components/header/sub-header-content';
    import Counter from '@/components/header/items-counter';

    import {formatPhoneNumber} from '@/methods/globalMethods';
    //import GET_PATIENTS from '@/graphql/queries/phoenix/ycmdPatientTransferGet';
    import InputSearch from "@/components/input-search";
    import AdvancedSearch from './advanced-search'
    import dataClient from '@/graphql/clients/axios';

    export default {
        name: 'patients',
        components: {
            'sub-header-content': SubHeaderContent,
            'counter': Counter,
            'infinite-loader': InfiniteLoader,
            Loading,
            'input-search': InputSearch,
            'patients-advanced-search': AdvancedSearch
        },
        apollo: {
            $client: 'phoenixClient'
        },
        data() {
            return {
                format,
                parseISO,
                formatPhoneNumber,
                card: {
                    index: 0,
                    panels: { left: false, right: false, top: false, bottom: false }
                },
                items: [],
                item: {},
                currentItems: 0,
                totalItems: 0,

                page: 1,
                searchValue: '',
                infiniteId: 1,
                rules: {
                    required: value => !!value || 'Required.',
                    min: v => v.length >= 8 || 'Min 8 characters'
                },

                nextToken: null,
                currentItems: 0,
                totalItems: 0,
                searchValue: '',

                selectedFacilitiesSearched: '',
                facilitiesSearched: [],
                isLoadingSearch: false,

                advancedSearch: {
                    transferred_by_id: null,
                    to_facility_id: null,
                    from_facility_id: null,
                    message_date: null,
                    datetype: null
                },
                isLoadingDownload: false,
                sending: false,
                hasPatientTransferReport: false
            }
        },
        created() {},
        async mounted() {
            let definition = await this.$store.dispatch('report/findReportDefinition', {report_id: 'patient_transfers'})
            this.hasPatientTransferReport = definition ? true : false
        },

        activated() {},
        watch: {
            getUserID(value) {
                this.refreshData();
            }
        },
        methods: {
            ...mapActions('menuRight', ['openMenuRight','closeMenuRight','toggleMenuRight']),
            submitAdvancedSearch({shouldRefresh, closePanel}) {
                if (shouldRefresh) {
                    // refresh data
                    this.refreshData();
                }
                if (closePanel) {
                    // close panel
                    this.card.panels.right = false
                }
            },
            clearAdvancedSearch() {
                for (let item in this.advancedSearch) {
                    this.advancedSearch[item] = null;
                }
            },
            changeView(view, params) {
                this.$emit('onChangeView', {view: view, params: params});
            },
            onSearchClick(e, value) {

                console.log(value);
            },
            onSearchChange(e, value) {

                console.log(value);
            },
            slide(direction) {
                this.card.panels[direction] = !this.card.panels[direction];
            },
            route(name, transition) {

                this.$router.push({
                    name: name, params: {
                        transition: transition
                    }
                }).catch(err => {
                    console.log('router error: ', err)
                });
            },
            async sendConsult() {
                console.log('sending consult');
            },
            async discharge() {
                console.log('discharge action');
            },
            async downloadReport () {
                let variables = {
                    report_id: 'patient_transfers',
                    output_type: 'csv',
                    parameters: []
                 }

                if (this.advancedSearch) {
                    if (this.advancedSearch.transferred_by_id) {
                        //: ID, : ID, message_date: AWSDateTime, message_end_date: AWSDateTime
                        variables.parameters.push({
                            id: 'transferred_by_id',
                            value: this.advancedSearch.transferred_by_id
                        })
                    }
                    if (this.advancedSearch.to_facility_id) {
                        variables.parameters.push({
                            id: 'to_facility_id',
                            value: this.advancedSearch.to_facility_id
                        })
                    }
                    if (this.advancedSearch.from_facility_id) {
                        variables.parameters.push({
                            id: 'from_facility_id',
                            value: this.advancedSearch.from_facility_id
                        })
                    }
                    if (this.advancedSearch.message_date) {
                        variables.parameters.push({
                            id: 'message_date',
                            value: this.advancedSearch.message_date
                        })
                    }
                }

                let run = await this.$store.dispatch('report/runReport', variables)

                if (run && run.url) {
                    this.$toasted.success(`Generated ${run.report_name}. Please make sure pop-up blocker is not enabled`);
                    window.open(run.url, '_blank');
                } 

            },
            refreshData() {
                this.currentItems = 0;
                this.totalItems = 0;
                this.page = 1;
                this.items = [];

                this.infiniteId++;
            },
            async infiniteHandler($state) {

                this.isLoading = true;
                console.log('sending page: ', this.page);

                try {
                    console.log('this.advancedSearch', this.advancedSearch)
                    let variables = {
                            page: this.page,
                            mode: 'transfer',
                            limit: 20
                    }

                    if (this.advancedSearch) {
                        if (this.advancedSearch.transferred_by_id) {
                            //: ID, : ID, message_date: AWSDateTime, message_end_date: AWSDateTime
                            variables.transferred_by_id = this.advancedSearch.transferred_by_id
                        }
                        if (this.advancedSearch.to_facility_id) {
                            variables.transferred_to_facility_id = this.advancedSearch.to_facility_id.id
                        }
                        if (this.advancedSearch.from_facility_id) {
                            variables.transferred_from_facility_id = this.advancedSearch.from_facility_id.id
                        }
                        if (this.advancedSearch.message_date) {
                            variables.transferred_message_date = this.advancedSearch.message_date
                        }

                    }
                    /*
                    const response = await this.$apollo.query({
                        query: GET_PATIENTS,
                        variables: variables,
                        fetchPolicy: 'no-cache',
                        errorPolicy: 'all'
                    });

                    console.log('response: ', response);
                    */
                    let response = await dataClient('ycmdPatientGet',variables)

                    this.isLoading = false;

                    if (response && response.patients && response.patients.length) {

                        response.patients.map(p => {
                            let obj = p.transfer
                            delete p.transfer
                            obj.patient = p
                            this.items.push(obj)
                        })  
                            //this.items.push(...response.patients);
                            this.page++;

                            $state.loaded();
 

                        // Update count
                        this.currentItems = this.items.length;
                        if (response.patients.total_count) {
                            this.totalItems = response.patients.total_count;
                        } else {
                            this.totalItems = 0;
                        }

                    } else {
                        // data did not come back
                        // console.log('data did not come back or is finished: ', response);
                        $state.complete();
                    }
                } catch (e) {
                    $state.complete();
                    // defaultErrorHandler(e);
                }
            }
        },
        computed: {
            ...mapState({
                isMenuOpen: state => state.menu.open,
                isMenuRightOpen: state => state.menuRight.open
            }),
            ...mapGetters(
                'profile', ['getUserID']
            )
        }
    }

</script>

<style scoped>

    .tab {
        color: white !important;
    }

    .tabs-holder {
        width: 270px;
        margin-bottom: -8px;
        margin-left: -4px;
    }

    .tabs--active {
        background-color: white;
        color: #167DD7 !important;
    }

    .message__section {
        height: 200px;
    }

    .list-item__row {
        width: 100%;
        padding-right: 20px;
        margin-top: 5px;
        margin-bottom: 5px;
    }

    @media (max-width: 600px) {
        .list-container {
            font-size: 10px;
            padding: 0 0 8px 0;
        }
    }

    /* Small Breakpoint */
    @media (min-width: 600px) {

    }

    /* Medium Breakpoint */
    @media (min-width: 960px) {
        .list-item__column {
            display: flex;
            align-items: center;
            height: 100%;
        }
        .label-value {
            padding-bottom: 0;
        }
    }

    /* Large Breakpoint */
    @media (min-width: 1264px) {

    }

    /* XLarge Breakpoint */
    @media (min-width: 1904px) {

    }

</style>
