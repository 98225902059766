<template>

    <div class="i-card__scrollable">

        <!-- Title first row -->
        <section-title :title="`Office Information`"/>

        <section-content>

            <template v-slot:section-content>

                <v-row class="full-width">

                    <v-col cols="12" sm="12" md="6">
                        <div class="label-title">Office Address</div>
                        <v-text-field light solo dense flat outlined
                                      :rules="[rules.required]"
                                      v-model="officeDataLocal.officeAddress" />
                    </v-col>
                    <v-col cols="12" sm="12" md="6">
                        <div class="label-title">Address Cont..</div>
                        <v-text-field light solo dense flat outlined
                                      v-model="officeDataLocal.officeAddressCont" />
                    </v-col>
                    <v-col cols="12" sm="12" md="6">
                        <div class="label-title">City</div>
                        <v-text-field light solo dense flat outlined
                                      :rules="[rules.required]"
                                      v-model="officeDataLocal.officeCity" />
                    </v-col>
                    <v-col cols="12" sm="12" md="6">
                        <div class="label-title">State</div>
                        <v-text-field light solo dense flat outlined
                                      :rules="[rules.required]"
                                      v-model="officeDataLocal.officeState" />
                    </v-col>
                    <v-col cols="12" sm="12" md="6">
                        <div class="label-title">Zip Code</div>
                        <v-text-field light solo dense flat outlined
                                      :rules="[rules.required]"
                                      v-model="officeDataLocal.officeZipCode" />
                    </v-col>
                    <v-col cols="12" sm="12" md="6">
                        <div class="label-title">Office Phone</div>
                        <v-text-field light solo dense flat outlined
                                      :rules="[rules.required]"
                                      v-mask="masks.phone"
                                      v-model="officeDataLocal.officePhone" />
                    </v-col>
                    <v-col cols="12" sm="12" md="6">
                        <div class="label-title">Office Fax</div>
                        <v-text-field light solo dense flat outlined
                                      v-mask="masks.phone"
                                      v-model="officeDataLocal.officeFax" />
                    </v-col>
                    <v-col cols="12" sm="12" md="6">
                        <div class="label-title">Office Website</div>
                        <v-text-field light solo dense flat outlined
                                      v-model="officeDataLocal.officeWebsite" />
                    </v-col>
                    <v-col cols="12" sm="12" md="6">
                        <div class="label-title">Lunch Backline Phone</div>
                        <v-text-field light solo dense flat outlined
                                      v-mask="masks.phone"
                                      v-model="officeDataLocal.lunchPhone" />
                    </v-col>
                    <!-- <v-col cols="12" sm="12" md="6">
                        <div class="label-title">Timezone</div>
                        <v-text-field light solo dense flat outlined
                                      v-model="officeDataLocal.officeAddressCont" />
                    </v-col> -->

                </v-row>

            </template>

        </section-content>

        <!-- Title second row -->
        <section-title :title="`Office Hours`"/>

        <section-content>

            <template v-slot:section-content>

                <v-row class="full-width">

                    <v-col class="" v-for="(day, i) in officeDataLocal.officeHours" :key="`hours-day_${i}`" cols="12" sm="12" md="12" lg="6">

                        <v-row class="">
                            <v-col cols="6" sm="4" md="4" lg="4" class="pa-0 pl-2">
                                <v-menu
                                    v-model="timePickerModels[day.day].open"
                                    :close-on-content-click="false"
                                    :nudge-right="40"

                                    transition="scale-transition"
                                    offset-y
                                    max-width="290px"
                                    min-width="290px"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <div class="label-title ml-8">{{mapNumberToDays[day.day]}} - Open</div>
                                        <v-text-field
                                            light solo dense flat outlined
                                            v-model="officeDataLocal.officeHours[i].open"
                                            label="Picker in menu"
                                            prepend-icon="access_time"
                                            readonly
                                            v-bind="attrs"
                                            v-on="on"
                                        ></v-text-field>
                                    </template>
                                    <v-time-picker
                                        v-if="timePickerModels[day.day].open"
                                        v-model="officeDataLocal.officeHours[i].open"
                                        full-width
                                        @click:minute=""
                                    ></v-time-picker>
                                </v-menu>
                            </v-col>

                            <v-col cols="6" sm="4" md="4" lg="4" class="pa-0 pl-2">
                                <v-menu
                                    v-model="timePickerModels[day.day].close"
                                    :close-on-content-click="false"
                                    :nudge-right="40"
                                    transition="scale-transition"
                                    offset-y
                                    max-width="290px"
                                    min-width="290px"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <div class="label-title ml-8">Close</div>
                                        <v-text-field
                                            light solo dense flat outlined
                                            v-model="officeDataLocal.officeHours[i].close"
                                            label="Picker in menu"
                                            prepend-icon="access_time"
                                            readonly
                                            v-bind="attrs"
                                            v-on="on"
                                        ></v-text-field>
                                    </template>
                                    <v-time-picker
                                        v-if="timePickerModels[day.day].close"
                                        v-model="officeDataLocal.officeHours[i].close"
                                        full-width
                                        @click:minute=""
                                    ></v-time-picker>
                                </v-menu>
                            </v-col>

                            <v-switch
                              v-model="day.enabled"
                              class="ml-4"
                              inset
                              @change="onChangeDayEnabled(day)"
                              :label="`${day.enabled ? 'Open' : 'Closed'}`"
                            ></v-switch>
                        </v-row>

                    </v-col>

                </v-row>

            </template>

        </section-content>

    </div>

</template>

<script>

    import {mapActions, mapState} from "vuex";
    import { format, parseISO } from 'date-fns';
    import Loading from 'vue-loading-overlay';
    import InfiniteLoading from 'vue-infinite-loading';
    import {defaultErrorHandler} from '@/graphql/handler/errorHandler';

    import SectionTitle from '@/components/sections/section-title';
    import SectionContent from '@/components/sections/section-content';

    import {formatPhoneNumber} from '@/methods/globalMethods';
    import GET_MESSAGES from '@/graphql/queries/phoenix/ycmdCallLogGet';

    export default {
        name: 'adminEditOffice',
        components: {
            'section-title': SectionTitle,
            'section-content': SectionContent,
            Loading,
            InfiniteLoading
        },
        apollo: {
            $client: 'phoenixClient'
        },
        props: {
            officeData: Object
        },
        data() {
            return {
                format,
                parseISO,
                formatPhoneNumber,
                card: {
                    index: 0,
                    panels: { left: false, right: false, top: false, bottom: false }
                },
                rules: {
                    required: value => !!value || 'Required.',
                    min: v => v.length >= 8 || 'Min 8 characters'
                },
                masks: {
                    phone: '(###) ###-####'
                },
                mapNumberToDays: {
                    1: 'Monday',
                    2: 'Tuesday',
                    3: 'Wednesday',
                    4: 'Thursday',
                    5: 'Friday',
                    6: 'Saturday',
                    7: 'Sunday',
                    lunch: 'Lunch'
                },
                timePickerModels: {
                    1: {
                        open: false,
                        close: false
                    },
                    2: {
                        open: false,
                        close: false
                    },
                    3: {
                        open: false,
                        close: false
                    },
                    4: {
                        open: false,
                        close: false
                    },
                    5: {
                        open: false,
                        close: false
                    },
                    6: {
                        open: false,
                        close: false
                    },
                    7: {
                        open: false,
                        close: false
                    },
                    'lunch': {
                        open: false,
                        close: false
                    }
                }
            }
        },
        created() {},
        mounted() {},
        activated() {},
        watch: {
            'officeDataLocal.officeHours'(val) {
                console.log('val: ', val);
            }
        },
        methods: {
            ...mapActions('menuRight', ['openMenuRight','closeMenuRight','toggleMenuRight']),
            changeView(view, params) {

                this.$emit('onChangeView', {view: view, params: params});
            },
            slide(direction) {
                this.card.panels[direction] = !this.card.panels[direction];
            },
            route(name, transition) {

                this.$router.push({
                    name: name, params: {
                        transition: transition
                    }
                }).catch(err => {
                    console.log('router error: ', err)
                });
            },
            onChangeDayEnabled(day) {
                if (!day.enabled) {
                    day.close = null;
                    day.open = null;
                } else {
                    day.close = '09:00';
                    day.open = '05:00';
                }
            },
            convertTime(time) {
                return time.toLocaleTimeString({},
                    {timeZone:'UTC',hour12:true,hour:'numeric',minute:'numeric'}
                );
            }
        },
        computed: {
            ...mapState({
                isMenuOpen: state => state.menu.open,
                isMenuRightOpen: state => state.menuRight.open
            }),
            officeDataLocal: {
                get: function() {
                    return this.officeData;
                },
                set: function(newValue) {
                    this.$emit('update:officeData', newValue)
                }
            }
        }
    }

</script>

<style scoped>

    .tab {
        color: white !important;
    }

    .tabs-holder {
        width: 270px;
        margin-bottom: -8px;
        margin-left: -4px;
    }

    .tabs--active {
        background-color: white;
        color: #167DD7 !important;
    }

    .message__section {
        height: 200px;
    }

    .list-item__row {
        width: 100%;
        padding-right: 20px;
        margin-top: 5px;
        margin-bottom: 5px;
    }

    @media (max-width: 600px) {
        .list-container {
            font-size: 10px;
            padding: 0 0 8px 0;
        }
    }

    /* Small Breakpoint */
    @media (min-width: 600px) {

    }

    /* Medium Breakpoint */
    @media (min-width: 960px) {
        .list-item__column {
            display: flex;
            align-items: center;
            height: 100%;
        }
        .label-value {
            padding-bottom: 0;
        }
    }

    /* Large Breakpoint */
    @media (min-width: 1264px) {

    }

    /* XLarge Breakpoint */
    @media (min-width: 1904px) {

    }

</style>
