<template>

    <div >
        <v-card
            class="pb-5"
        >
            <v-card-title>{{shift.name}}</v-card-title>
            <v-card-subtitle>{{shiftRounding}}</v-card-subtitle>
            <v-card-text>
            <v-container fluid
              class="pa-0 ma-0"
            >
                <v-row
                    class="pa-0 ma-0"
                >
                    <v-col
                    cols="6"
                    class="pa-0 ma-0"

                    >
                        <div
                            v-if="shiftNewPatients"
                        >{{shiftNewPatients}}</div>

                        <div v-if="shiftRulesHtml"
                            v-html="shiftRulesHtml"
                        />
                    </v-col>
                    <v-col
                    cols="6"
                    class="pa-0 ma-0"
                    stylex="border: 1px solid blue;"

                    >
                        <schedule-provider
                            :providers="providers"
                            v-model="selectedProvider"
                            backgroundColor="ycmd-very-lightest-gray"     
                            :group_id="group_id"
                            :outlined="false"             
                            :style="isMultiple?'width: 100%':'width: 300px'"
                            :multiple="isMultiple"
                            :deferMultipleEvents="true"
                            :key="`sp_single_${shift.id}_${selectedProvider}`"
                        >
                        </schedule-provider>
                   
                    </v-col>
                </v-row>
            </v-container>
            </v-card-text>
        </v-card>
    </div>

</template>

<script>
import scheduleProvider from '@/views/apps/scheduling-new/components/scheduleProvider';
import {mapActions, mapGetters} from "vuex";

    export default {
        name: 'scheduleShiftDayDetail',
        components: {
            'schedule-provider': scheduleProvider
        },
        props: {
            shift: {
                type: Object,
                default: () => {}
            },
            date: {
                type: String,
                default: ''
            },
            assignments: {
                type: Array,
                default: () => []
            }, 
            personalCoverage: {
                type: Object,
                default: () => {}
            },    
            providerMap: {
                type: Object,
                default: () => {}
            },    
            group_id: {
                type: String,
                default: ''
            },                                          
        },
        data: () => ({
            //selectedDuration: '',
        }),
        created() {

        },
        async mounted() {

        },
        watch: {
        },
        methods: {

        },
        computed: {
            ...mapGetters(
                'schedule',['getColors',]
            ), 
            isMultiple () {

                let multiple = false
                
                if (this.shift) {
                    multiple = this.shift.rotation || this.shift.rounding   
                }

                return multiple

            },
            shiftRounding () {
                return this.shift.rounding ? 'Rounding' : 'Non-rounding'
            },
            shiftNewPatients () {
                if (!this.shift.rounding) {
                    return `Assign new patients: ${this.shift.assign_new_patient}`
                }
                return null
            },
            shiftRulesHtml () {

                if (this.shift.rules && this.shift.rules.length) {
                    let options = ''
                    this.shift.rules.map(r => {
                        options += `<li>${r}</li>`
                    })
                    let html = `<ul>${options}</ul>`
                    return html
                }

                return null
            },
            selectedProvider: {
                get: function() {

                    if (this.isMultiple) {
                        let arr = []

                        if (this.assignments && this.assignments.length) {
                            this.assignments.map(a => {
                                arr.push(a.user_id)
                            })
                        }

                        return arr

                    } else {
                        if (this.assignments && this.assignments.length) {
                            return this.assignments[0].user_id
                        }
                    }
                    return null
                },
                set: function(newValue) {
                    console.log('day detail', newValue)
                    this.$emit('input', newValue)
                    this.$emit('shiftDayEdit',{shift: this.shift, providers: newValue, date: this.date})
                }
            }, 
            providers () {
                let ret = []
                for (let id in this.providerMap) {
                    ret.push(this.providerMap[id])
                }
                return ret
            }  
        }
    }
</script>
<style scoped>

.location-select >>> label {
    padding-left: 12px;
}
.location-select >>> .v-select__selections {
    padding-left: 12px;
}




</style>