<template>
<div>

  <v-menu
    v-model="menu"
    bottom
    right
    transition="scale-transition"
    origin="top left"
    :close-on-click="false"
    :close-on-content-click="false"
  >
    <template v-slot:activator="{ on }">
      <v-icon
        v-on="on"
        :style="`background-color: ${bgColor};color:${fgColor};`"
      >
        edit
      </v-icon>
    </template>
    <v-card>
      <v-card-text>
        <color-pallette
          :provider="provider"
          :groupId="groupId"
          :userIndexEdits="userIndexEdits"
          @close="close"
        />
      </v-card-text>
      <v-card-actions>
        <v-btn 
            text
            @click="close(-1)"
        >
            Cancel
        </v-btn>
      </v-card-actions>
    </v-card>

  </v-menu>
</div>  
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import colorPallette from '@/views/apps/scheduling-new/components/colorPallette'
  export default {
    props: {
      provider : {
        type: Object,
        default: () => {}
      },
      groupId : {
        type: String,
        default: ''
      },    
      userIndexEdits : {
        type: Object,
        default: () => {}
      }          
    },
    components: {
        'color-pallette': colorPallette
    },    
    data: () => ({
      menu: false
    }),
    mounted() {
    },
    methods: {
      close(params) {
        this.menu = false
        this.$emit('close', params)
      }
    },
    computed: {
      ...mapGetters(
          'schedule',['getColors',]
      ),         
      userIndex () {
        let ui = -1
        
        if (this.provider) {
          ui = this.userIndexEdits[this.provider.id] 

          if (ui >= 0) {
            //it has been edited.  Use it
          } else {
            ui = this.provider.user_index
          }
        }

        return ui

      },
      fgColor () {
        if (this.userIndex >= 0) {
          let color = this.getColors[this.userIndex]
          return color[1]
        }
      },
      bgColor () {
        if (this.userIndex >= 0) {
          let color = this.getColors[this.userIndex]
          return color[0]
        }
      }

    },
    watch: {

    }

  }
</script>

