<template>


  <v-container
    class="pa-0 ma-0 "                
    >        

      <v-row
        class="pt-5 ma-0 px-0 pb-0"               >
        <v-col cols="12"
            class="pa-0 ma-0"
        >

        <v-text-field light flat dense
            class="fs-18"
            label="Name"
            background-color="ycmd-very-lightest-gray"
            v-model="localName" />

        </v-col>
      </v-row>


      <v-row
        class="ma-0 pa-0" 
      >
        <v-col cols="3"
            class="pa-0 ma-0"
        >
          <time-entry
            v-model="localStartTime"
            backgroundColor="ycmd-very-lightest-gray"
            :rules="requiredRules"
            label="Start Time"
            class="pr-1"
          />
        </v-col>
        <v-col cols="3"
            class="pa-0 ma-0"
        >
          <time-entry
            v-model="localEndTime"
            backgroundColor="ycmd-very-lightest-gray"
            :rules="requiredRules"
            label="End Time"
            class="pl-1"
          />
        </v-col>    
        <v-col cols="6"
            class="pa-0 ma-0"
        >
          <checkbox-list
            :items="dows"
            v-model="localWeekdays"
            :label="'Days'"
            :horizontal="true"
          />
        </v-col>                               
      </v-row>

      <v-row
        class="ma-0 pa-0" 
        align="center"
        v-if="showRounding"
      >

        <v-col cols="3"
            class="pa-0 ma-0"
        >
          <v-checkbox
          label="Rounding Shift"
          v-model="localRounding"
          dense
          hide-details
          :disabled="disabledRounding"                  
          />
        </v-col>     
        <v-col cols="9"
            class="pa-0 ma-0 font-weight-light fs-10 "
            style="line-height: 12px;"
        >
          Make this template/shift a Rounding template, and this shift can be used multiple times per day. Physicians on this template/shift will receive pages sent to them directly and messages will not be routed to any other shift, even if the filter/rules match.
        </v-col>                                               
      </v-row>

      <v-row
        class="ma-0 pa-0" 
        align="center"
        v-if="showRoundingOutpatient"
      >

        <v-col cols="3"
            class="pa-0 ma-0"
        >
          <v-checkbox
          label="Rounding Shift + Outpatient Rounding"
          v-model="localRoundingOutpatient"
          dense
          hide-details
          />
        </v-col>     
        <v-col cols="9"
            class="pa-0 ma-0 font-weight-light fs-10 "
            style="line-height: 12px;"
        >
            Same as rounding, but also, the oncall will take their own outpatient calls, only if they are selected. This shift will not have outpatients calls routed to.
        </v-col>                                               
      </v-row>

      <v-row
        class="ma-0 pa-0" 
        align="center"
        v-if="showRotation"
      >

        <v-col cols="3"
            class="pa-0 ma-0"
        >
          <v-checkbox
          label="Rotating Shift"
          v-model="localRotation"
          dense
          hide-details
          :disabled="disabledRotation"    
          />
        </v-col>     
        <v-col cols="9"
            class="pa-0 ma-0 font-weight-light fs-10 "
            style="line-height: 12px;"
        >
          Make this template/shift a rotating template. This means that multiple physicians can be active on this shift and messages are rotated to each person on the shift.

        </v-col>                                               
      </v-row>



      <v-row
        class="ma-0 pa-0" 
        align="center"
        v-if="showCatchall"
      >

        <v-col cols="3"
            class="pa-0 ma-0"
        >
          <v-checkbox
          label="Catch-All Template"
          v-model="localCatchall"
          dense
          hide-details
          />
        </v-col>     
        <v-col cols="9"
            class="pa-0 ma-0 font-weight-light fs-10 "
            style="line-height: 12px;"
        >
          Make this template/shift a Catch-All template. This means any physician in this subgroup that is not on a shift who receives a message will instead route that message to the physician on this shift. Only one catch-all can be used per time period.
        </v-col>                                               
      </v-row>


      <v-row
        class="ma-0 pa-0" 
        align="center"
        v-if="showOutpatient"

      >

        <v-col cols="3"
            class="pa-0 ma-0"
        >
          <v-checkbox
          label="Outpatient Catch-All Template"
          v-model="localOutpatient"
          dense
          hide-details
          />
        </v-col>     
        <v-col cols="9"
            class="pa-0 ma-0 font-weight-light fs-10 "
            style="line-height: 12px;"
        >
          Make this template/shift an Outpatient Catch-All template. This is the same as the catch-all, but only affects outpatient locations.
        </v-col>                                               
      </v-row>



      <v-row
        class="ma-0 pa-0" 
        align="center"
        v-if="showAssignNewPatient"

      >

        <v-col cols="3"
            class="pa-0 ma-0"
        >
          <v-checkbox
          label="Assign New Patient"
          v-model="localAssignNewPatient"
          dense
          hide-details                  
          />
        </v-col>     
        <v-col cols="9"
            class="pa-0 ma-0 font-weight-light fs-10 "
            style="line-height: 12px;"
        >
          Make this template/shift into one that will auto-assign new patients to the receiving physician.
        </v-col>                                               
      </v-row>


      <v-row
        class="ma-0 pa-0" 
        align="center"
        v-if="showAssignTemporary"
      >

        <v-col cols="3"
            class="pa-0 ma-0"
        >
          <v-checkbox
          label="Temporary Assignment"
          v-model="localAssignTemporary"
          dense
          hide-details
          />
        </v-col>     
        <v-col cols="9"
            class="pa-0 ma-0 font-weight-light fs-10 "
            style="line-height: 12px;"
        >
          Make this template/shift into one that will assign the patient temporarily to the receiving physician.
        </v-col>                                               
      </v-row>


      <v-row
        class="ma-0 pa-0" 
        v-if="showFacilities"                    
      >
        <v-col cols="12"
            class="pa-0 ma-0"
        >
          <checkbox-list
            :items="podFacilities"
            v-model="localSelectedFacilities"
            label="Facilities"
          />
        </v-col>
      </v-row>      
      <v-row
        class="ma-0 px-0 pb-0 pt-5" 
        v-if="showFilter"                    
      >
        <v-col cols="3"
            class="pa-0 ma-0"
        >
          Filter
        </v-col>     

        <v-col cols="3"
            class="pa-0 ma-0"
        >
          <v-select
              light
              flat
              outlined
              class="align-center"
              :useIcon="false"
              label="Select Filter"
              :items="filterItems"
              v-model="localFilterId"
              item-value="id"
              item-text="name"                                    
              dense
              background-color="ycmd-very-lightest-gray" 
          
          />
          <filter-select
            :pod_id="pod ? pod.id: null"
            :filters="filters"
            :group_id="group_id"
            :filter_id="localFilterId"
            label="Filter"
            @change="saveFilter"
            backgroundColor="ycmd-very-lightest-gray" 
          />


        </v-col>
      </v-row>     

      <v-row
        class="ma-0 pa-0" 
      
      >
        <v-col cols="12"
            class="pa-0 ma-0"
        >

    <v-spacer></v-spacer>
      <v-btn
        @click="cancel()"
      >
        Cancel
      </v-btn>              

        </v-col>
      </v-row>      

    </v-container>
            
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import scheduleFilterSelect from '@/views/apps/scheduling-new/components/scheduleFilterSelect';
import timeEntry from '@/components/datetime/time-entry';
import checkboxList from '@/components/checkbox-list'
  export default {
    props: {
      shift: {
          type: Object,
          default: () => {}
      },  
      pod: {
          type: Object,
          default: () => {}
      },   
      filters: {
          type: Array,
          default: () => []
      },   
      group_id: {
          type: String,
          default: ''
      },                                       
    },
    components: {
      'time-entry': timeEntry,
      'checkbox-list': checkboxList,
      'filter-select':scheduleFilterSelect
    },    
    data: () => ({
      name: '',
      rules: {
          required: value => !!value || 'Required.',
          arrayRequired: (value) => {
            return !value ||  !value.length ? 'Required.' : true
          },
          shiftRules: []
      },
      isValid: true,
      localSelectedFacilities: [],
      localName: '',
      localStartTime: '',
      localEndTime: '',
      localWeekdays: [],
      localOutpatient: 0,
      localCatchall: 0,
      localAssignNewPatient: 0,
      localAssignTemporary: 0,
      localRotation: 0,
      localRounding: 0,
      localRoundingOutpatient: 0,
      localFilterId: null,
      dows: [
        {id: '0', name: 'Sunday'}, 
        {id: '1', name: 'Monday'},
        {id: '2', name: 'Tuesday'}, 
        {id: '3', name: 'Wednesday'},
        {id: '4', name: 'Thursday'},
        {id: '5', name: 'Friday'},
        {id: '6', name: 'Saturday'}
      ],
    }),
    mounted() {

    },
    methods: {
      ...mapGetters('schedule',['getFacilityById']),
      cancel() {
        this.$emit('cancel')
      },
      saveFilter(params) {
        this.localFilterId = params.filter_id
      }
    },
    computed: {
      filterItems () {
        let sorted = this.filters.sort( (a,b) => {
            return a.name.localeCompare(b.name)
        })

        return sorted

      },
      showRoundingOutpatient () {
        return this.isNew
      },
      showOutpatient () {

        if (this.isNew) {
          return true
        } else {
          return this.shift.rounding ? false : true
        }
        return true
      },
      showAssignTemporary () {

        if (this.isNew) {
          return true
        } else {
          return this.shift.rounding ? false : true
        }
        return true
      },
      showAssignNewPatient () {

        if (this.isNew) {
          return true
        } else {
          return this.shift.rounding ? false : true
        }
        return true
      },
      showRotation () {

        //if (this.isNew) {
          //return true
        //} else {
          return this.shift.rounding ? false : true
        //}
        //return true
      },
      showCatchall () {
        if (this.isNew) {
          return true
        } else {

          return true

        }
        return false

      },
      showRounding () {
        return true
        if (this.isNew) {
          return true
        }
        if (this.shift.rounding) {
          return true
        }
      },
      showFilter () {
        return this.shift.rounding ? false : true
      },
      disabledRounding () {
        return this.isNew ? false : true
      },
      disabledRotation () {
        return this.isNew ? false : true
      },
      requiredRules () {
        return [this.rules.required]
      },      
      showFacilities () {
        return this.shift.rotation || this.shift.rounding         
      },
      groupFacilitiesMap () {
        let map = {}
        if (this.facilities && this.facilities.length) {
          this.facilities.map(f => {
            map[f.id] = f
          })
        }
        return map
      },
      podFacilities () {
        let arr = []

        if (this.pod && this.pod.facility_ids && this.pod.facility_ids.length) {
          this.pod.facility_ids.map(id => {
            let fac = this.$store.getters['schedule/getFacilityById'](id)
            arr.push(fac)
          })  
          let sorted = arr.sort( (a,b) => {
              return a.name.localeCompare(b.name)
          })

          arr = sorted          
        }

        return arr
      },
      shiftFacilities () {
        let arr = []
        let map = this.groupFacilitiesMap


        if (this.pod && this.pod.facility_ids && this.pod.facility_ids.length) {
          
          this.pod.facility_ids.map(id => {
            let fac = map[id]
            if (fac) {
              arr.push(fac)
            }            
          })
          let sorted = arr.sort( (a,b) => {
              return a.name.localeCompare(b.name)
          })

          arr = sorted

        }

        return arr
      },
      isNew () {
        if (this.shift && this.shift.id) {
          return false
        }
        return true
      }
                  
    },
    watch: {
      shift: {
          handler (newValue, oldValue) {

              this.localSelectedFacilities = newValue.rounding_facilities
              this.localName = newValue.name
              this.localStartTime = newValue.start
              this.localEndTime = newValue.end
              this.localWeekdays = newValue.weekdays
              this.localOutpatient = newValue.outpatient
              this.localCatchall = newValue.catchall
              this.localAssignNewPatient = newValue.assign_new_patient
              this.localAssignTemporary = newValue.assign_temporary
              this.localRotation = newValue.rotation
              this.localRoundingOutpatient = 0
              this.localRounding = newValue.rounding
              this.localFilterId = newValue.filter

              if (!this.localWeekdays.length) {

                this.localWeekdays = ["0","1","2","3","4","5","6"]
              }
          }, 
          deep: true,
          immediate: true
      }
    }
  }
</script>

