<template>

    <v-layout class="full-width full-height">

        <loading :active.sync="isLoading"
                 :can-cancel="false"
                 :is-full-page="false"
                 :background-color="`#101622`"
                 :color="`white`"
                 :loader="`bars`">
        </loading>

        <portal-header />

        <v-main :class="`${ expanded ? 'main-content-hidden' : 'main-content' }`">

            <transition :name="transition">

                <router-view class="absolute full-width full-height" />

            </transition>

        </v-main>


        <!-- Left side drawer -->
        <main-menu>

            <template v-slot:header-actions>

                <v-tooltip bottom
                           nudge-left="26"
                           nudge-bottom="0"
                           :disabled="$vuetify.breakpoint.xsOnly"
                           color="ycmd-black"
                           content-class="fade-0 pa-4 pt-2 pb-2">
                    <template v-slot:activator="{ on }">
                        <v-btn icon dark depressed v-on="on" @click="onSwapProfilePicture()">
                            <v-icon :size="22" color="ycmd-blue">person</v-icon>
                        </v-btn>
                    </template>
                    <span>Change Profile Image</span>
                </v-tooltip>

                <v-tooltip top
                           :disabled="$vuetify.breakpoint.xsOnly"
                           color="ycmd-black"
                           content-class="fade-0 pa-4 pt-2 pb-2">
                    <template v-slot:activator="{ on }">
                        <v-btn icon dark v-on="on" @click="route('COMMUNICATION.edit-profile', 'fade-in-transition')">
                            <v-icon color="ycmd-blue" :size="24">settings</v-icon>
                        </v-btn>
                    </template>
                    <span>Account</span>
                </v-tooltip>

            </template>

            <template v-slot:footer-action>

                <v-tooltip top
                           :disabled="$vuetify.breakpoint.xsOnly"
                           color="ycmd-black"
                           content-class="fade-0 pa-4 pt-2 pb-2">
                    <template v-slot:activator="{ on }">
                        <v-btn icon dark v-on="on">
                            <v-icon :size="24">assignment</v-icon>
                        </v-btn>
                    </template>
                    <span>Tasks</span>
                </v-tooltip>

            </template>

            <template v-slot:drawer-items>

            </template>

        </main-menu>

<!--        <v-footer v-if="!fullscreen && $vuetify.breakpoint.xsOnly" app dark class="pa-0">-->

<!--            <div class="d-flex pa-2-->
<!--                        flex-grow-1-->
<!--                        fill-height-->
<!--                        align-center-->
<!--                        justify-center-->
<!--                        align-content-space-between-->
<!--                        ycmd-dark-blue">-->

<!--                <v-btn icon class="ml-3 mr-3"-->
<!--                       @click="changeMenuItem(1, 'GROUP.inbox', 'fade-in-transition')">-->
<!--                    <v-icon :class="`menu-icon ${ menuIndex === 1 ? 'active' : '' }`">inbox</v-icon>-->
<!--                </v-btn>-->
<!--            </div>-->
<!--        </v-footer>-->

    </v-layout>

</template>

<script>

    import { mapState, mapActions, mapMutations  } from 'vuex';
    import innerHeight from 'ios-inner-height';
    import Loading from 'vue-loading-overlay';


    import { clearLocalStorage } from '@/methods/globalMethods';

    export default {
        name: 'communicationIndex',
        components: {
            Loading
        },
        data: () => ({
            drawer: true,
            menuIndex: -1,
            card: {
                index: 0,
                panels: { left: false, right: false, top: false, bottom: false }
            },
            dialog: false,
            isLoading: false,
            transition: 'fade-in-transition',
            height: 0,
            innerHeight: 0,
            fullscreen: false
        }),
        created() {

        },
        mounted() {

            this.height = innerHeight();
            this.innerHeight = window.innerHeight;

            // Add or remove the header based on which route meta matches
            this.fullscreen = this.$store.getters['layout/fullscreen'];
        },
        methods: {
            ...mapActions('menu', ['openMenu','closeMenu','toggleMenu']),
            ...mapActions('menuRight', ['openMenuRight','closeMenuRight','toggleMenuRight']),
            ...mapMutations('profile', ['setToDoPressed']),
            route(name, transition) {

                this.$router.push({
                    name: name,
                    params: {
                        transition: transition || this.transition
                    }
                }).catch(err => { console.log('router error: ', err) });
            },
            slide(direction) {
                this.card.panels[direction] = !this.card.panels[direction];
            },
            changeMenuItem(index, name, transition) {
                // Check if we are already on it
                if (this.menuIndex === index) {
                    return;
                }
                this.menuIndex = index;
                this.route(name, transition);
            }
        },
        watch: {
            '$route'(to) {

                this.transition = (to.params.transition) ? to.params.transition : 'fade-in-transition';

                if (to.matched.some(record => record.meta.fullscreen)) {
                    this.fullscreen = true;
                } else {
                    this.fullscreen = false;
                }

            }
        },
        computed: {
            ...mapState({
                isMenuOpen: state => state.menu.open
            }),
            ...mapState('rightMenu', ['expanded']),
            ...mapState('profile', ['toDoPressed']),
            ...mapState('profile', ['myInfo']),
            getInnerWidth: function () {
                return window.innerWidth;
            }
        }
    }

</script>

<style scoped>

    .main-content {
        padding-bottom: 0px !important;
    }

    .main-content >>> .v-main__wrap  {
        max-width: 100% !important;
        padding-left: 4px;
    }

    .main-content-hidden >>> .v-main__wrap {
        max-width: 4px !important;
    }

    .menu-icon {
        color: rgba(255, 255, 255, 0.7) !important;
        font-size: 24px;
        cursor: pointer;
        transition: all 0.3s cubic-bezier(.25, .8, .50, 1);
    }

    .menu-icon.active {
        color: rgba(255, 255, 255, 1) !important;
        font-size: 28px;
        transition: all 0.3s cubic-bezier(.25, .8, .50, 1);
    }

    .menu-item.active {
        background-color: #177ad5;
    }

    .application--wrap {
        min-height: 100% !important;
        height: 100%;
    }

    .selected-white.v-list-group--active {
        color: white !important;
    }

    .small-input >>> .v-input {
        font-size: 12px !important;
    }
    .small-input >>> .v-input__control {
        min-height: 32px !important;
    }
    .small-input >>> .v-label {
        font-size: 12px !important;
    }

</style>
