<template>

    <div class="view">

        <i-card :viewIndex="card.index"
                :viewCount="1"
                :leftPanel="card.panels.left"
                :rightPanel="card.panels.right"
                :topPanel="card.panels.top"
                :bottomPanel="card.panels.bottom"
                class="i-card mr-2 br-2">

            <div class="i-card__main pa-2 pr-0 pt-0">

                <div class="i-card__main__header pa-2">

                    <communication-header :toggleMenuRight="toggleMenuRight">

                        <template v-slot:default>

                            <div style="width:72px">
                                <v-tooltip bottom
                                           nudge-bottom="0"
                                           nudge-right="32"
                                           color="ycmd-black"
                                           :disabled="$vuetify.breakpoint.xsOnly"
                                           content-class="fade-0 pa-4 pt-2 pb-2">
                                    <template v-slot:activator="{ on }">
                                        <v-btn icon dark small rounded
                                               @click="card.panels.bottom = true"
                                               v-on="on"
                                               class="ml-3 mr-4 ycmd-green">
                                            <v-icon :size="16">add</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>New Patient</span>
                                </v-tooltip>
                            </div>

                        </template>

                        <template v-slot:actions>

                            <input-search :onSearchChange="onSearchChange"
                                                  :hasAdvancedSearch="false"
                                                  :onSearchClick="onSearchClick"
                                                  :openRightPanel="_ => card.panels.right = true" />
                        </template>

                    </communication-header>

                </div>

                <div class="i-card__main__content white pa-2 br-2">

                    <sub-header-content v-if="items.length">

                        <template v-slot:header-start>

                            <v-row no-gutters>
                                <v-col cols="12" sm="6" md="4" lg="4">
                                    <div class="fixed-header__column--group fixed-header__text">Patient Name
                                        <v-btn icon color="ycmd-green" class="hidden">
                                            <v-icon :size="16">arrow_downward</v-icon>
                                        </v-btn>
                                    </div>
                                </v-col>
                                <v-col cols="12" sm="6" md="4" lg="4">
                                    <div class="fixed-header__column--group fixed-header__text">Facility
                                        <v-btn icon color="ycmd-blue" class="hidden">
                                            <v-icon :size="16">arrow_downward</v-icon>
                                        </v-btn>
                                    </div>
                                </v-col>
                            </v-row>

                            <!-- Spacing for list buttons -->
                            <div class="scrollbar-width-padding" style="width: 126px;">&nbsp;</div>

                        </template>

                        <template v-slot:header-end>

                            <counter :count="currentItems.toString()" :total="totalItems.toString()"/>

                        </template>

                    </sub-header-content>

                    <div class="i-card__scrollable">

                        <div class="list-container">

                            <v-container fluid v-for="(item, i) in items" :key="`item_${i}`" :class="`list-item flex-shrink-0 pa-3`">

                                <div class="d-flex flex-grow-1">

                                    <div class="d-flex flex-grow-1 flex-column">

                                        <v-row no-gutters>

                                            <v-col cols="12" sm="6" md="4" lg="4">
                                                <div :class="`list-item__column ${ $vuetify.breakpoint.smAndDown ? 'pb-4' : '' }`">
                                                    <div class="hidden-md-and-up label-title">Patient Name</div>
                                                    <div class="label-value fw-500">
                                                        {{ (item.first_name || item.last_name) ? `${item.first_name} ${item.last_name}` : 'N/A' }}
                                                    </div>
                                                </div>
                                            </v-col>

                                            <v-col cols="12" sm="6" md="4" lg="4">
                                                <div :class="`list-item__column ${ $vuetify.breakpoint.smAndDown ? 'pb-4' : '' }`">
                                                    <div class="hidden-md-and-up label-title">Facility</div>
                                                    <div class="label-value fw-500">
                                                        {{ item.location ? `${item.location.shortcode ? item.location.shortcode : 'N/A'}${item.location.unit && item.location.unit.name ? ' - ' + item.location.unit.name : ''}` : 'N/A' }}
                                                    </div>
                                                </div>
                                            </v-col>

                                        </v-row>

                                    </div>

                                </div>

                                <div class="d-flex flex-row justify-space-between align-center">

                                    <v-tooltip bottom
                                               nudge-bottom="10"
                                               nudge-right="50"
                                               :disabled="$vuetify.breakpoint.xsOnly"
                                               color="ycmd-black"
                                               content-class="fade-0 pa-4 pt-2 pb-2">
                                        <template v-slot:activator="{ on }">
                                            <v-btn icon dark color="ycmd-blue" v-on="on" @click="sendConsult" class="ml-2 mr-2">
                                                <v-icon :size="24">record_voice_over</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Send Consult</span>
                                    </v-tooltip>

                                    <v-tooltip bottom
                                               nudge-bottom="10"
                                               nudge-right="50"
                                               :disabled="$vuetify.breakpoint.xsOnly"
                                               color="ycmd-black"
                                               content-class="fade-0 pa-4 pt-2 pb-2">
                                        <template v-slot:activator="{ on }">
                                            <v-btn icon dark color="ycmd-blue" v-on="on" @click="() => discharge(item)" class="ml-2 mr-2">
                                                <v-icon :size="24">double_arrow</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Discharge</span>
                                    </v-tooltip>

                                </div>

                            </v-container>

                            <infinite-loading @infinite="infiniteHandler" :identifier="infiniteId" class="mt-2">
                                <div slot="no-more" class="infinite-loading__text ycmd-light-blue__text">No more data</div>
                                <div slot="no-results" class="infinite-loading__text ycmd-light-blue__text">No results</div>
                                <div slot="no-results">
                                    <div class="pt-4 mt-4 infinite-loading__text ycmd-light-blue__text">{{this.loadingMessageResult}}</div>
                                </div>
                                <div slot="spinner">
                                    <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
                                </div>
                            </infinite-loading>

                        </div>

                    </div>

                </div>

            </div>

            <template slot="right-panel">

                <div class="i-card__panel i-card__panel__right ycmd-blue-vertical-gradient pa-2 pr-0 pt-0">

                    <div class="i-card__panel__header br-2">

                        <div class="d-flex align-center flex-grow-1">

                            <v-tooltip bottom
                                       nudge-bottom="10"
                                       nudge-right="50"
                                       :disabled="$vuetify.breakpoint.xsOnly"
                                       color="ycmd-black"
                                       content-class="fade-0 pa-4 pt-2 pb-2">
                                <template v-slot:activator="{ on }">
                                    <v-btn icon dark v-on="on" @click="card.panels.right = false" class="ml-2 mr-2">
                                        <v-icon :size="20">close</v-icon>
                                    </v-btn>
                                </template>
                                <span>Cancel</span>
                            </v-tooltip>

                            <div class="white--text fs-14 ml-3">
                                Search Criteria
                            </div>
                        </div>
                        <div class="d-flex align-center justify-end ml-2">

                        </div>
                    </div>

                    <div class="i-card__panel__content white br-2 br_tr-0 pa-2">

                        <div class="i-card__scrollable">
                            <div class="pa-3">
                                Content
                            </div>
                        </div>
                    </div>
                </div>

            </template>

            <template slot="bottom-panel">

                <div class="i-card__panel i-card__panel__bottom ycmd-blue-vertical-gradient pa-2 pr-0 pt-0">

                    <div class="i-card__panel__header">

                        <div class="d-flex align-center flex-grow-1">

                            <v-tooltip bottom
                                       nudge-bottom="10"
                                       nudge-right="50"
                                       :disabled="$vuetify.breakpoint.xsOnly"
                                       color="ycmd-black"
                                       content-class="fade-0 pa-4 pt-2 pb-2">
                                <template v-slot:activator="{ on }">
                                    <v-btn icon dark v-on="on" @click="card.panels.bottom = false" class="ml-2 mr-2">
                                        <v-icon :size="20">close</v-icon>
                                    </v-btn>
                                </template>
                                <span>Cancel</span>
                            </v-tooltip>

                            <div class="white--text fs-14 ml-3">
                                Add Patient
                            </div>
                        </div>
                        <div class="d-flex align-center justify-end ml-2">

                        </div>
                    </div>

                    <div class="i-card__panel__content white br-2 br_tr-0 pa-2">

                        <div class="i-card__scrollable">

                            <!-- Title first row -->
                            <section-title :title="`Add A New Patient`"/>

                            <section-content>

                                <template v-slot:section-content>

                                    <v-form
                                          ref="addPatientForm"
                                          v-model="validPatientForm"
                                          class="full-width">
                                        <div>
                                            <v-row>
                                                <v-col cols="8" sm="5" md="5">
                                                    <div class="label-title">First Name</div>
                                                    <v-text-field light solo dense flat outlined
                                                                  name="input-firstname"
                                                                  :rules="[rules.required]"
                                                                  v-model="firstName" />
                                                </v-col>
                                                <v-col cols="4" sm="2" md="2">
                                                    <div class="label-title">M.I.</div>
                                                    <v-text-field light solo dense flat outlined
                                                                  name="input-firstname"
                                                                  v-model="middleInitial" />
                                                </v-col>
                                                <v-col cols="6" sm="5" md="5">
                                                    <div class="label-title">Last Name</div>
                                                    <v-text-field light solo dense flat outlined
                                                                  name="input-lastname"
                                                                  :rules="[rules.required]"
                                                                  v-model="lastName" />
                                                </v-col>
                                                <v-col cols="12" sm="12" md="12">
                                                    <div class="label-title">Facility</div>
                                                    <v-text-field light solo dense flat outlined
                                                                  name="input-lastname"
                                                                  :rules="[rules.required]"
                                                                  v-model="facilitySelected" />
                                                </v-col>
                                            </v-row>
                                        </div>
                                    </v-form>

                                </template>

                            </section-content>

                        </div>

                    </div>
                </div>

            </template>

        </i-card>

        <v-dialog light v-model="dialog" max-width="400">

            <v-card v-if="currentDialog==='dischargeUser'" color="ycmd-blue">

                <v-card-title class="white--text">
                    <v-icon dark class="mr-4">error_outline</v-icon> Discharge User
                </v-card-title>

                <v-card-text class="white pt-4">
                    <div class="fs-14">Are you sure you want to discharge the user?</div>
                    <div class="red--text pt-4 fs-18">{{currentSelectedUser.first_name}} {{currentSelectedUser.last_name}} </div>
                </v-card-text>

                <v-card-actions>
                    <v-btn dark text color="white" @click="clearDialog()">Cancel</v-btn>
                    <v-spacer/>
                    <v-btn dark text @click="disableAdmin()">Discharge</v-btn>
                </v-card-actions>

            </v-card>

        </v-dialog>

    </div>

</template>

<script>

    import {mapActions, mapState} from "vuex";
    import { format, parseISO } from 'date-fns';
    import Loading from 'vue-loading-overlay';
    import InfiniteLoading from 'vue-infinite-loading';
    import {defaultErrorHandler} from '@/graphql/handler/errorHandler';

    import SectionTitle from '@/components/sections/section-title';
    import SectionContent from '@/components/sections/section-content';

    import CommunicationHeader from '../../communication/components/communication-header';
    import InputSearch from '@/components/input-search';
    import SubHeaderContent from '@/components/header/sub-header-content';
    import Counter from '@/components/header/items-counter';

    import {formatPhoneNumber} from '@/methods/globalMethods';
    import GET_PATIENTS from '@/graphql/queries/phoenix/ycmdPatientGet';

    export default {
        name: 'patients',
        components: {
            'sub-header-content': SubHeaderContent,
            'counter': Counter,
            'communication-header': CommunicationHeader,
            'input-search': InputSearch,
            'section-title': SectionTitle,
            'section-content': SectionContent,
            Loading,
            InfiniteLoading
        },
        apollo: {
            $client: 'phoenixClient'
        },
        data() {
            return {
                format,
                parseISO,
                formatPhoneNumber,
                card: {
                    index: 0,
                    panels: { left: false, right: false, top: false, bottom: false }
                },
                dialog: false,
                currentSelectedUser: {},
                currentDialog: null,

                items: [],
                item: {},

                page: 1,
                limit: 20,
                searchValue: '',
                infiniteId: 1,
                loadingMessageResult: '',
                rules: {
                    required: value => !!value || 'Required.',
                    min: v => v.length >= 8 || 'Min 8 characters'
                },

                currentItems: 0,
                totalItems: 0,

                validPatientForm: false,
                firstName: '',
                lastName: '',
                middleInitial: '',
                facilitySelected: ''
            }
        },
        created() {},
        mounted() {},
        activated() {},
        watch: {
            searchValue(value) {
                this.submitSearch();
            }
        },
        methods: {
            ...mapActions('menuRight', ['openMenuRight','closeMenuRight','toggleMenuRight']),
            changeView(view, params) {

                this.$emit('onChangeView', {view: view, params: params});
            },
            onSearchClick(e, value) {

                this.submitSearch();
                // console.log('submit search for: ', this.searchValue);

            },
            onSearchChange(e, value) {

                // console.log(value);
                this.searchValue = value;

            },
            submitSearch() {
                this.items = [];
                this.page = 1;
                this.infiniteId += 1;
            },
            slide(direction) {
                this.card.panels[direction] = !this.card.panels[direction];
            },
            clearDialog() {
                this.dialog = false;
                this.currentDialog = null;
                this.currentSelectedUser = {};
            },
            route(name, transition) {

                this.$router.push({
                    name: name, params: {
                        transition: transition
                    }
                }).catch(err => {
                    console.log('router error: ', err)
                });
            },
            async sendConsult() {
                console.log('sending consult');
            },
            async discharge(item) {
                this.dialog = true;
                this.currentDialog = 'dischargeUser';
                this.currentSelectedUser = item;
                console.log('discharge action');
            },
            async infiniteHandler($state) {

                this.isLoading = true;

                try {
                    const response = await this.$apollo.query({
                        query: GET_PATIENTS,
                        variables: {
                            page: this.page,
                            limit: this.limit,
                            search: this.searchValue
                        },
                        fetchPolicy: 'no-cache',
                        errorPolicy: 'all'
                    });

                    console.log('page: ', this.page);
                    console.log('response: ', response);
                    this.isLoading = false;

                    if (response.data.ycmdPatientGet && response.data.ycmdPatientGet && response.data.ycmdPatientGet.patients) {

                        if (response.data.ycmdPatientGet.patients.length) {
                            this.items.push(...response.data.ycmdPatientGet.patients);
                            this.page++;
                            $state.loaded();
                        } else {
                            $state.complete();
                        }

                        // Update count
                        this.currentItems = this.items.length;
                        if (response.data.ycmdPatientGet.total_count) {
                            this.totalItems = response.data.ycmdPatientGet.total_count;
                        }

                    } else {
                        // data did not come back
                        //console.log('pcpMessages data did not come back or is finished: ', response);
                        this.loadingMessageResult = 'No results were found';
                        $state.loaded();
                        $state.complete();
                    }
                } catch (e) {
                    $state.loaded();
                    $state.complete();
                    // defaultErrorHandler(e);
                }
            }
        },
        computed: {
            ...mapState({
                isMenuOpen: state => state.menu.open,
                isMenuRightOpen: state => state.menuRight.open
            })
        }
    }

</script>

<style scoped>

    .tab {
        color: white !important;
    }

    .tabs-holder {
        width: 270px;
        margin-bottom: -8px;
        margin-left: -4px;
    }

    .tabs--active {
        background-color: white;
        color: #167DD7 !important;
    }

    .message__section {
        height: 200px;
    }

    .list-item__row {
        width: 100%;
        padding-right: 20px;
        margin-top: 5px;
        margin-bottom: 5px;
    }

    @media (max-width: 600px) {
        .list-container {
            font-size: 10px;
            padding: 0 0 8px 0;
        }
    }

    /* Small Breakpoint */
    @media (min-width: 600px) {

    }

    /* Medium Breakpoint */
    @media (min-width: 960px) {
        .list-item__column {
            display: flex;
            align-items: center;
            height: 100%;
        }
        .label-value {
            padding-bottom: 0;
        }
    }

    /* Large Breakpoint */
    @media (min-width: 1264px) {

    }

    /* XLarge Breakpoint */
    @media (min-width: 1904px) {

    }

</style>
