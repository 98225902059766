<template>
  <div

  >
    <label 
      class="v-label v-label--active theme--light fs-10"
      v-if="label"
    >{{label}}</label>

    <v-container
      class="pa-0 ma-0 "  
      v-if="horizontal"              
      >        

        <v-row
          class="ma-0 pa-0"               >
          <v-col
              class="pa-0 ma-0 shrink"
              v-for="(item,i) in items"
              :key="`col_${item.id}_${i}`"              
          > 
            <v-checkbox
              v-model="localValues[item.id]"
              hide-details dense
            >
            <template v-slot:label>

              <span class="fs-12 font-weight-bold">{{item.name}}</span>

            </template>
            </v-checkbox>
          </v-col>
        </v-row>
    </v-container>

    <v-list
      select-strategy="classic"
      dense
      class="pa-0 ma-0"
      v-else
    >
      <v-list-item
        v-for="(item,i) in items"
        :key="`key_${item.id}_${i}`"
        :value="item.id"
        class="pa-0 ma-0"
        >

          <v-list-item-action
            class="pa-0 ma-0"
          >
            <v-checkbox v-model="localValues[item.id]" hide-details dense></v-checkbox>
          </v-list-item-action>

        <v-list-item-title>{{ item.name }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </div>    
</template>

<script>

export default {

  props: {
    value: {
      default: () => [],
      type: Array
    },
    items: {
      default: () => [],
      type: Array
    },
    label: {
      default: '',
      type: String
    },
    horizontal: {
      default: false,
      type: Boolean
    },

    
  },
  data: () => ({
    localValues: {}
  }),
  methods: {
    setValue (arr) {

      let current = {}
      
      for (let id in this.localValues) {
        if (this.localValues[id]) {
          current[id] = true
        }
      }

      let examined = {}

      if (arr && arr.length) {
        arr.map(a => {
          if (current[a]) {
            //same
          } else {
            this.$set(this.localValues,a,true)
          }

          examined[a] = true
          
        })
      }
    
      for (let id in this.localValues) {
        if (!examined[id]) {
          this.$set(this.localValues,id,false)
        }
      }

    }
  },
  computed: {

  },
  watch: {
    localValues: {
        handler (val, oldVal) {
          let arr = []
          for (let id in val) {
            if (val[id]) {
              arr.push(id)
            }
          }
          this.$emit('input', arr)
        },
        deep: true
    },
    value(newValue, oldValue) {
      this.setValue(newValue)
    }
  },
  mounted () {
    if (this.value && this.value.length) {
      this.value.map(v => {
        this.$set(this.localValues,v,true)
      })
    }
  }
}
</script>