<template>

    <div class="view">

        <v-overlay :value="isLoading" :absolute="true" color="#2389e8" opacity="0.3">
            <div class="d-flex align-center justify-center" >
                <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
            </div>
        </v-overlay>

        <i-card :viewIndex="card.index"
                :viewCount="1"
                :leftPanel="card.panels.left"
                :rightPanel="card.panels.right"
                :topPanel="card.panels.top"
                :bottomPanel="card.panels.bottom"
                class="i-card mr-2 br-2">

            <div class="i-card__main pa-2 pr-0 pt-0">

                <div class="i-card__main__header pa-2 pl-0">

                    <div class="d-flex align-center flex-grow-1">

                        <v-tabs v-model="userType" dark background-color="transparent">

                            <v-tabs-slider color="ycmd-green"></v-tabs-slider>

                            <v-tab v-for="tab of tabs" :key="tab.id">
                                {{tab.name}}
                            </v-tab>

                        </v-tabs>

                        <v-spacer />

                        <v-tooltip bottom
                                   nudge-bottom="0"
                                   nudge-right="32"
                                   color="ycmd-black"
                                   :disabled="$vuetify.breakpoint.xsOnly"
                                   content-class="fade-0 pa-4 pt-2 pb-2">
                            <template v-slot:activator="{ on }">
                                <v-btn dark text small
                                       @click="createUserValidation"
                                       v-on="on"
                                       class="ycmd-light-blue mr-2">
                                   Save
                               </v-btn>
                           </template>
                           <span>Save</span>
                       </v-tooltip>

                    </div>

                </div>

                <div class="i-card__main__content white pa-2 br-2">

                    <div class="i-card__scrollable">

                        <div class="list-container">

                            <!-- Title first row -->
                            <section-title :title="`User Info`"/>

                            <section-content>

                                <template v-slot:section-content>

                                    <v-form
                                          ref="editNewUserInfoForm"
                                          v-model="validNewUserInfo"
                                          class="full-width">

                                        <v-row class="">

                                            <v-col cols="12" sm="6" md="6">
                                                <div class="label-title">First Name</div>
                                                <v-text-field light solo dense flat outlined
                                                              :rules="[rules.required]"
                                                              v-model="firstName" />
                                            </v-col>
                                            <v-col cols="12" sm="6" md="6">
                                                <div class="label-title">Last Name</div>
                                                <v-text-field light solo dense flat outlined
                                                              :rules="[rules.required]"
                                                              v-model="lastName" />
                                            </v-col>
                                            <v-col cols="12" sm="6" md="6" lg="4">
                                                <div class="label-title">Address</div>
                                                <v-text-field light solo dense flat outlined
                                                              :rules="[rules.required]"
                                                              v-model="address" />
                                            </v-col>
                                            <v-col cols="12" sm="6" md="6" lg="4">
                                                <div class="label-title">City</div>
                                                <v-text-field light solo dense flat outlined
                                                              :rules="[rules.required]"
                                                              v-model="city" />
                                            </v-col>
                                            <v-col cols="12" sm="6" md="6" lg="4">
                                                <div class="label-title">State</div>
                                                <v-autocomplete
                                                    light dense flat outlined solo
                                                    :rules="[rules.required]"
                                                    v-model="state"
                                                    :items="states"
                                                    label="Select State"
                                                    item-value="value"
                                                    item-text="name"
                                                    background-color="white"
                                                    clearable
                                                    hide-selected
                                                ></v-autocomplete>
                                            </v-col>
                                            <v-col cols="12" sm="6" md="6" lg="4">
                                                <div class="label-title">Zip Code</div>
                                                <v-text-field light solo dense flat outlined
                                                              :rules="[rules.required]"
                                                              v-model="zipCode" />
                                            </v-col>

                                        </v-row>

                                        <v-row class="">

                                            <v-col cols="12" sm="12" md="12" lg="6">
                                                <div class="label-title">NPI Number</div>
                                                <v-text-field light solo dense flat outlined
                                                              v-model="npiNumber" />
                                            </v-col>

                                        </v-row>

                                        <v-row class="">

                                            <template v-if="userType === 0">
                                                <v-col cols="12" sm="6" md="6" lg="6">
                                                    <div class="label-title">Title</div>
                                                    <v-select light dense flat outlined solo
                                                        v-model="titleSelected"
                                                        :items="providerTitles"
                                                        label="Leave blank if none"
                                                        item-text="name"
                                                        item-value="value"
                                                        background-color="white" />
                                                </v-col>
                                                <v-col cols="12" sm="6" md="6" lg="6">
                                                    <div class="label-title">Speciality</div>
                                                    <v-select light dense flat outlined solo
                                                        v-model="specialitySelected"
                                                        :items="providerSpecialities"
                                                        label="Leave blank if none"
                                                        item-text="name"
                                                        item-value="value"
                                                        background-color="white" />
                                                </v-col>
                                            </template>
                                            <template v-if="userType === 1">
                                                <v-col cols="12" sm="6" md="6" lg="6">
                                                    <div class="label-title">Member Role</div>
                                                    <v-select light dense flat outlined solo
                                                        v-model="memberRole"
                                                        :items="memberRoles"
                                                        :rules="[rules.required]"
                                                        label="Select a role"
                                                        item-text="name"
                                                        item-value="value"
                                                        background-color="white" />
                                                </v-col>
                                            </template>

                                        </v-row>

                                    </v-form>

                                </template>

                            </section-content>

                            <!-- Title 2nd row -->
                            <section-title :title="`Contact Information`"/>

                            <section-content>

                                <template v-slot:section-content>

                                    <v-form
                                          ref="editNewUserContactForm"
                                          v-model="validNewUserContact"
                                          class="full-width">

                                        <v-row>

                                            <v-col cols="12" sm="12" md="6">
                                                <div class="label-title">Email Address</div>
                                                <v-text-field light solo dense flat outlined
                                                              :rules="[rules.required, rules.email]"
                                                              v-model="email" />
                                            </v-col>
                                            <v-col cols="12" sm="12" md="6">
                                                <div class="label-title">Phone Number</div>
                                                <v-text-field light solo dense flat outlined
                                                              v-mask="masks.phone"
                                                              :rules="[rules.required]"
                                                              v-model="phoneNumber" />
                                            </v-col>

                                        </v-row>

                                    </v-form>

                                </template>

                            </section-content>

                            <!-- Title 3rd row -->
                            <section-title :title="`Account Information`"/>

                            <section-content>

                                <template v-slot:section-content>

                                    <v-form
                                          ref="editNewUserAccountForm"
                                          v-model="validNewUserAccount"
                                          class="full-width">

                                        <v-row>

                                            <v-col cols="12" sm="12" md="6">
                                                <div class="label-title">Username</div>
                                                <v-text-field light solo dense flat outlined
                                                              :rules="[rules.required]"
                                                              v-model="username" />
                                            </v-col>
                                            <v-col cols="12" sm="12" md="6">
                                                <div class="label-title">Password</div>
                                                <v-text-field
                                                    light solo dense flat outlined
                                                    v-model="password"
                                                    :append-icon="showPassword ? 'visibility' : 'visibility_off'"
                                                    :rules="[rules.required]"
                                                    :type="showPassword ? 'text' : 'password'"
                                                    @click:append="showPassword = !showPassword"
                                                ></v-text-field>
                                            </v-col>

                                        </v-row>

                                    </v-form>

                                </template>

                            </section-content>

                        </div>

                    </div>

                </div>

            </div>

        </i-card>

    </div>

</template>

<script>

    import {mapActions, mapState} from "vuex";
    import { format, parseISO } from 'date-fns';
    import InfiniteLoading from 'vue-infinite-loading';
    import {defaultErrorHandler} from '@/graphql/handler/errorHandler';
    import { states, memberRoles, providerTitles, providerSpecialities } from '@/data/groupApp/dataTypes';

    import SectionTitle from '@/components/sections/section-title';
    import SectionContent from '@/components/sections/section-content';

    import {formatPhoneNumber} from '@/methods/globalMethods';
    import CREATE_USER from '@/graphql/mutations/phoenix/ycmdUserCreate';

    export default {
        name: 'newAccountPage',
        components: {
            'section-title': SectionTitle,
            'section-content': SectionContent,
            InfiniteLoading
        },
        apollo: {
            $client: 'phoenixClient'
        },
        data() {
            return {
                format,
                parseISO,
                formatPhoneNumber,
                providerTitles,
                providerSpecialities,
                states,
                memberRoles,
                card: {
                    index: 0,
                    panels: { left: false, right: false, top: false, bottom: false }
                },
                masks: {
                    phone: '(###) ###-####'
                },
                isLoading: false,
                tabs: [
                    {
                        id: 0,
                        name: 'Provider',
                        value: 'provider'
                    },
                    {
                        id: 1,
                        name: 'Member',
                        value: 'member'
                    }
                ],
                isEditable: true,
                userType: 'member',
                rules: {
                    required: value => !!value || 'Required.',
                    min: v => v && v.length >= 8 || 'Min 8 characters',
                    email: value => {
                      const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                      return pattern.test(value) || 'Invalid e-mail';
                    }
                },

                validNewUserInfo: false,
                validNewUserContact: false,
                validNewUserAccount: false,

                firstName: '',
                lastName: '',
                address: '',
                city: '',
                state: '',
                zipCode: '',
                npiNumber: '',
                memberRole: null,
                email: '',
                phoneNumber: '',
                username: '',
                password: '',
                showPassword: false,

                titleSelected: null,
                specialitySelected: null
            }
        },
        created() {},
        mounted() {},
        activated() {},
        watch: {
            userType(val) {
                console.log(val);
            }
        },
        methods: {
            ...mapActions('menuRight', ['openMenuRight','closeMenuRight','toggleMenuRight']),
            changeView(view, params) {

                this.$emit('onChangeView', {view: view, params: params});
            },
            slide(direction) {
                this.card.panels[direction] = !this.card.panels[direction];
            },
            route(name, transition) {

                this.$router.push({
                    name: name, params: {
                        transition: transition
                    }
                }).catch(err => {
                    console.log('router error: ', err)
                });
            },
            createUserValidation() {
                console.log(this.memberRole)
                this.$refs.editNewUserInfoForm.validate();
                this.$refs.editNewUserContactForm.validate();
                this.$refs.editNewUserAccountForm.validate();
                console.log(this.validNewUserInfo, this.validNewUserContact, this.validNewUserAccount);
                if (this.validNewUserInfo && this.validNewUserContact && this.validNewUserAccount) {
                    console.log('validation passed');
                    this.createUser();
                }

            },
            async createUser() {

                this.isLoading = true;

                console.log('SENDING: ', {
                    address: this.address || null,
                    city: this.city || null,
                    coordinator: null,
                    email: this.email || null,
                    first_name: this.firstName || null,
                    group_id: null,
                    last_name: this.lastName || null,
                    password: this.password || null,
                    member_role: this.memberRole || null,
                    mobile_phone: this.phoneNumber || null,
                    moonlighter: null,
                    npi_number: this.npiNumber || null,
                    state: this.state || null,
                    specialty: this.specialitySelected || null,
                    title: this.titleSelected || null,
                    user_type: this.tabs[this.userType].value || null,
                    username: this.username || null,
                    zip_code: this.zipCode || null
                });

                try {
                    const response = await this.$apollo.mutate({
                        mutation: CREATE_USER,
                        variables: {
                            user: {
                                address: this.address || null,
                                city: this.city || null,
                                coordinator: null,
                                email: this.email || null,
                                first_name: this.firstName || null,
                                group_id: null,
                                last_name: this.lastName || null,
                                password: this.password || null,
                                member_role: this.memberRole || null,
                                mobile_phone: this.phoneNumber || null,
                                moonlighter: null,
                                npi_number: this.npiNumber || null,
                                state: this.state || null,
                                specialty: this.specialitySelected || null,
                                title: this.titleSelected || null,
                                user_type: this.tabs[this.userType].value || null,
                                username: this.username || null,
                                zip_code: this.zipCode || null
                            }
                        },
                        fetchPolicy: 'no-cache',
                        errorPolicy: 'all'
                    });
                    this.isLoading = false;
                    console.log('response: ', response);

                    // update
                    if (response.data && response.data.ycmdUserCreate) {
                        this.$refs.editNewUserInfoForm.reset();
                        this.$refs.editNewUserContactForm.reset();
                        this.$refs.editNewUserAccountForm.reset();

                        this.$toasted.success(`Successfully created new user!`);
                    } else {
                        if (response.errors.length && response.errors[0].message) {
                            this.$toasted.error(`Failed to create user - ${response.errors[0].message}`);
                        } else {
                            this.$toasted.error(`Failed to create user`);
                        }
                    }
                } catch (e) {
                    this.isLoading = false;
                    // defaultErrorHandler(e);
                    console.log('Catch error: ', e);
                }
            }
        },
        computed: {
            ...mapState({
                isMenuOpen: state => state.menu.open,
                isMenuRightOpen: state => state.menuRight.open
            })
        }
    }

</script>

<style scoped>

    .tab {
        color: white !important;
    }

    .tabs-holder {
        width: 270px;
        margin-bottom: -8px;
        margin-left: -4px;
    }

    .tabs--active {
        background-color: white;
        color: #167DD7 !important;
    }

    .message__section {
        height: 200px;
    }

    .list-item__row {
        width: 100%;
        padding-right: 20px;
        margin-top: 5px;
        margin-bottom: 5px;
    }

    @media (max-width: 600px) {
        .list-container {
            font-size: 10px;
            padding: 0 0 8px 0;
        }
    }

    /* Small Breakpoint */
    @media (min-width: 600px) {

    }

    /* Medium Breakpoint */
    @media (min-width: 960px) {
        .list-item__column {
            display: flex;
            align-items: center;
            height: 100%;
        }
        .label-value {
            padding-bottom: 0;
        }
    }

    /* Large Breakpoint */
    @media (min-width: 1264px) {

    }

    /* XLarge Breakpoint */
    @media (min-width: 1904px) {

    }

</style>
