<template>

    <div >
        <div
            dense
            class="pa-0 ma-0"
            v-if="items && items.length"
            :style="scroll ? 'max-height:200px;overflow-y:auto;' : ''"
        >
            <template
            v-for="(item,i) in items"
            >
                <div 
                    :key="`item_${i}`"
                    :id="`shift_${item.id}_${i}`"
                    @click.stop="selectShift(item)"
                    :class="`contact pa-0 ma-0`"
                    :style="item.style"
                >
                    <span
                    class="pa-0 ma-0"
                    >
                    {{item.name}}
                    </span>
                </div>
            </template>      

        </div>
    </div>

</template>

<script>
import {mapActions, mapGetters} from "vuex";

    export default {
        name: 'scheduleShift',
        components: {
        },
        props: {
            shifts: {
                type: Array,
                default: () => []
            },
            providers: {
                type: Array,
                default: () => []
            },
            date: {
                type: String,
                default: ''
            },
            assignments: {
                type: Object,
                default: () => {}
            }, 
            personalCoverage: {
                type: Object,
                default: () => {}
            },    
            providerMap: {
                type: Object,
                default: () => {}
            },                         
            scroll: {
                type: Boolean,
                default: true
            },
            dayWidth: {
                type: Number,
                default: 165
            }, 
            personalCoverageOnRounding : {
                type: Boolean,
                default: false
            },          
        },
        data: () => ({
            //selectedDuration: '',
        }),
        created() {

        },
        async mounted() {

        },
        watch: {
        },
        methods: {
            selectShift (shift) {
                this.$emit('shiftClick',{date: this.date, shift: shift})
            },
            twoDigit (num) {
                let str = num + ''
                if (str.length === 1) {
                    str = '0' + str
                }
                return str
            },
            getCoverage(assignment) {

                let userCoverageArray = this.personalCoverage[assignment.user_id]
                let coverage
                if (userCoverageArray && userCoverageArray.length) {

                    //console.log('personalCoverage', this.personalCoverage)
                    //console.log('assignment', assignment)

                    let startDateKey = `${this.date}T00:00:00.000Z`
                    let endDateKey = `${this.date}T23:59:59.999Z`

                    let personal = userCoverageArray.find(c => {

                        let startKey = `${c.start_year}-${this.twoDigit(c.start_month)}-${this.twoDigit(c.start_day)}T${this.twoDigit(c.start_hours)}:${this.twoDigit(c.start_minutes)}:${this.twoDigit(c.start_seconds)}.000`
                        let endKey = `${c.end_year}-${this.twoDigit(c.end_month)}-${this.twoDigit(c.end_day)}T${this.twoDigit(c.end_hours)}:${this.twoDigit(c.end_minutes)}:${this.twoDigit(c.end_seconds)}.000`

                        if (startDateKey >= startKey && startDateKey <= endKey) {
                            return true
                        }
                        if (endDateKey >= startKey && endDateKey <= endKey) {
                            return true
                        }                        
                        /*
                        if (startDateKey >= c.start && startDateKey <= c.end) {
                            return true
                        }
                        if (endDateKey >= c.start && endDateKey <= c.end) {
                            return true
                        }
                        */
                    })

                    if (personal) {

                        let coverer = this.providerMap[personal.personal_coverer]

                        let color = this.getColors[this.getColors.length-1]
                        
                        if (coverer && coverer.user_index >= 0) {
                            color = this.getColors[coverer.user_index]
                        }
                        if (color) {
                            
                            coverage = {
                                user_id: personal.personal_coverer,
                                id: assignment.id,
                                fg: color[1],
                                bg: color[0],
                                border: color[2]
                            }

                        }
                    }

                } 

                return coverage
            },

            getAssignmentGradient (shift, assignment) {

                let coverage
                if (shift.rotation || shift.rounding) {
                    if (this.personalCoverageOnRounding) {
                        coverage = this.getCoverage(assignment)
                    }
                } else {
                    coverage = this.getCoverage(assignment)
                }

                if (coverage) {
                        let diff = 5

                        let j = 0
                        let i = 0
                        let newGradient = ''
                        while (i <= 100) {
//                    gradientStyle = 'linear-gradient(45deg,#911eb4 25%, #e6194b 25%, #e6194b 50%, #911eb4 50%, #911eb4 75%, #e6194b 75%, #e6194b 100%)'                   
                            if (newGradient) {
                                newGradient +=', '
                            }

                            let odd = (j++ % 2)
                            
                            if (i > 0 ) {
                                if (odd) {
                                    newGradient += ` ${assignment.bg} ${i}% `
                                    if (i < 100) {
                                        newGradient += `, ${coverage.bg} ${i}% `
                                    }
                                } else {
                                    newGradient += ` ${coverage.bg} ${i}% `
                                    if (i < 100) {
                                        newGradient += `, ${assignment.bg} ${i}% `
                                    }
                                }
                            }

                            i += diff

                        }

                        let coverageGradient = `linear-gradient(45deg,${newGradient})`
                        return coverageGradient

                }
                let gradient = `linear-gradient(to right, ${assignment.bg} 0%, ${assignment.bg} 100%)`
                return gradient   
            },

            calculateStyle (assignments, s) {

                if (!assignments || !assignments.length) {
                    return 'background-color: #ebf3f9;'
                }

                let gradients = new Array(assignments.length)
                for (let i = 0;i<assignments.length;i++) {
                    let assignment = assignments[i]
                    gradients[i] = this.getAssignmentGradient(s,assignment)
                }

                let gradientStyle
                let style

                let ts = "text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;"

                if (gradients.length === 1) {                    
                    gradientStyle = gradients[0]
                    style = `${ts}color: #ffffff;width: 100%;height: 100%;background-image:${gradientStyle}`
                } else {
                  
                    let increment = 100 / gradients.length

                    gradientStyle = ''
                                        
                    let backgroundPosition=""
                    let backgroundSize=""

                    for (let i=0;i<gradients.length;i++) {
                
                        let pixels = (i*increment / 100) * this.dayWidth

                        //let current = `${i*increment}%`
                        let currentPixels = `${pixels}px`

                        if (gradientStyle) {
                            gradientStyle += ','
                        }
                        gradientStyle += gradients[i]

                        if (backgroundPosition) {
                            backgroundPosition += ','
                        }
                        backgroundPosition += `left ${currentPixels} bottom 100% `

                        if (backgroundSize) {
                            backgroundSize += ','
                        }
                        //backgroundSize += `${(i+1)*increment}% 100%`
                        backgroundSize += `${(increment/100) * this.dayWidth}px 100%`

                    }

                    style = `${ts}color: #ffffff;width: 100%;height: 100%;background-image:${gradientStyle};background-position: ${backgroundPosition};background-size: ${backgroundSize};background-origin: content-box;`

                }

                return style

            }                                     
        },
        computed: {
            ...mapGetters(
                'schedule',['getColors',]
            ),   
            items () {
                let ret = []

                if (this.shifts && this.shifts.length) {
                    this.shifts.map(s => {
                        //console.log(`shift ${s.id}`, this.assignments[s.id])
                        let assignments = this.assignments[s.id]

                        let style = this.calculateStyle(assignments, s)
                        
                        ret.push({
                            name: s.name,
                            id: s.id,
                            style: style,
                            //style: "width: 100%;height: 100%;background-image:linear-gradient(to right,red    20%,green  20%,green  40%,blue   40%,blue   60%,purple 60%,purple 80%,yellow 80%,yellow 100%)",
                            assignments
                        })
                    })
                }

                return ret
            }
        }
    }
</script>
<style scoped>

.location-select >>> label {
    padding-left: 12px;
}
.location-select >>> .v-select__selections {
    padding-left: 12px;
}




</style>