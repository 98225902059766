<template>

    <div class="d-flex fill-height ycmd-dark-blue-radial-gradient full-width pa-2 br-2">

      <loading :active.sync="isLoading"
              :can-cancel="false"
              :is-full-page="false"
              :background-color="`#101622`"
              :color="`#637eb6`"
              :loader="`bars`">
      </loading>

      <div class="d-flex fill-height full-width flex-column">

            <div class="d-flex align-center white--text pa-2 pb-3">

                <div class="fs-16 fw-500">CREATE PASSWORD</div>
                <v-spacer />
                <v-btn dark icon text @click="route('login', 'down')">
                    <v-icon>close</v-icon>
                </v-btn>
            </div>

            <div class="d-flex flex-column fill-height full-width">

                    <div class="d-flex flex-column flex-grow-1 full-width pa-3 br-2">

                        <v-form
                          ref="passwordResetForm"
                          v-model="validPasswordReset"
                          :lazy-validation="false"
                          class="pt-3"
                        >

                            <v-layout column class="mb-4 fs-12" style="" style="text-align: center; color: white;">
                                Password must be at least 8 characters, 1 lowercase, 1 uppercase, and at least 1 number
                            </v-layout>

                            <v-text-field
                              solo dark dense flat
                              v-model="newPassword"
                              :append-icon="showPassword ? 'visibility' : 'visibility_off'"
                              :rules="[rules.required]"
                              :type="showPassword ? 'text' : 'password'"
                              :tabindex="1"
                              label="New Password"
                              color="#ffffff"
                              v-on:keyup.enter="() => submitPassword()"
                              @input="extraPasswordCheck()"
                              background-color="ycmd-dark-blue"
                              @click:append="showPassword = !showPassword"
                            ></v-text-field>
                            <v-text-field
                              solo dark dense flat
                              v-model="newPasswordConfirm"
                              class="pt-2"
                              :append-icon="showConfirmPassword ? 'visibility' : 'visibility_off'"
                              :rules="[rules.required]"
                              :type="showConfirmPassword ? 'text' : 'password'"
                              :tabindex="2"
                              label="Confirm Password"
                              color="#ffffff"
                              v-on:keyup.enter="() => submitPassword()"
                              @input="extraPasswordCheck()"
                              background-color="ycmd-dark-blue"
                              @click:append="showConfirmPassword = !showConfirmPassword"
                            ></v-text-field>
                        </v-form>

                        <div style="color: #FF5252">{{newPasswordError}}</div>

                        <v-layout column class="fs-8" style="color: #FC5252; padding: 0px; text-align: center">
                            {{extraPasswordError}}
                        </v-layout>

                    </div>

                    <div class="d-flex flex-column align-center">

                        <div class="full-width pa-1 pl-0 pr-0">
                            <v-btn dark block large depressed color="ycmd-light-blue" :disabled="validPasswordResetCheck" @click="submitPassword">Continue</v-btn>
                        </div>

                    </div>
            </div>
        </div>

        <v-dialog persistent v-model="dialog" max-width="330" style="">

          <v-card v-if="currentDialog === 'loginFirst'" color="" dark style="background-color: #09283F; padding-top: 15px;">
            <v-card-text class="fs-18">
              Please log in first before trying to set your password!
            </v-card-text>


            <v-card-actions>
              <v-spacer/>
              <v-btn text color="#FC5252" @click="() => {this.route('login', 'fade-in-transition')}">Login</v-btn>
              <v-spacer/>
            </v-card-actions>
          </v-card>

          <v-card v-if="currentDialog === 'createSuccess'" color="" dark style="background-color: #09283F; padding-top: 15px;">
            <v-card-text class="fs-18">
              You have successfully created your account!
            </v-card-text>


            <v-card-actions>
              <v-spacer/>
              <v-btn text color="#00b300" @click="() => {this.route('login', 'fade-in-transition')}">Login</v-btn>
              <v-spacer/>
            </v-card-actions>
          </v-card>

        </v-dialog>

    </div>

</template>

<script>

    import { defaultErrorHandler } from '@/graphql/handler/errorHandler';

    // Import component
    import Loading from 'vue-loading-overlay';
    // Import stylesheet
    import 'vue-loading-overlay/dist/vue-loading.css';

    import LOGIN_CONTINUE from '../../graphql/mutations/authenticateContinue';

    export default {
        name: 'createPassword',
        data: () => ({
            dialog: false,
            validPasswordReset: false,
            currentDialog: 'loginFirst',
            isLoading: false,
            newPassword: '',
            newPasswordConfirm: '',
            showPassword: false,
            showConfirmPassword: false,
            newPasswordError: '',
            errorMessage: '',
            extraPasswordError: '',
            canSubmitPassword: false,
            rules: {
                required: value => !!value || 'Required.',
                min: v => v.length >= 8 || 'Min 8 characters'
            }
        }),
        components: {
          Loading
        },
        mounted() {
          // Get other data from Vue-x
          const loginData = this.$store.getters['profile/getAuthenticateBegin'];

          // Check if other required data still exists in Vue-x
          if (!loginData.username || !loginData.challengeName || !loginData.session) {
            // Route back to login if vue-x data is missing
            console.log('Needed data is missing! Routing back to login.');
            this.route('login', 'fade-in-transition');
            return;
          }
        },
        apollo: {
            $client: 'publicClient'
        },
        computed: {
          validPasswordResetCheck: function() {
            if (this.validPasswordReset && (this.newPassword === this.newPasswordConfirm)){
              return false
            }
            return true;
          }
        },
        methods: {
            async submitPassword() {
              // Get other data from Vue-x
              const loginData = this.$store.getters['profile/getAuthenticateBegin'];

              // Check if other required data still exists in Vue-x
              // console.log('login data NEW: ', loginData);
              if (!loginData.username || !loginData.challengeName || !loginData.session) {
                this.currentDialog = 'loginFirst';
                this.dialog = true;
                return;
              }

              // Check if form is valid
              if (this.validPasswordResetCheck) {
                console.log('validation failed');
                return;
              }

              // We are setting the accesstoken to null here because we don't want access token header to send for login
              localStorage.setItem("accessToken", null);


              this.isLoading = true;

              try {

                  const response = await this.$apollo.mutate({
                      mutation: LOGIN_CONTINUE,
                      variables: {
                        email: loginData.username,
                        challengeName: loginData.challengeName,
                        session: loginData.session,
                        new_password: this.newPassword
                      },
                      fetchPolicy: 'no-cache',
                      errorPolicy: 'all'
                  });
                  // console.log('Set password response: ', response);

                  this.isLoading = false;

                  if (response.data.authenticateContinue) {
                    if (response.data.authenticateContinue.ycmdAuthenticate.accessToken && response.data.authenticateContinue.ycmdAuthenticate.idToken) {

                      localStorage.setItem("accessToken", response.data.authenticateContinue.ycmdAuthenticate.accessToken);
                      localStorage.setItem("idToken", response.data.authenticateContinue.ycmdAuthenticate.idToken);

                      this.$store.commit('profile/setTokens', {
                        accessToken: response.data.authenticateContinue.ycmdAuthenticate.accessToken,
                        idToken: response.data.authenticateContinue.ycmdAuthenticate.idToken,
                        refreshToken: response.data.authenticateContinue.ycmdAuthenticate.refreshToken,
                        tokenType: response.data.authenticateContinue.ycmdAuthenticate.tokenType,
                        expiresIn: response.data.authenticateContinue.ycmdAuthenticate.expiresIn
                      });
                      // Route to our main app page here
                      // TODO
                      this.currentDialog = 'createSuccess';
                      this.dialog = true;
                      // this.route('ADMIN.verification', 'fade-in-transition');
                    }
                  } else if (response.errors && response.errors.length > 0) {
                    // If an error came back
                    if (response.errors[0].message) {
                      this.newPasswordError = response.errors[0].message;
                    } else {
                      this.newPasswordError = "Error 1E. Invalid response.";
                    }
                  } else {
                    // data did not come back
                    console.log('createPassword data did not come back: ', response);
                    this.errorMessage = 'Setting password was not successful. Please try again.';
                  }
              } catch (e) {
                  this.isLoading = false;
                  this.errorMessage = e.message;
                  console.log('CAUGHT: There was an error logging in loginContinue: ', e);
                  defaultErrorHandler(e);
              }

            },
            extraPasswordCheck() {
              // check if passwords match
              if (this.newPassword !== this.newPasswordConfirm) {
                this.extraPasswordError = 'Passwords do not match.';
                this.passwordsMatch = false;
              } else {
                this.extraPasswordError = '';
                this.passwordsMatch = true;
              }
            },
            passwordResetValidation() {
              if (this.$refs.passwordResetForm.validate() && !this.newPasswordError) {
                return true;
              }
              return false;
            },
            clearDialog() {
              this.dialog = false;
              this.validPasswordReset = false;
              this.currentDialog = 'loginFirst';
              this.isLoading = false;
              this.newPassword = '';
              this.newPasswordConfirm = '';
              this.showPassword = false;
              this.showConfirmPassword = false;
              this.newPasswordError = '';
              this.errorMessage = '';
              this.extraPasswordError = '';
              this.canSubmitPassword = false;
            },
            route(name, direction) {

                this.$router.push({
                    name: name, params: {
                        transition: direction
                    }
                }).catch(err => { console.log('router error: ', err) });
            }
        }
    }

</script>

<style scoped>



</style>
