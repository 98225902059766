import dataClient from '@/graphql/clients/axios';
import Vue from 'vue'
import {colors} from './colors'

// Initial state
const state = {
  //pods: [],
  groupId: null,
  groupList: null,
  groups: {},
  facilities: {},
  facilitiesLoaded: false,
  shiftsCache: {},
  shiftRulesCache: {},
  filtersCache: {}
};

const getters = {
  //getPods: state => state.pods,
  getGroupId: state => state.groupId,
  getfacilitiesLoaded: state => state.facilitiesLoaded,
  getGroupList: (state) => {
    return state.groupList
  },
  getGroupById: (state) => (id) => {
    return state.groups[id]
  },
  getFacilityById: (state) => (id) => {
    return state.facilities[id]
  },
  getShiftsByPodId: (state) => (podId) => {
    return state.shiftsCache[podId]
  },
  getFiltersByPodId: (state) => (podId) => {
    return state.filtersCache[podId]
  },  
  getShiftRulesByPodId: (state) => (shiftId) => {
    return state.shiftRulesCache[shiftId]
  },  
  getColors: (state) => {
    return colors
  },
};

const actions = {
  loadGroup: async ({commit, getters, dispatch, rootGetters}, id) => {

    let group = getters.getGroupById(id)

    if (!group) {

      let params = {
        operation: 'group_get',

        parameters: [
          {
            id: 'group_id',
            value: id
          }
        ]
      }
      
      let result = await dataClient('scheduleGet', params)

      let group = result.groups[0]
      group.users = result.users
      
      result.data.map(d => {
        if (d.id === 'user_indexes') {
          group.user_indexes = JSON.parse(d.value)
        }
      })

      /*
      group.facilities = result.facilities
      group.facilityMap = {}
      group.facilities.map(f => {
        group.facilityMap[f.id] = f
      })
      */
      group.userMap = {}      
      group.users.map(f => {
        let map = {}
        f.roles.map(r => {
          map[r] = true
        })
        f.roleMap = map
        group.userMap[f.id] = f
      })

      commit('setGroup', group)

    }

    return getters.getGroupById(id)
  },
  loadGroupList: async ({commit, getters, dispatch, rootGetters}, payload) => {

    let list = getters.getGroupList

    if (!list) {

      let params = {
        operation: 'group_get_list'
      }
      
      let result = await dataClient('scheduleGet', params)

      commit('setGroupList', result.groups)
    }
  },
  loadFacilities: async ({commit, getters, dispatch, rootGetters}, payload) => {

    if (!getters.getfacilitiesLoaded) {

      let params = {
        operation: 'facility_get'
      }
      
      let result = await dataClient('scheduleGet', params)

      let facilityMap = {}

      if (result && result.facilities && result.facilities.length) {
        result.facilities.map(f => {
          facilityMap[f.id] = f
        })  
      }

      commit('setFacilities', facilityMap)
    }
  },
  savePod: async ({commit, getters, dispatch}, pod) => {
    
    let parameters = []
    for (let prop in pod) {

      let val = pod[prop]
      if (val) {
        if (typeof(val) !== 'string') {
          val = JSON.stringify(val)
        }  
      }

      parameters.push({
        id: prop,
        value: val
      })
    }
    let params = {
      operation: 'pod_save',
      parameters
    }

    let result = await dataClient('scheduleOperation', params)
    console.log('result', result)

    if (result.error) {
      return result
    }

    if (result.pods[0]) {
      commit('updatePod', result.pods[0])
    }

    return result.pods &&  result.pods.length ? result.pods[0]: null 
  },

  saveAssignments: async ({commit, getters, dispatch, rootGetters}, payload) => {
    console.log('payload', payload)

    let parameters = []

    parameters.push({
      id: 'return_year',
      value: payload.return_year
    })

    parameters.push({
      id: 'return_month',
      value: payload.return_month
    })
    parameters.push({
      id: 'pod_id',
      value: payload.pod_id
    })

    payload.assignments.map(assignment => {

      parameters.push({
        name: assignment.operation,
        id: `${assignment.shift_id}|${assignment.date}`,
        value: assignment.provider_id
      })
    })

    let params = {
      operation: 'assignments_save',
      parameters
    }

    let result = await dataClient('scheduleOperation', params)
    console.log('result', result)
    
    return result
  },
  

  updateShifts: ({commit, getters, dispatch, rootGetters}, payload) => {

    let ret = {
      shifts: payload.shifts,
      data: {},
    }

    if (payload && payload.data && payload.data.length) {
      payload.data.map(d => {
        ret[d.id] = d.value
      })
    }

    if (payload.users && payload.users.length) {
      commit('updateGroupUsers', {users: payload.users, groupId: getters.getGroupId})
    }

    return ret
  
  },  

  
  loadFilters: async ({commit, getters, dispatch, rootGetters}, payload) => {

    //don't cache shifts since they are time dependent
    let filters = getters.getFiltersByPodId(payload.pod_id)

    if (!filters) {

      let params = {
        operation: 'get-filters-by-pod',
        parameters:[{
          id: 'pod_id',
          value: payload.pod_id
        }]
      }

      let groupId = getters.getGroupId

      let group = getters.getGroupById(groupId)
      
      let result = await dataClient('scheduleGet', params)

      console.log('filter result', result)

      let podMap = {}
      group.pods.map(p => {
        podMap[p.id] = true
      })
      
      let filters = []

      result.filters.map(f => {
        if (f.user_id === groupId) {
          f.source='group'
        } else if (podMap[f.user_id]) {
          f.source='subgroup'
        } else {
          f.source='provider'
        }
        filters.push(f)
      })

/*
      commit('setPodShifts', {
        pod_id:payload.pod_id,
        shifts: result.shifts 
      })
*/                    

      commit('updatePodFilters', {filters: filters, podId: payload.pod_id})

      return filters
    }

  },


  loadShifts: async ({commit, getters, dispatch, rootGetters}, payload) => {

    //don't cache shifts since they are time dependent
    let shifts //= getters.getShiftsByPodId(payload.pod_id)

    if (!shifts) {
      let paramArray = []
      for (let prop in payload) {
        paramArray.push({id: prop, value: payload[prop]})
      }

      let params = {
        operation: 'get-shifts-by-pod',
        parameters:paramArray
      }
      
      let result = await dataClient('scheduleGet', params)

/*
      commit('setPodShifts', {
        pod_id:payload.pod_id,
        shifts: result.shifts 
      })
*/        

      let ret = {
        shifts: result.shifts,
      }
      if (result && result.data && result.data.length) {
        result.data.map(d => {
          ret[d.id] = d.value
        })
      }

      if (result.users && result.users.length) {
        commit('updateGroupUsers', {users: result.users, groupId: getters.getGroupId})
      }

      return ret
    }

  },

  saveColors: async ({commit, getters, dispatch, rootGetters}, payload) => {

    //let colorIndexes = payload.colorIndexes
    let edits = payload.edits

    if (edits) {
      let paramArray = []
      
      paramArray.push({id: 'group_id', value: payload.groupId})
      paramArray.push({id: 'user_index_edits', value: JSON.stringify(edits)})

      let params = {
        operation: 'user-indexes-save',
        parameters:paramArray
      }   
  
      let result = await dataClient('scheduleOperation', params)
      console.log('result', result)

      let new_user_indexes
      result.data.map(d => {
        if (d.id === 'user_indexes') {
          new_user_indexes = JSON.parse(d.value)
        }
      })

      if (new_user_indexes) {
        commit('updateUserIndexes', {user_indexes: new_user_indexes, groupId: payload.groupId})

      }


    } 

    return edits

  },
  addUserToGroup: async ({commit, getters, dispatch, rootGetters}, payload) => {

    let paramArray = []
    for (let prop in payload) {
      paramArray.push({id: prop, value: payload[prop]})
    }

    let params = {
      operation: 'provider-add',
      parameters:paramArray
    }

    let result = await dataClient('scheduleOperation', params)
    console.log('result', result)

    let new_user_indexes
    result.data.map(d => {
      if (d.id === 'user_indexes') {
        new_user_indexes = JSON.parse(d.value)
      }
    })

    if (result.users && result.users.length) {

      commit('updateGroupUsers', {users: result.users, groupId: payload.group_id})
    }

    if (new_user_indexes) {

      commit('updateUserIndexes', {user_indexes: new_user_indexes, groupId: payload.group_id})
    }

    return result

  }      

};

const mutations = {
  /*
  setPods (state, data) {
    state.pods = data;
  },
  */
  setGroupList (state, list) {
    state.groupList = list
  },
  setGroupId (state, id) {
    state.groupId = id
  },  
  setGroup (state, group) {
    state.groups[group.id] = group
  },
  setFacilities (state, map) {
    state.facilities = map
    state.facilitiesLoaded = true
  },
  setPodShifts (state, params) {
    state.shiftsCache[params.pod_id] = params.shifts
  },  
  updatePod (state, pod) {

    let group = state.groups[pod.group_id]

    if (group) {
      let podIndex = group.pods.findIndex(p => {
        return p.id === pod.id
      })

      if (podIndex >= 0) {
        Vue.set(group.pods, podIndex, pod);
      } else {
        Vue.set(group.pods, group.pods.length, pod);
      }
    }
  },
  updateGroupUsers (state, params) {
    let group = state.groups[params.groupId]

    let map = group.userMap
    let arr = group.users

    let persist = false
    params.users.map(u => {
      if (!map[u.id]) {

        let rolemap = {}
        u.roles.map(r => {
          rolemap[r] = true
        })
        u.roleMap = rolemap
        
        arr.push(u)
        map[u.id] = u
        persist = true
      }
    })

    if (persist) {
      Vue.set(state.groups[params.groupId],'userMap',map)
      Vue.set(state.groups[params.groupId],'users',arr)
      Vue.set(state.groups,params.groupId,state.groups[params.groupId])
    }

  },
  updateUserIndexes  (state, params) {

    let newUsers = []

    let userMap = {}      
    state.groups[params.groupId].users.map(f => {
      let user_index = params.user_indexes[f.id]
      if (user_index >= 0) {
        Vue.set(f,'user_index',user_index)
      }

      let map = {}
      f.roles.map(r => {
        map[r] = true
      })
      f.roleMap = map
      userMap[f.id] = f
      newUsers.push(f)
    })

    Vue.set(state.groups[params.groupId],'user_indexes',params.user_indexes)
    Vue.set(state.groups[params.groupId],'users',newUsers)    
    Vue.set(state.groups[params.groupId],'userMap',userMap)
  },
  updatePodFilters (state, params) {
    state.filtersCache[params.podId] = params.filters

  },
  resetState(state) {
    state.index = 0
    state.groupId = null
    state.groupList = null
    state.groups = {}
    state.facilities = {}
    state.facilitiesLoaded = false
    state.shiftsCache = {}
    state.shiftRulesCache = {}
    state.filtersCache = {}
  },  
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
