import jwt_decode from "jwt-decode";
//import axios from "axios";
import store from '@/store'
import dataClient from '@/graphql/clients/axios';

import REFRESH_TOKEN from '@/graphql/mutations/phoenix/ycmdAuthenticateTokenRefresh';

const { definitions, loc } = REFRESH_TOKEN;
const { name, operation } = definitions[0];
const { body } = loc.source;

const checkAccessToken = (accessToken) => {

    let decoded = jwt_decode(accessToken);
    // console.log('decoded: ', decoded);
    const currentTime = Math.floor(new Date().getTime() / 1000);
    // console.log('difference check: ', decoded.exp - decoded.iat)
    let timeLeft = decoded && decoded.exp ? decoded.exp - currentTime : 0;
    // check edge case after login where exp does not exist (maybe backend sends back token data differently there?) TODO

    return { decoded, timeLeft }
};

async function refreshHandler() {
    // Check expiration of auth token

    let refreshToken = localStorage.getItem("refreshTokenPCP");

    if (!refreshToken) {
        refreshToken = localStorage.getItem("refreshToken");
    }

    const username = localStorage.getItem("username");
    const userID = localStorage.getItem("userID");
    const accessToken = localStorage.getItem("accessToken");

    // console.log('username: ', username);
    // console.log('userID: ', userID);
    // console.log('refresh token in refreshHandler: ', refreshToken);
    // console.log('access token: ', accessToken);
    const { decoded, timeLeft } = checkAccessToken(accessToken);

    if (decoded) {
        localStorage.setItem("userID", decoded.user_id);
    }

    console.log('time left access: ', timeLeft);
    // if (timeLeft < 30)
 
    if (timeLeft < 30) {

        if (refreshToken && (username || userID)) {

            try {

                let ycmdAuthenticateTokenRefresh = await dataClient('ycmdAuthenticateTokenRefresh',{
                    username: username || null,
                    user_id: userID || null,
                    refresh_token: refreshToken
                })

                console.log('refresh response: ', ycmdAuthenticateTokenRefresh);
                if ( 
                    ycmdAuthenticateTokenRefresh && 
                    ycmdAuthenticateTokenRefresh.authentication_status &&
                    ycmdAuthenticateTokenRefresh.authentication_status.prompt_for_twofactor  ){
                    store.commit('app/setAuthenticationStatus', ycmdAuthenticateTokenRefresh.authentication_status)
                    store.commit('app/setAuthenticationStatusOpen', true)
                }
                if (ycmdAuthenticateTokenRefresh && ycmdAuthenticateTokenRefresh.cognito) {
                    if (ycmdAuthenticateTokenRefresh.cognito.accessToken) {
                        localStorage.setItem("accessTokenPCP", ycmdAuthenticateTokenRefresh.cognito.accessToken);
                    }
                    if (ycmdAuthenticateTokenRefresh.cognito.idToken) {
                        localStorage.setItem("idTokenPCP", ycmdAuthenticateTokenRefresh.cognito.idToken);
                    }
                    
                }
                if (ycmdAuthenticateTokenRefresh && ycmdAuthenticateTokenRefresh.token) {
                    console.log('INFO: Refreshed the token successfully!');
                    localStorage.setItem("accessToken", ycmdAuthenticateTokenRefresh.token);

                    return true;
                } else {
                    if (!ycmdAuthenticateTokenRefresh) {
                        // no internet connection
                        console.log("WARNING: Refresh token response is null, probably do not have connection");
                        return true; // returning true so it does not log you out
                    }

                    console.log("WARNING: Access token couldn't be retrieved from the refresh token");
                    console.log('response for refresh failure: ', response, 'timeleft: ', timeLeft);
                    return false;
                }

            }
            catch (e) {
                console.log('ERROR: There was an error refreshing token: ', e);
            }

            return false;
        }
        else {

            if (!refreshToken)
            console.log("WARNING: No refresh token available");

            if (!username || userID)
            console.log("WARNING: No user info available");
        }

    }
    else {
        // Token is still valid
        return true;
    }
}

export { refreshHandler }
