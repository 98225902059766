<template>

    <div class="list-container">

        <v-container fluid
                     v-for="(item, i) in items"
                     :key="`user_${i}`"
                     :class="`list-item flex-shrink-0 pa-3 pt-2 pb-2 relative`">

            <v-row no-gutters>
                <v-col cols="6" sm="4" md="2" lg="2">
                    <div :class="`list-item__column ${ $vuetify.breakpoint.smAndDown ? 'pb-4' : '' }`">
                        <div class="hidden-md-and-up label-title">PCP</div>
                        <div class="label-value fw-500">
                            {{ item.pcp_given_name || 'N/A' }}
                            {{ item.pcp_family_name || 'N/A' }}
                            {{ item.pcp_suffix }}
                        </div>
                    </div>
                </v-col>
                <v-col cols="4" sm="4" md="2" lg="2">
                    <div :class="`list-item__column ${ $vuetify.breakpoint.smAndDown ? 'pb-4' : '' }`">
                        <div class="hidden-md-and-up label-title">Message</div>
                        <div class="label-value">
                            {{parsePcpStatus[item.message_trigger_event] || 'N/A'}}
                        </div>
                    </div>
                </v-col>
                <v-col cols="6" sm="4" md="3" lg="2">
                    <div :class="`list-item__column ${ $vuetify.breakpoint.smAndDown ? 'pb-4' : '' }`">
                        <div class="hidden-md-and-up label-title">Occurred</div>
                        <div class="label-value fs-10">{{ format(parseISO(item.event_recorded_time), 'MM-dd-yyyy h:mm a') }} PST</div>
                    </div>
                </v-col>
                <v-col cols="6" sm="4" md="2" lg="2">
                    <div :class="`list-item__column ${ $vuetify.breakpoint.smAndDown ? 'pb-4' : '' }`">
                        <div class="hidden-md-and-up label-title">Patient</div>
                        <div class="label-value fw-500">
                            {{ item.patient_given_name || 'N/A' }}
                            {{ item.patient_family_name || 'N/A' }}
                        </div>
                    </div>
                </v-col>
                <v-col cols="12" sm="4" md="2" lg="3">
                    <div :class="`list-item__column ${ $vuetify.breakpoint.smAndDown ? 'pb-4' : '' }`">
                        <div :class="`${ $vuetify.breakpoint.smAndDown ? 'd-flex flex-column flex-grow-1' : '' }`">
                            <div class="hidden-md-and-up label-title">Reason</div>
                            <div class="label-value fs-10">{{ item.visit_admit_reason || 'N/A' }}</div>
                        </div>
                        <div class="hidden-sm-and-up">
                            <v-tooltip left
                                       :disabled="$vuetify.breakpoint.xsOnly"
                                       color="ycmd-black"
                                       content-class="fade-0 pa-4 pt-2 pb-2">
                                <template v-slot:activator="{ on }">
                                    <v-btn icon dark text small
                                           @click="changeView({ view: 'details-view', params: { data: item } });"
                                           v-on="on" color="white" class="ycmd-blue mr-1">
                                        <v-icon :size="14">arrow_forward</v-icon>
                                    </v-btn>
                                </template>
                                <span>Show Details</span>
                            </v-tooltip>
                        </div>
                    </div>
                </v-col>
            </v-row>

            <div class="hidden-xs-only">
                <div class="d-flex flex-shrink-0 fill-height align-center justify-center">
                    <v-tooltip left
                               :disabled="$vuetify.breakpoint.xsOnly"
                               color="ycmd-black"
                               content-class="fade-0 pa-4 pt-2 pb-2">
                        <template v-slot:activator="{ on }">
                            <v-btn icon dark text small
                                   @click="changeView({ view: 'details-view', params: { data: item } });"
                                   v-on="on" color="white" class="ycmd-blue mr-1">
                                <v-icon :size="14">arrow_forward</v-icon>
                            </v-btn>
                        </template>
                        <span>Show Details</span>
                    </v-tooltip>
                </div>
            </div>

        </v-container>

        <template v-if="!items.length && !isLoading">

            <div class="d-flex align-start justify-center full-height fs-18 white--text mt-4">No Results</div>

        </template>

        <infinite-loader :infiniteHandler="infiniteHandler"
                        :infiniteId="infiniteId"
                        :refreshHandler="refreshHandler"/>

    </div>

</template>

<script>

    import { format, parseISO } from 'date-fns';
    import Loading from 'vue-loading-overlay';
    import InfiniteLoading from 'vue-infinite-loading';
    import {defaultErrorHandler} from '@/graphql/handler/errorHandler';
    import { parsePcpStatus } from '@/methods/globalMethods';

    import SubHeaderContent from '@/components/header/sub-header-content';
    import Counter from '@/components/header/items-counter';
    import ListContainer from '@/components/content/list-container';
    import CommunicationHeader from "../components/communication-header";
    import InputSearch from "@/components/input-search";
    import InfiniteLoader from '@/components/loader/infinite-loader';

    import GET_MESSAGES from '@/graphql/queries/pcpMessagesBrowse';
    import GET_FACILITIES from '@/graphql/queries/pcpGetFacilities';
    import CONFIRM_MESSAGES from '@/graphql/mutations/pcpMessageConfirm';
    import {mapActions, mapState} from "vuex";

    import { pcpUserMessageTypes } from '@/data/pcpUser/constants.js';

    export default {
        name: 'notifications',
        components: {
            'communication-header': CommunicationHeader,
            'sub-header-content': SubHeaderContent,
            'counter': Counter,
            'list-items-container': ListContainer,
            'input-search': InputSearch,
            'infinite-loader': InfiniteLoader,
            Loading,
            InfiniteLoading
        },
        props: {
            changeView: Function,
            infiniteHandler: Function,
            infiniteId: Number,
            refreshHandler: Function,
            items: Array,
            totalItems: Number,
            isLoading: Boolean
        },
        apollo: {
            $client: 'phoenixClient'
        },
        data() {
            return {
                format,
                parseISO,
                card: {
                    index: 0,
                    panels: { left: false, right: false, top: false, bottom: false }
                },
                parsePcpStatus: parsePcpStatus,
                rules: {
                    required: value => !!value || 'Required.',
                    min: v => v.length >= 8 || 'Min 8 characters'
                },
                pcpUserMessageTypes: pcpUserMessageTypes,
                pcpUserMessageTypeSelected: pcpUserMessageTypes[0],

            }
        },
        created() {

        },
        mounted() {
          // console.log(this.pcpUserMessageTypes);
        },
        activated() {
          // this.$nextTick(() => this.$refs.searchBarRef.focus());
        },
        watch: {
          pcpUserMessageTypeSelected(val) {
            // do new search here
            this.refreshData()
          }
        },
        methods: {
            ...mapActions('menu', ['openMenu','closeMenu','toggleMenu']),
            slide(direction) {
                this.card.panels[direction] = !this.card.panels[direction];
            },
            onSearchClick(e, value) {
                if (value === '') value = null;
                this.searchValue = value;
                this.refreshData();
            },
            async getFacilities(val) {
              this.isLoadingSearch = true;
              try {
                const response = await this.$apollo.query({
                    query: GET_FACILITIES,
                    variables: {
                        name: val,
                        limit: 10
                    },
                    fetchPolicy: 'no-cache',
                    errorPolicy: 'all'
                });
                this.isLoadingSearch = false;
                if (response.data && response.data.pcpGetFacilities) {
                  this.facilitiesSearched = response.data.pcpGetFacilities;
                  return response.data.pcpGetFacilities;
                }
              } catch(e) {
                defaultErrorHandler(e);
              }
            },
            confirmMessagesUI(visitID) {
              // if (!visitID) return;
              // for (let message in this.items) {
              //   const currentMessage = this.items[message];
              //   // check if visitID matches
              //   if (currentitem.visit_id === visitID) {
              //     currentitem.confirmed_time = Date.now();
              //   }
              // }
            },
            async confirmMessagePCP(visitID) {
              // TODO: TEMPORARY
              // taking this out as requested for now
              return;
              //
              if (!visitID) {
                this.$toasted.error(`There was an error confirming message - missing visit ID`);
                return;
              }
              this.confirmMessagesUI(visitID);
              try {
                const response = await this.$apollo.mutate({
                  mutation: CONFIRM_MESSAGES,
                  variables: { visit_id: visitID },
                  fetchPolicy: 'no-cache',
                  errorPolicy: "all"
                })
                // console.log('MESSAGE: Confirm message response: ', response);
                if (response.data && response.data.pcpMessageConfirm) {
                    // check for success
                    if (response.data.pcpMessageConfirm.status === 'success') {
                        this.$toasted.success(`Successfully confirmed item.`);
                    }
                } else if (response.errors && response.errors.length > 0) {
                    if (response.errors[0].message) {
                        this.$toasted.error(`There was a problem confirming messsage - ${response.errors[0].message}`);
                    } else {
                        this.$toasted.error(`There was an error confirming item.`);
                    }
                } else {
                    // data did not come back
                    console.log('updateStatusError data did not come back: ', response);
                    this.$toasted.error(`There was an error.`);
                }
              } catch(e) {
                defaultErrorHandler(e);
              }
            },
            route(name, transition) {

                this.$router.push({
                    name: name, params: {
                        transition: transition
                    }
                }).catch(err => { console.log('router error: ', err) });
            }
        },
        computed: {
            ...mapState({
                isMenuOpen: state => state.menu.open
            })
        }
    }

</script>

<style scoped>

    .message--confirmed {

    }

    .message--unconfirmed {
      background-color: rgba(11, 160, 236, 0.5);
    }

    @media (max-width: 600px) {
        .list-container {
            font-size: 10px;
            padding: 0 0 8px 0;
        }
    }

    /* Small Breakpoint */
    @media (min-width: 600px) {

    }

    /* Medium Breakpoint */
    @media (min-width: 960px) {
        .list-item__column {
            display: flex;
            align-items: center;
            height: 100%;
        }
        .label-value {
            padding-bottom: 0;
        }
    }

    /* Large Breakpoint */
    @media (min-width: 1264px) {

    }

    /* XLarge Breakpoint */
    @media (min-width: 1904px) {

    }

</style>
