import gql from 'graphql-tag'

export default gql`query ycmdUserSearch($search: String, $limit: Int, $roles: [YcmdRole], $facility_ids: [ID], $user_id: ID, $include_searching_user: Boolean, $exclude_group_filter: Boolean, $group_ids: [ID]) {
	ycmdUserSearch(search: $search, limit: $limit, roles: $roles, facility_ids: $facility_ids, user_id: $user_id, include_searching_user: $include_searching_user, exclude_group_filter: $exclude_group_filter, group_ids: $group_ids) {
		group_name
		group_id
		users {
			id
			first_name
			last_name
			group_id
		}
	}
}`

/*
export default gql`query ycmdUserSearch($search: String, $limit: Int, $roles: [YcmdRole], $facility_ids: [ID], $user_id: ID, $include_searching_user: Boolean, $exclude_group_filter: Boolean, $group_ids: [ID],$exclude_user_ids: [ID]) {
	ycmdUserSearch(search: $search, limit: $limit, roles: $roles, facility_ids: $facility_ids, user_id: $user_id, include_searching_user: $include_searching_user, exclude_group_filter: $exclude_group_filter, group_ids: $group_ids, exclude_user_ids: $exclude_user_ids) {
		group_name
		group_id
		users {
			id
			first_name
			last_name
			group_id
		}
	}
}`
*/