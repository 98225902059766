<template>

    <div class="view">

        <i-card :viewIndex="card.index"
                :viewCount="1"
                :leftPanel="card.panels.left"
                :rightPanel="card.panels.right"
                :topPanel="card.panels.top"
                :bottomPanel="card.panels.bottom"
                class="i-card">

            <div class="i-card__main">

                <div class="i-card__main__content br-2 pb-2 pr-2"
                >
                    <v-container
                        class="pa-0 ma-0 full-width"
                        style="width: 100%;max-width: 100%"
                    >
                        <v-row
                            class="pa-0 ma-0"
                        >
                            <v-col
                                class="pa-0 ma-0 shrink"                                
                            >

                                <v-autocomplete
                                        solo light dense flat outlined
                                        class="pr-3"
                                        v-model="selectedGroup"
                                        :disabled="!getGroupList || !getGroupList.length"
                                        :items="getGroupList"
                                        label="Select Group"
                                        item-value="id"
                                        item-text="name"
                                        style="max-width: 300px;min-width: 200px;"  
                                        hide-details        
                                        :loading="loadingGroupList"      
                                        loader-height="4"                                                                            
                                ></v-autocomplete>
                            </v-col>
                            <v-col
                                class="pa-0 ma-0 shrink"                                
                            >

                                <v-select
                                    light
                                    flat
                                    outlined
                                    solo
                                    class="align-center"
                                    :useIcon="false"
                                    label="Select Pod/Subgroup"
                                    :items="pods"
                                    v-model="selectedPod"
                                    item-value="id"
                                    item-text="name"                                    
                                    dense
                                    style="max-width: 300px;min-width: 234px;"   
                                    hide-details  
                                    :loading="loadingGroup"      
                                    loader-height="4"          
                                    :disabled="loadingGroup"               
                                >

                                    <template
                                        v-slot:append-outer
                                    >

                                        <v-tooltip 
                                                bottom
                                                nudge-bottom="0"
                                                nudge-right="32"
                                                color="ycmd-black"
                                                :disabled="$vuetify.breakpoint.xsOnly"
                                                content-class="fade-0">
                                            <template v-slot:activator="{ on }">
                                                <v-btn icon
                                                    @click="addPod"
                                                        class="pa-0"
                                                        x-small
                                                    v-on="on">
                                                        <v-icon
                                                            color="white"
                                                            class="pa-0"
                                                        >
                                                            add
                                                        </v-icon>

                                                </v-btn>
                                            </template>
                                            <span>Add Pod</span>
                                        </v-tooltip>
                                        <v-tooltip 
                                            v-if="selectedPod"            
                                                    bottom
                                                nudge-bottom="0"
                                                nudge-right="32"
                                                color="ycmd-black"
                                                :disabled="$vuetify.breakpoint.xsOnly"
                                                content-class="fade-0">
                                            <template v-slot:activator="{ on }">
                                                <v-btn icon
                                                    @click="editPod"
                                                        class="pa-0"
                                                        x-small
                                                    v-on="on">
                                                        <v-icon
                                                            color="white"
                                                            class="pa-0"
                                                        >
                                                            edit
                                                        </v-icon>

                                                </v-btn>
                                            </template>
                                            <span>Edit Pod</span>
                                        </v-tooltip>


                                    </template>                                    
                                </v-select>

                            </v-col>
                            <v-col
                                class="pa-0 ma-0 shrink"                                
                            >

                                <v-btn
                                    text
                                    class="ml-5"
                                    @click="shiftEdit=true"
                                >
                                <span
                                    class="text-none text-decoration-underline ycmd-very-lightest-gray--text "
                                >
                                {{shiftEditCaption}}
                                </span>
                                    <v-icon
                                        class="ml-2 ycmd-very-lightest-gray--text"
                                    >
                                        schedule
                                    </v-icon>
                                </v-btn>                            
                            </v-col>
                            <v-col
                                class="pa-0 ma-0 Grow "                                
                            >
                                &nbsp;
                            </v-col>                              
                            <v-col
                                class="pa-0 ma-0 shrink"                                
                            >
                                <v-btn
                                    @click="saveSchedule"
                                    class="ycmd-green"                                    
                                    v-if="isDirty"
                                    :loading="saving"
                                >
                                    Save
                                </v-btn>                            
                            </v-col>                            
                        </v-row>

                        <v-row
                            class="pa-0 ma-0"
                        >
                            <v-col
                                class="pa-0 ma-0"
                                 cols="12"                                
                            >

                            <v-toolbar
                                dense
                                flat
                                color="ycmd-very-lightest-blue"
                                id="emr_toolbar"
                            >

          <v-progress-circular
            indeterminate
            :size="20"
            :width="1"
            color="primary"
            :style="{visibility: loadingCalendar ? 'visible': 'hidden'}"
          >
          </v-progress-circular>
          <v-btn
            text
            class="text-none"
            color="ycmd-very-lightest-blue"
            style="border: none"
            width="100px"
            @click="toggleCalendarType"
          >
            {{tbCalendarTypeDescription}}
          </v-btn>

            <v-btn
              color="ycmd-very-lightest-blue"
              style="border: none"
              text
              @click="prevDate"
            >
              <v-icon>chevron_left</v-icon>
            </v-btn>


            <v-menu
              bottom
              left
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  text
                  class="text-none"
                  color="ycmd-very-lightest-blue"
                  style="border: none"
                  width="100px"
                >
                  {{tbCalendarRangeDescription}}
                </v-btn>
              </template>

              <v-date-picker
                v-model="calendarDate"
                no-title
                scrollable
              >
      
              </v-date-picker>
            </v-menu>


            <v-btn
              color="ycmd-very-lightest-blue"
              style="border: none"
              text
              @click="nextDate"
            >
              <v-icon>chevron_right</v-icon>
            </v-btn>

            <v-spacer />

            <schedule-provider
                :providers="providers"
                :showFilter="true"
                :showGroupProviders="true"
                :showOutOfGroupProviders="true"
                v-model="tbSelectedProvider"
                backgroundColor="ycmd-very-lightest-blue"     
                :group_id="selectedGroup"
                :pod_id="selectedPod"
                :outlined="false"             
                style="width: 300px;"
                :provider_id_include="podProviderIdInclude"
            >
            </schedule-provider>
            
            <v-tooltip 
                    bottom
                    nudge-bottom="0"
                    nudge-right="32"
                    color="ycmd-black"
                    :disabled="$vuetify.breakpoint.xsOnly"
                    content-class="fade-0">
                <template v-slot:activator="{ on }">

                    <v-btn icon
                        @click.stop="onProviderEdit"
                        :disabled="!selectedGroup"
                        size="24"
                        v-on="on"
                    >
                        <v-icon
                            size="16"
                            class="ycmd-light-blue--text"
                        >
                            person_add
                        </v-icon>
                    </v-btn>
                </template>
                <span>Add/Edit Providers</span>
            </v-tooltip>

            <v-select
                light
                flat
                solo
                :useIcon="false"
                label="Shift Filter"
                :items="tbShiftsFilterArray"
                multiple
                v-model="tbSelectedShifts"
                item-value="id"
                item-text="name"                                    
                dense
                class="mt-n3"
                style="max-width: 300px;max-height: 25px;"                  
                background-color="ycmd-very-lightest-blue"     
                :outlined="false"    
                clearable         
            >
            </v-select>

        </v-toolbar>


                            </v-col>
                        </v-row>


                    </v-container>

                <div class="i-card__scrollable">

                                <schedule-calendar 
                                    v-model="calendarDate"
                                    :events="eventsArray"
                                    :shifts="shifts"
                                    :assignmentMap="combinedAssignmentMap"
                                    :refreshCalendar="refreshCalendar"
                                    :loading="loadingCalendar"
                                    :providers="providers"
                                    :group_id="getGroupId"
                                    @getEvents="checkRange"
                                    @createAppointment="createAppointment"
                                    @editAppointment="editAppointment"
                                    @shiftClick="onShiftClick"
                                    @shiftDayEdit="onShiftDayEdit"
                                    @onDateClick="onDateClick"
                                    :calendarType.sync="tbCalendarType"
                                    :tbCurrentRange.sync="tbCurrentRange"
                                    :tbSelectedShifts.sync="tbSelectedShifts"
                                    :tbSelectedProvider.sync="tbSelectedProvider"
                                    :tbCalendarType.sync="tbCalendarType"
                                    :showToolbar="false"
                                />




                </div>
                </div>

            </div>

            <template slot="right-panel">

                <div>Hello right panel</div>

            </template>

            <template slot="bottom-panel">

                <div>Hello Bottom Panel</div>

            </template>

        </i-card>

        <pod 
            v-model="openPod"
            @cancel="podCancel"
            @ok="podSave"
            :groupId="selectedGroup"
            :podId="podId"
        />

        <confirmation
            v-model="confirmScheduleChange"
            title="Schedule Change"
            subtitle=""
            :text="confirmationText"
            @click="confirmationClicked"
            :buttons="confirmationButtons"
            max-width="500"
        />     

        <shift-edit
            v-model="shiftEdit"
            :title="shiftEditCaption"
            subtitle=""
            :shifts="shifts"
            :filters="filters"
            :pod="selectedPodObject"
            :facilities="groupFacilities"
            :group_id="group?group.id:''"
            max-width="1200px"
        />     

        <pod-providers
            v-model="podProvidersEdit"
            max-width="800px"
            :selectedId="selectedPod"
            @cancel="providersCancel"
            @ok="providersSave"
            :pods="pods"
            :groupId="selectedGroup"
        />

    </div>

</template>


<script>
    import Pod from '@/views/apps/scheduling-new/components/pod.vue'
    import PodProviders from '@/views/apps/scheduling-new/components/podProviders.vue'
    import ShiftEdit from '@/views/apps/scheduling-new/components/scheduleShiftEdit.vue'    
    import scheduleCalendar from '@/views/apps/scheduling-new/components/scheduleCalendar';
    import Confirmation from '@/components/modals/confirmation.vue'
    import {mapActions, mapGetters} from "vuex";
    import scheduleProvider from '@/views/apps/scheduling-new/components/scheduleProvider';
    import { addDays, addMonths, addMinutes, min, differenceInMinutes } from 'date-fns'
    import {formatSimple} from '@/methods/formatters';

    export default {
        components: {
            pod: Pod,
            'schedule-calendar': scheduleCalendar,
            confirmation: Confirmation,
            'shift-edit': ShiftEdit,
            'schedule-provider': scheduleProvider,
            'pod-providers': PodProviders
        },
        data() {
            return {
                card: {
                    index: 0,
                    panels: { left: false, right: false, top: false, bottom: false },
                },
                group: {},
                selectedPod: null,
                openPod: false,
                podId: null,
                refreshCalendar: '',
                loadingCalendar: false,
                loadingGroup: false,
                loadingGroupList: true,
                providerIds: [],
                shifts: [],
                filters: [],
                sortBy: 'name',
                calendarStart: '',
                calendarEnd: '',
                rangeStart: '',
                rangeEnd: '',
                assignmentEdits: {},
                currentToggle: {},
                confirmScheduleChange: false,
                confirmationButtons: [
                    {caption: 'OK'},
                    {caption: 'Cancel'}
                ],
                confirmationText: '',
                calendarDate: null,
                shiftEdit: false,
                tbCalendarType: 'month',
                tbCalendarTypes: [
                    {name: 'Month', value: 'month'},
                    {name: 'Day', value: 'day'},            
                ],
                months: ['Jan','Feb','Mar','Apr','May','June','July','Aug','Sept','Oct','Nov','Dec'],
                tbCurrentRange: null,
                tbSelectedShifts: null,
                tbSelectedProvider: null,
                saving: false,
                tbReturnRange: null,
                podProvidersEdit: '',
                groupFacilities: []
            }
        },
        created() {},
        activated() {},
        watch: {
            async getGroupId (newValue, oldValue) {
                if (newValue) {
                    this.loadingGroup = true
                    this.selectedPod = null
                    this.group = await this.loadGroup(newValue)
                    this.groupFacilities = this.group.facilities
                    this.loadingGroup = false
                } else {
                    this.group = {}
                }
            },
            selectedPod (newValue, oldValue) {
                this.rangeStart = ''
                this.rangeEnd = ''

                this.checkshifts()

            },

        },
        methods: {
            ...mapActions('menuRight', ['openMenuRight','closeMenuRight','toggleMenuRight']),
            ...mapActions('schedule',['loadGroupList','loadGroup','loadFacilities','savePod','loadShifts','updateShifts','saveAssignments','loadFilters']),            
            onProviderEdit () {
                this.podProvidersEdit = `${this.selectedPod}|${(new Date()).toISOString()}`
            },
            providersSave (params) {

            },
            providersCancel () {
                this.podProvidersEdit = ''
            },
            nextDate () {

                let newDate
                if (this.tbCalendarType === 'day') {
                    let dt = new Date(parseInt(this.year+''),parseInt((this.month -1)+''),this.day )
                    newDate = addDays(dt,1)
                } else {
                    let dt = new Date(parseInt(this.year+''),parseInt((this.month -1)+''),1 )                    
                    newDate = addMonths(dt,1)
                }

                this.calendarDate = formatSimple(newDate,'yyyy-MM-dd')

            },
            prevDate () {

                let newDate
                if (this.tbCalendarType === 'day') {
                    let dt = new Date(parseInt(this.year+''),parseInt((this.month -1)+''),this.day )
                    newDate = addDays(dt,-1)
                } else {
                    let dt = new Date(parseInt(this.year+''),parseInt((this.month -1)+''),1 )                    
                    newDate = addMonths(dt,-1)
                }

                this.calendarDate = formatSimple(newDate,'yyyy-MM-dd')
            },

            tbSortShifts (shifts) {
              
              if (shifts && shifts.length) {
                let sortType = this.$store.getters['profile/getPreference']('schedule_sort_type', 'time')

                let ret
                if (sortType === 'name') {
                  ret = shifts.sort((a,b) => {
                    let asortable = `${a.personal}_${a.last_name}${a.first_name}`
                    let bsortable = `${b.personal}_${b.last_name}${b.first_name}` 

                    return asortable.localeCompare(bsortable);
                  })
                } else {

                  ret = shifts.sort((a,b) => {
                    let asortable = `${a.personal}_${a.start}_${a.name}_${a.end}`
                    let bsortable = `${b.personal}_${b.start}_${b.name}_${b.end}` 

                    return asortable.localeCompare(bsortable);
                  })

                }
                return ret
              }
              return []
            },   

            toggleCalendarType () {
                this.calendarDate = this.tbReturnRange.start.date
                this.tbCalendarType = 'month'
            },
            async saveSchedule() {
                let operations = this.operations

                if (operations && operations.length) {
                    this.saving = true
                    let pieces = this.calendarDate.split('-')
                    let result = await this.saveAssignments({
                        assignments: operations, 
                        pod_id: this.selectedPod,
                        
                        return_year: pieces[0],
                        return_month: pieces[1]
                    })

                    if (result && result.error) {
                        let message = result.error.message
                        if (!message) {
                            message = 'An error has occurred'
                        }
                        this.$toasted.error(message)
                    } else {
                        this.loadingCalendar = true
                        let response = await this.updateShifts(result)
                        this.assignmentEdits = {}
                    
                        this.shifts = response.shifts
                        this.rangeStart = response.begin
                        this.rangeEnd = response.end


                        let message
                        if (response.additions || response.deletions) {
                            message = `Schedule updated.  ${response.additions} shifts added.  ${response.deletions} shifts removed`
                        }
                        if (message) {
                            this.$toasted.success(message)
                        }
                        this.loadingCalendar = false
                    }
                    this.saving = false
                }
            },
            onShiftDayEdit(params) {
                this.setAssignment(params.shift.id, params.date, params.providers)
            },
            onShiftClick(params) {

                let result = this.toggleAssignment(params.shift.id, params.date, params.provider)
   
            },
            onDateClick (params) {
                if (this.tbCalendarType === 'month') {
                    this.tbReturnRange = this.tbCurrentRange
                }
                this.tbCalendarType='day'
            },
            confirmationClicked (btn) {
                

                if (btn.caption === 'OK') {
                    

                    this.doToggleAssignment(
                        this.currentToggle.shiftId, 
                        this.currentToggle.date, 
                        this.currentToggle.provider, 
                        this.currentToggle.assignmentId, 
                        this.currentToggle.currentState, 
                        this.currentToggle.multiple)
                }

                this.confirmScheduleChange = false
            },
            setAssignment (shiftId, date, providers) {
                
                let shift = this.shiftMap[shiftId]
                
                let multiple = shift.rotation || shift.rounding 

                if (multiple) {
                    let original
                    let current
                    //console.log('this.combinedAssignmentMap', this.combinedAssignmentMap)
                    let shiftAssignments = this.combinedAssignmentMap[shiftId]

                    let shiftDateAssignments = {}
                    if (shiftAssignments) {
                        shiftDateAssignments = shiftAssignments[date]
                    }

                    //ones to add
                    let providerMap = {}
                    providers.map(p => {

                        providerMap[p] = true
                        let assignment = shiftDateAssignments[p]
                        let add = false
                        if (!assignment) {
                            add = true
                        }
                        if (!add) {
                            if (!assignment.state && typeof(assignment.state) !== 'undefined') {
                                add = true
                            }
                        }
                        if (add) {
                            let obj = assignment
                            if (!obj) {
                                obj = {

                                }
                            }
                            obj.state = true

                            this.writeEdit(shiftId,date,p,obj)
                        }
                    })
                    let shiftAssignments2 = this.combinedAssignmentMap[shiftId]
                    if (shiftAssignments2) {
                        //now look for extras to delete
                        let shiftDateAssignments2 = shiftAssignments2[date]

                        if (shiftDateAssignments2) {
                            for (let providerId in shiftDateAssignments2) {
                                if (!providerMap[providerId]) {
                                    let obj = shiftDateAssignments2[providerId]
                                    obj.state = false

                                    this.writeEdit(shiftId,date,providerId,obj)
                                }                                
                            }
                        }
                    }


                }


            },
            toggleAssignment (shiftId, date, provider) {
                let shift = this.shiftMap[shiftId]
                
                let multiple = shift.rotation || shift.rounding 

                let original
                let current
                let shiftAssignments = this.shiftAssignmentMap[shiftId]
                if (shiftAssignments) {
                    let shiftDateAssignments = shiftAssignments[date]

                    if (shiftDateAssignments) {
                        if (multiple) { //if multiple then we need exact provider match
                            original = shiftDateAssignments[provider]                                
                        } else {
                            for (let id in shiftDateAssignments) {
                                original = shiftDateAssignments[id]
                            }
                        }
                    }
                }

                let shiftEdit = this.assignmentEdits[shiftId]
                
                if (shiftEdit) {
                    let editDate = shiftEdit[date]

                    if (editDate) {
                        if (multiple) {
                            current = editDate[provider]
                        } else {
                            for (let pid in editDate) {
                                let edit = editDate[pid]
                                if (edit.state) {
                                    current = edit
                                }
                            }
                        }
                    }
                }

                let currentState

                if (multiple) {
                    if (current) {
                        currentState = current.state
                    } else {
                        currentState = original && original.user_id===provider ? true : false
                    }
                } else {

                    if (current) {
                        currentState = current && current.user_id===provider ? true : false
                    } else {
                        currentState = original && original.user_id===provider ? true : false
                    }

                    /*
                    if (current && current.user_id === provider) {
                        currentState = current.state
                    } else {
                        currentState = original && original.user_id===provider ? true : false
                    }
                    */
                }

                //let currentState = original && original.user_id===provider ? true : false
/*
                if (current) {
                    currentState = current.state

                }
*/
                currentState = !currentState

                let assignmentId = current ? current.id : -1

                if (multiple) {
                    this.doToggleAssignment(shiftId, date, provider, assignmentId, currentState, multiple)
                } else {
                    if (original) {
                        
                        this.currentToggle = {
                            shiftId, 
                            date, 
                            provider, 
                            assignmentId, 
                            currentState, 
                            multiple
                        }

                        let newProvider = this.providerMap[provider]

                        let oldProvider = this.providerMap[current ? current.user_id : original.user_id]

                        let text

                        if (!currentState) {
                            text = `Are you sure you want to remove ${oldProvider.first_name} ${oldProvider.last_name} from this shift`

                        } else {
                            text = `Are you sure you want to replace this shift with ${newProvider.first_name} ${newProvider.last_name}`
                            
                            if (oldProvider) {
                                text = `${oldProvider.first_name} ${oldProvider.last_name} is currently oncall for this shift.  ` + text                        
                            }
                        }

                        this.confirmationText = text

                        this.confirmScheduleChange = true

                    } else {
                        this.doToggleAssignment(shiftId, date, provider, assignmentId, currentState, multiple)
                    }
                }

/*
                if (!this.assignmentEdits[shiftId]) {
                    this.$set(this.assignmentEdits,shiftId, {})
                    //this.assignmentEdits[params.shift.id] = {}
                }

                if (!this.assignmentEdits[shiftId][date]) {
                    this.$set(this.assignmentEdits[shiftId],date, {})
                }

                this.$set(this.assignmentEdits[shiftId][date],[provider], {
                    id: current ? current.id : -1,
                    state: currentState
                })
*/
            },
            writeEdit(shiftId, date, provider,value) {
                
                if (!this.assignmentEdits[shiftId]) {
                    this.$set(this.assignmentEdits,shiftId, {})
                }

                if (!this.assignmentEdits[shiftId][date]) {
                    this.$set(this.assignmentEdits[shiftId],date, {})
                }

                this.$set(this.assignmentEdits[shiftId][date],provider,value)
            },
            getEdits(shiftId, date) {
                
                if (!this.assignmentEdits[shiftId]) {
                    return {}
                }

                if (!this.assignmentEdits[shiftId][date]) {
                    return {}
                }

                return this.assignmentEdits[shiftId][date]
            },            
            doToggleAssignment(shiftId, date, provider, assignmentId, currentState, multiple) {
                
/*                
                if (!this.assignmentEdits[shiftId]) {
                    this.$set(this.assignmentEdits,shiftId, {})
                }

                if (!this.assignmentEdits[shiftId][date]) {
                    this.$set(this.assignmentEdits[shiftId],date, {})
                }
*/
                if (!multiple) {
                    //need to remove all the other edits
                    let edits = this.getEdits(shiftId,date)

                    for (let id in edits) {
                        if (id !== provider) {
                            let obj = edits[id]
                            obj.state = false
                            this.writeEdit(shiftId,date,id,obj)
                        }
                    }
                    let currentShift = this.shiftAssignmentMap[shiftId]
                    
                    if (currentShift) {
                        let currentDate = currentShift[date]
                        if (currentDate) {
                            for (let id in currentDate) {
                                //if (id !== provider) {
                                    let obj = currentDate[id]
                                    obj.state = false
                                    this.writeEdit(shiftId,date,id,obj)
                                //}
                            }

                        }
                    }

                }

                this.writeEdit(shiftId,date,provider, 
                    {
                    id: assignmentId,
                    state: currentState,
                    user_id: provider
                    })
/*
                this.$set(this.assignmentEdits[shiftId][date],provider, {
                    id: assignmentId,
                    state: currentState
                })
*/

            },
            async checkshifts () {

                if (this.selectedPod) {
                    if (!this.rangeStart || !this.rangeEnd || 
                        this.calendarStart < this.rangeStart || 
                        this.calendarStart > this.rangeEnd || 
                        this.calendarEnd < this.rangeStart || 
                        this.calendarEnd > this.rangeEnd) {
                            
                        this.loadingCalendar = true
                        let shiftResult = await this.loadShifts({pod_id: this.selectedPod, year: this.year, month: this.month});

                        this.shifts = shiftResult.shifts
                        this.rangeStart = shiftResult.begin
                        this.rangeEnd = shiftResult.end
                        this.loadingCalendar = false
                    }

                    let filters = await this.loadFilters({pod_id: this.selectedPod})
                    this.filters = filters

                } else {
                    this.shifts = []
                }
            },
            addPod() {
                this.podId = null
                this.openPod = true
            },
            editPod() {
                this.podId = this.selectedPod
                this.openPod = true
            },

            podCancel() {
                this.openPod = false
            },
            async podSave(pod) {
                let result = await this.savePod(pod)

                this.openPod = false
            },
            checkRange(range) {
                console.log('range', range)

                this.calendarStart = range.start.date
                this.calendarEnd = range.end.date

                this.checkshifts()

                //the date strings are in yyyy-MM-dd
            },
            createAppointment (appt) {
                console.log('appt', appt)
                console.log('this.providerIds', this.providerIds)
            },
            async editAppointment (params) {

            },
            twoDigit (num) {
              let str = num + ''
              if (str.length === 1) {
                  str = '0' + str
              }
              return str
            },     
            getOriginalAssignment(shiftId, date) {
                let shiftAssignment = this.shiftAssignmentMap[shiftId]
                if (shiftAssignment) {
                    return shiftAssignment[date]
                }
            },
            getAssignmentOperations(shiftId, date, edit, original) {
                let operations = []
                console.log('edit', edit)
                console.log('original', original)

                let shift = this.shiftMap[shiftId]
                
                let multiple = shift.rotation || shift.rounding                 

                let providersToAdd = {}
                let providersToDelete = {}

                if (multiple) {

                    for (let providerId in edit) {
                        let editObj = edit[providerId]
                        if (editObj.state) {
                            if (!original || !original[providerId]) {
                                providersToAdd[providerId] = true
                            }
                        } else {
                            if (original[providerId]) {
                                providersToDelete[providerId] = true
                            }
                        }
                    }
                } else {
                    for (let providerId in edit) {
                        let editObj = edit[providerId]
                        if (editObj.state) {
                            if (!original || !original[providerId]) {
                                providersToAdd[providerId] = true
                            }
                            for (let oProviderId in original) {
                                if (providerId !== oProviderId) {
                                    providersToDelete[oProviderId] = true
                                }
                            }
                        } else {
                            if (original && original[providerId]) {
                                providersToDelete[providerId] = true
                            }
                        }
                    }

                }
                console.log('providersToAdd',providersToAdd)
                console.log('providersToDelete',providersToDelete)

                for (let providerId in providersToAdd) {
                    operations.push({operation: 'add', shift_id: shiftId, date: date, provider_id: providerId})
                }

                for (let providerId in providersToDelete) {
                    operations.push({operation: 'delete', shift_id: shiftId, date: date, provider_id: providerId})
                }
                return operations

            }     
        },
        async mounted () {
            this.loadFacilities()
            await this.loadGroupList()
            this.loadingGroupList = false
            if (this.getGroupId) {
                this.group = await this.loadGroup(this.getGroupId)
            }
        },
        computed: {
            ...mapGetters('schedule', ['v2SchedulingUrl', '']),
            ...mapGetters('schedule',['getGroupId','getGroupList']),

            tbShiftsFilterArray () {
                let arr = []
                if (this.shifts && this.shifts.length){
                    this.shifts.map(s => {
                        if (!s.personal) {
                            arr.push(s)
                        }
                    })
                }

                let ret = this.tbSortShifts(arr)

                return ret
            },

            tbCalendarRangeDescription () {
              if (!this.tbCurrentRange) {
                return ''
              }
              
              switch (this.tbCalendarType) {
                case 'day':
                  let desc = `${this.months[this.tbCurrentRange.start.month-1]} ${this.tbCurrentRange.start.day}`
                  let dt = new Date()
                  if (dt.getFullYear() != this.tbCurrentRange.start.year) {
                    desc += `, ${this.tbCurrentRange.start.year}`
                  }
                  return desc
                default: 
                  return `${this.months[this.tbCurrentRange.start.month-1]} ${this.tbCurrentRange.start.year}`
              }
            },            
            tbCalendarTypeDescription () {
              let currentType = this.tbCalendarType
              let item = this.tbCalendarTypes.find(ct => {
                return ct.value === currentType
              })
              return item.name
            },            
            shiftEditCaption () {
                let caption = `${this.selectedPodObject ? this.selectedPodObject.name : ''} Shifts`
                return caption
            },
            day () {
                let ret 
                if (this.calendarDate) {
                    let pieces = this.calendarDate.split('-')
                    ret = pieces[2]
                } else {
                    let dt = new Date()
                    ret = dt.getDate()
                }
                return ret
            },

            year () {

                let ret 
                if (this.calendarDate) {
                    let pieces = this.calendarDate.split('-')
                    ret = pieces[0]
                } else {
                    let dt = new Date()
                    ret = dt.getFullYear()
                }
                return ret
            },
            month () {

                let ret 
                if (this.calendarDate) {
                    let pieces = this.calendarDate.split('-')
                    ret = pieces[1]
                } else {
                    let dt = new Date()
                    ret = dt.getMonth()+1
                }
                return ret
            },            
           
            shiftMap () {
                let ret = {}

                if (this.shifts && this.shifts.length) {
                    this.shifts.map(s => {
                        ret[s.id] = s
                    })
                }

                return ret
            },
            shiftAssignmentMap () {
              let ret = {}

              if (this.shifts && this.shifts.length) {
                this.shifts.map(s => {

                    if (s.assignments && s.assignments.length) {
                        s.assignments.map(a => {
                            let assignmentDate = `${a.year}-${this.twoDigit(a.month)}-${this.twoDigit(a.day)}`
                            if (!ret[s.id]) {
                                ret[s.id] = {}
                            }
                            if (!ret[s.id][assignmentDate]) {
                                ret[s.id][assignmentDate] = {}
                            }

                            ret[s.id][assignmentDate][a.user_id] = a
                        })
                    }                                 
                })
              }
              return ret
            },            

            combinedAssignmentMap () {

                let ret = JSON.parse(JSON.stringify(this.shiftAssignmentMap))

                if (this.shifts && this.shifts.length) {
                this.shifts.map(s => {
                    let shiftEdits = this.assignmentEdits[s.id]

                    if (shiftEdits) {

                        for (let date in shiftEdits) {

                            let dateEdits = shiftEdits[date]
                            if (dateEdits) {                              
                                for (let uid in dateEdits) {
                                    if (!ret[s.id]) {
                                        ret[s.id] = {}
                                    }
                                    if (!ret[s.id][date]) {
                                        ret[s.id][date] = {}
                                    }
                                    
                                    if (dateEdits[uid]){
                                        ret[s.id][date][uid] =  dateEdits[uid]
                                        /*
                                        if (dateEdits[uid].state) {
                                            ret[s.id][date][uid] =  dateEdits[uid]
                                        } else {
                                            delete ret[s.id][date][uid]
                                        }
                                        */
                                    }
                                }
                            }
                        }
                    }                                    

                })
                }

                return ret
            },            
            eventsArray () {
                return []
            },
            selectedGroup: {
                get: function() {
                    return this.getGroupId;
                },
                set: function(newValue) {
                    this.$store.commit('schedule/setGroupId',newValue)
                }
            },   
            groupObject () {
                let obj = this.$store.getters['schedule/getGroupById'](this.selectedGroup)
                return obj ? obj : {}
            },                      
            pods () {

                if (this.group && this.group.pods && this.group.pods.length) {
                    return this.group.pods
                }

                return []
            },
            podProviderIdInclude () {
                let map = {}
                if (this.selectedPodObject && this.selectedPodObject && this.selectedPodObject.user_ids) {
                    
                    this.selectedPodObject.user_ids.map(id => {
                        map[id] = true
                    })

                    let personal = []
                    this.shifts.map(s => {
                        if (s.personal) {

                            personal.push(s)
                        } else {
                            if (s.assignments && s.assignments.length) {
                                s.assignments.map(a => {
                                    map[a.user_id] = true
                                })
                            }
                        }
                    })
                    /*
                    personal.map(p => {
                        if (map[p.personal_coveree] || map[p.personal_coverer]) {
                            map[p.personal_coveree] = true
                            map[p.personal_coverer] = true
                        }
                    })
                    */
                    let arr = []
                    for (let id in map) {
                        arr.push(id)
                    }

                    return arr
                }
            },
            selectedPodObject () {
                let arr = this.pods

                let ret
                if (this.selectedPod) {
                    ret = arr.find(p => {
                        return p.id === this.selectedPod
                    })
                }

                return ret
            },
            providers () {

                if (this.group && this.group.users && this.group.users.length) {
                    return this.group.users
                }

                return []
            },
            providerMap () {
              let ret = {} 
              if (this.providers && this.providers.length) {
                this.providers.map(p => {
                  ret[p.id] = p
                })
              }
              return ret
            },

            isDirty () {
                let operations = this.operations
                return operations && operations.length ? true : false
            },

            operations () {
                let ret = []
                for (let shiftId in this.assignmentEdits) {
                    let shiftEdits = this.assignmentEdits[shiftId]

                    if (shiftEdits) {

                        for (let date in shiftEdits) {
                            
                            let edit = shiftEdits[date]

                            if (edit) {
                                let original = this.getOriginalAssignment(shiftId, date)

                                let ops = this.getAssignmentOperations(shiftId, date, edit, original)

                                if (ops && ops.length) {
                                    ret.push(...ops)
                                }
                            }
                        }

                    }

                }
                return ret
            }

        }
    }

</script>

<style scoped>

</style>
