<template>

    <div class="d-flex">
        <v-select
            dense flat solo
            hide-details
            v-model="selectedProvider"
            :items="items"
            :loading="false"
            item-text="lastname"
            item-value="id"
            :background-color="backgroundColor"
            :outlined="outlined"            
            label="Select Provider"
            :rules="rules"
            clearable
            :auto-select-first="false"
            :key="`refresh_${refresh}`"
            full-width
            :multiple="multiple"
            @focus="onFocus"
            @blur="onBlur"            
        >

            <template v-slot:prepend-item>

                <v-checkbox
                    v-if="showGroupProviders"
                    dense
                    hide-details
                    v-model="includeGroupProviders"
                    label="Include Group Providers"
                    class="pl-3"
                ></v-checkbox>
                
                <v-checkbox
                    v-if="showOutOfGroupProviders"
                    dense
                    hide-details
                    v-model="includeOutOfGroupProviders"
                    label="Include Out of Group Providers"
                    class="pl-3"
                ></v-checkbox>

                <v-text-field light solo dense flat
                    v-if="showFilter"
                    placeholder="Filter"
                    hide-details
                    :background-color="backgroundColor"
                    class="px-4"
                    v-model="providerFilter" />
            </template>


            <template v-slot:item="data">
                <v-container
                class="pa-0 ma-0 "                
                :style="`background-color: ${data.item.bg};color: ${data.item.fg};border: 1px solid ${data.item.border};`"
                >        
                    <v-row
                        class="pa-0 ma-0"                     
                    >
                        <v-col cols="1"
                            v-if="true"
                            class="pa-0 ma-0"
                        >
                            <v-checkbox
                                dense
                                hide-details
                                :input-value="data.attrs.inputValue"
                                :class="checkboxClass(data.item)"
                                :style="`visibility: ${multiple ? 'visible': 'hidden'}`"
                            />

                        </v-col>
                        <v-col :cols="listItemColumns"
                            class="pa-0 ma-0 fw-700"
                        >                        
                        {{`${data.item.pod ? '*':''}${data.item.last_name ? data.item.last_name+', ':''}${data.item.first_name}${data.item.credentials ? ': ' + data.item.credentials: ''} `}}
                        </v-col>
                    </v-row>
                    <v-row
                        class="pa-0 ma-0"
                        v-if="data.item.specialty"
                    >
                        <v-col cols="1"
                            v-if="true"
                            class="pa-0 ma-0"
                        >
                            &nbsp;
                        </v-col>
                        <v-col :cols="listItemColumns"
                            class="pa-0 ma-0"
                        >
                        <span class="fw-300">Specialty:</span> <span class="fw-400">{{data.item.specialty}}</span>
                        </v-col>
                    </v-row>

                    <v-row
                        class="pa-0 ma-0"
                        v-if="data.item.group_name"                 
                    >
                        <v-col cols="1"
                            v-if="true"
                            class="pa-0 ma-0"
                        >
                            &nbsp;
                        </v-col>                    
                        <v-col :cols="listItemColumns"
                            class="pa-0 ma-0"
                        >
                        <span class="fw-300">Group:</span> <span class="fw-400">{{data.item.group_name}}</span>

                        </v-col>
                    </v-row>
                    <v-row
                        class="pa-0 ma-0"
                        v-if="!data.item.group_name"   
                    >
                        <v-col cols="12"
                            class="pa-0 ma-0"
                        >
                        <span class="fw-300">&nbsp;</span>

                        </v-col>
                    </v-row>

                </v-container>

            </template>
            <template v-slot:selection="data">
                <v-chip
                    v-if="multiple"
                    close
                    v-bind="data.attr"
                    @click:close="close(data.item)"
                    :key="`selected_${data.item.id}`"
                    :color="data.item.bg"
                    :text-color="data.item.fg"
                >
                    {{`${data.item.last_name ? data.item.last_name+', ':''}${data.item.first_name}${data.item.credentials ? ': ' + data.item.credentials: ''}${data.item.pod ? ' (pod)': '' } `}}
                </v-chip>  
                <v-container
                    class="pa-0 ma-0"
                    :style="`background-color: ${data.item.bg};color: ${data.item.fg};border: 1px solid ${data.item.border};` "
                    v-else
                >        
                    <v-row
                        class="pa-0 ma-0"
                    >
                        <v-col cols="1"
                            v-if="true"
                            class="pa-0 ma-0"
                        >
                            &nbsp;
                        </v-col>                    
                        <v-col :cols="listItemColumns"
                            class="pa-0 ma-0 fw-700"
                        >
                        
                        {{`${data.item.last_name ? data.item.last_name+', ':''}${data.item.first_name}${data.item.credentials ? ': ' + data.item.credentials: ''} `}}
                        </v-col>
                    </v-row>
                    <v-row
                        class="pa-0 ma-0"
                        v-if="data.item.specialty"
                    >
                        <v-col cols="1"
                            v-if="true"
                            class="pa-0 ma-0"
                        >
                            &nbsp;
                        </v-col>    

                        <v-col :cols="listItemColumns"
                            class="pa-0 ma-0"
                        >
                        <span class="fw-300">Specialty:</span> <span class="fw-400">{{data.item.specialty}}</span>
                        </v-col>
                    </v-row>

                    <v-row
                        class="pa-0 ma-0  text-no-wrap"
                        v-if="data.item.group_name"
                    >
                        <v-col cols="1"
                            v-if="true"
                            class="pa-0 ma-0"
                        >
                            &nbsp;
                        </v-col>                        
                        <v-col :cols="listItemColumns"
                            class="pa-0 ma-0"
                        >
                        <span class="fw-300">Group:</span> <span class="fw-400">{{data.item.group_name}}</span>

                        </v-col>
                    </v-row>
                    <v-row
                        class="pa-0 ma-0"
                        v-else

                    >
                        <v-col cols="12"
                            class="pa-0 ma-0"
                        >
                        <span class="fw-300">&nbsp;</span>

                        </v-col>
                    </v-row>                    
                </v-container> 
            </template>
            <template v-slot:selectionxxx="data">
                <v-container
                class="pa-0 ma-0"
                :style="`background-color: ${data.item.bg};color: ${data.item.fg};border: 1px solid ${data.item.border};` "
                >        
                    <v-row
                        class="pa-0 ma-0"
                    >
                        <v-col cols="1"
                            v-if="true"
                            class="pa-0 ma-0"
                        >
                            &nbsp;
                        </v-col>                    
                        <v-col :cols="listItemColumns"
                            class="pa-0 ma-0 fw-700"
                        >
                        {{`${data.item.last_name ? data.item.last_name+', ':''}${data.item.first_name}${data.item.credentials ? ': ' + data.item.credentials: ''} `}}
                        </v-col>
                    </v-row>
                    <v-row
                        class="pa-0 ma-0"
                        v-if="data.item.specialty"
                    >
                        <v-col cols="1"
                            v-if="true"
                            class="pa-0 ma-0"
                        >
                            &nbsp;
                        </v-col>    

                        <v-col :cols="listItemColumns"
                            class="pa-0 ma-0"
                        >
                        <span class="fw-300">Specialty:</span> <span class="fw-400">{{data.item.specialty}}</span>
                        </v-col>
                    </v-row>

                    <v-row
                        class="pa-0 ma-0  text-no-wrap"
                        v-if="data.item.group_name"
                    >
                        <v-col cols="1"
                            v-if="true"
                            class="pa-0 ma-0"
                        >
                            &nbsp;
                        </v-col>                        
                        <v-col :cols="listItemColumns"
                            class="pa-0 ma-0"
                        >
                        <span class="fw-300">Group:</span> <span class="fw-400">{{data.item.group_name}}</span>

                        </v-col>
                    </v-row>
                    <v-row
                        class="pa-0 ma-0"
                        v-else

                    >
                        <v-col cols="12"
                            class="pa-0 ma-0"
                        >
                        <span class="fw-300">&nbsp;</span>

                        </v-col>
                    </v-row>                    
                </v-container>

            </template>

        </v-select>
    </div>

</template>

<script>
import {mapActions, mapGetters} from "vuex";

    export default {
        name: 'scheduleProvider',
        components: {
        },
        props: {
            value: {
                //type: String,
                default: null
            },            
            rules: {
                type: Array,
                default: () => []
            },            
            providers: {
                type: Array,
                default: () => []
            }, 
            provider_id_include: {
                type: Array,
                default: () => null
            }, 
            group_id: {
                type: String,
                default: ''
            },  
            pod_id: {
                type: String,
                default: ''
            },                                                
            multiple: {
                type: Boolean,
                default: false
            },  
            deferMultipleEvents: {
                type: Boolean,
                default: false
            },
            showGroupProviders: {
                type: Boolean,
                default: false
            },   
            showOutOfGroupProviders: {
                type: Boolean,
                default: false
            },                     
            showFilter: {
                type: Boolean,
                default: false
            },                       
            forceClear: {
                type: String,
                default: ''
            },
            outlined: {
                default: false,
                type: Boolean
            },
            backgroundColor: {
                default: 'white',
                type: String
            },
        },
        data: () => ({
            refresh: '',
            focusValue: null,
            localValue: null,
            providerFilter: null,
            includeGroupProviders: false,
            includeOutOfGroupProviders: false

        }),
        created() {

        },
        async mounted() {
            this.focusValue = null
            this.localValue = this.value
        },
        watch: {
            /*
            selectedProvider (newValue, oldValue) {
                if (newValue) {
                    this.$emit('providerSelected', newValue)
                    if (this.clearOnSelect) {
                        this.selectedProvider = null
                    }
                }
                this.$emit('input', newValue)
            },
            */
            forceClear (newValue, oldValue) {
                this.$nextTick(() => {
                    this.selectedProvider = null
                    this.refresh = newValue
                })
            }
        },
        methods: {
            matchesFilter (provider) {
                if (!this.providerFilter) {
                    return true
                }

                let match = this.providerFilter.toLowerCase()

                if (provider.first_name && provider.first_name.toLowerCase().indexOf(match) >= 0 ) {
                    return true
                }

                if (provider.last_name && provider.last_name.toLowerCase().indexOf(match) >= 0 ) {
                    return true
                }

                return false
                
            },
            serializeValue (value) {
                if (!Array.isArray(value)) {
                    return value
                }
                
                console.log('serializeValue value', value)
                let arr = JSON.parse(JSON.stringify(value))
                console.log('serializeValue arr', arr)
                let sorted = arr.sort()
                console.log('serializeValue sorted', sorted)
                return JSON.stringify(sorted)
            },
            onFocus(params) {
                console.log('onFocus',params)
                this.focusValue = this.serializeValue(this.localValue)

            },
            onBlur(params) {
                let blurValue = this.serializeValue(this.localValue)
                console.log('this.focusValue', this.focusValue)
                console.log('blurValue', blurValue)

                if (this.focusValue !== blurValue) {
                    this.$emit('input', this.localValue)
                }

                this.focusValue = null

            },            
            close(item) {
                if (this.value && this.value.length) {
                    let index = this.value.findIndex(p => {
                        return p === item.id
                    })

                    if (index >= 0) {
                        if (index >= 0) {
                            this.value.splice(index, 1)
                        }
                        this.$emit('input', this.value)
                    }                    
                }
            },
            checkboxClass(item) {
                switch (item.fg) {
                    case '#000':
                    case '#000000':
                    case '000':
                    case '000000':
                        return 'scheduleProviderClass000'
                    default:
                        return 'scheduleProviderClassfff'        
                }            
            }
        },
        computed: {
            ...mapGetters(
                'schedule',['getColors',]
            ),   
            listItemColumns () {
                return '11'
                //return this.multiple ? '11': '12'
            },            
            selectedProvider: {
                get: function() {
                    return this.value ;
                },
                set: function(newValue) {      
                    this.localValue = newValue

                    this.providerFilter = ''

                    if (!this.multiple) {
                        this.$emit('input', newValue)
                    } else if (!this.deferMultipleEvents) {
                        this.$emit('input', newValue)
                    }  
                }
            },   
            providerFilterMap () {
                let map
                if (this.provider_id_include) {

                    map = {}
                    this.provider_id_include.map(id => {
                        map[id] = true
                    })
                }

                return map
            },             
            items () {

                let arr = []

                let map = this.providerFilterMap
                let included = {}

                let podProviderMap = {}
                if (this.group_id && this.pod_id) {

                    let group = this.$store.getters['schedule/getGroupById'](this.group_id)

                    if (group) {
                        let pod = group.pods.find(p => {
                            return p.id === this.pod_id
                        })

                        if (pod) {
                            pod.user_ids.map(id => {
                                podProviderMap[id] = true
                            })
                        }
                    }
                }


                this.providers.map(p => {
                    if (this.matchesFilter(p)) {

                        if (!map || map[p.id]) {
                            included[p.id] = true

                            let color = this.getColors[p.user_index]

                            let provider = {
                                first_name: p.first_name,
                                last_name: p.last_name,
                                id: p.id,
                                user_index: p.user_index,
                                fg: color[1],
                                bg: color[0],
                                border: color[2]
                            }

                            if (podProviderMap[p.id]) {
                                provider.pod = true
                            }

                            if (p.group.id != this.group_id) {
                                provider.group_name = p.group.name                        
                            }
                            arr.push(provider)
                        }
                    }
                })

                if (this.includeGroupProviders || this.includeOutOfGroupProviders) {
                    let group = this.$store.getters['schedule/getGroupById'](this.group_id)

                    if (group) {
                        group.users.map(u => {    
                       
                            if (u.roleMap['provider']) {

                                if (!included[u.id]) {

                                    if ( (u.group.id === this.group_id && this.includeGroupProviders ) ||
                                        (u.group.id !== this.group_id && this.includeOutOfGroupProviders ) ) {

                                        if (this.matchesFilter(u)) {

                                            included[u.id] = true
                                            let color = this.getColors[u.user_index]

                                            let provider = {
                                                first_name: u.first_name,
                                                last_name: u.last_name,
                                                id: u.id,
                                                user_index: u.user_index,
                                                fg: color[1],
                                                bg: color[0],
                                                border: color[2]
                                            }

                                            if (u.group.id != this.group_id) {
                                                provider.group_name = u.group.name                        
                                            }                                            
                                            arr.push(provider)
                                        }
                                    }
                                }
                            }
                        })
                    }
                }

                let sorted = arr.sort( (a,b) => {
                    let asortable = (a.last_name ? a.last_name : '') + (a.first_name ? a.first_name : '') 
                    let bsortable = (b.last_name ? b.last_name : '') + (b.first_name ? b.first_name : '')
                    return asortable.localeCompare(bsortable)
                })

                return sorted
            }
        }
    }
</script>
<style scoped>
.scheduleProviderClassfff >>> i {
    color: #fff !important
}
.scheduleProviderClass000 >>> i {
    color: #000 !important
}
</style>