import gql from 'graphql-tag'

export default gql`query scheduleGet($operation: String!, $parameters: [NameIdValueInput]) {
	scheduleGet(operation: $operation, parameters: $parameters )
    {
		  operation
      groups {
        id
        name
        facilities {
          name
          id
        }
        pods {
          facility_ids
          group_id
          id
          name
          user_ids
          providers {
            user_id
            name
            provider_id
          }
          date_created
        }
      }
      users {
        id
        first_name
        last_name
        group {
          id
          name
        }
        roles
        user_index
        settings {
          preferences {
            name
            value
            id
          }
        }
		  }
      facilities {
        id
        name
        shortcode
      }


      shifts {
        catchall
        end
        id
        name
        outpatient
        personal
        personal_coveree
        personal_coverer
        rounding   
        rotation
        start
        type
        filter
        rounding_facilities
        weekdays
        assign_new_patient
        assign_temporary
        assignments {
          day
          month
          pod_id
          skip_rotation
          template_id
          user_id
          year
          shift_id
          shift_type
          shift_name
          added_by
          date_added
        }
        rules
        start_year
        start_month
        start_day
        start_hours
        start_minutes
        start_seconds
        end_year
        end_month
        end_day
        end_hours
        end_minutes
        end_seconds

      }
      filters {
        id
        name
        default_options {
          id
          type
          name
          operator
          value_json  
        }
        rules
        message_fields {
          id
          type
          name
          operator
          value_json          
        }
        deleted
        priority
        user_id
        group
        date_created
        created_by
        created_by_first_name
        created_by_last_name
        date_deleted
        deleted_by
        last_edit_date
        last_edit_by        
      }
      data {
          name
          id
          value
		  }      
    }
}`
