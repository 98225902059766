<template>

    <v-list-item
      :style="`background-color: ${bgColor};color:${fgColor};`"
    >
      <v-list-item-title>
      {{provider.first_name}} {{provider.last_name}}
      </v-list-item-title>
      <v-list-item-subtitle
      v-if="provider.group.id != groupId"
      :style="`background-color: ${bgColor};color:${fgColor};`"
      >
      {{provider.group.name}}
      </v-list-item-subtitle>
      
      <v-list-item-icon>
        <color-provider
          :provider="provider"
          :groupId="groupId"
          :userIndexEdits="userIndexEdits"
          @close="close"
        />
      </v-list-item-icon>
    </v-list-item>

</template>

<script>
import {mapActions, mapGetters} from "vuex";
import colorProvider from '@/views/apps/scheduling-new/components/colorProvider'

  export default {
    props: {
      provider : {
        type: Object,
        default: () => {}
      },
      groupId : {
        type: String,
        default: ''
      },    
      userIndexEdits : {
        type: Object,
        default: () => {}
      }          
    },
    components: {
      'color-provider': colorProvider
    },    
    data: () => ({
      menu: false
    }),
    mounted() {
    },
    methods: {
      close(params) {
        this.$emit('close', params)
      }
    },
    computed: {
      ...mapGetters(
          'schedule',['getColors',]
      ),         
      userIndex () {
        let ui = -1
        
        if (this.provider) {
          ui = this.userIndexEdits[this.provider.id] 

          if (ui >= 0) {
            //it has been edited.  Use it
          } else {
            ui = this.provider.user_index
          }
        }

        return ui

      },
      fgColor () {
        if (this.userIndex >= 0) {
          let color = this.getColors[this.userIndex]
          return color[1]
        }
      },
      bgColor () {
        if (this.userIndex >= 0) {
          let color = this.getColors[this.userIndex]
          return color[0]
        }
      }

    },
    watch: {

    }

  }
</script>

