<template>

    <div>
          <v-calendar
              ref="calendar"
              v-model="calendarDate"
              :type="tbCalendarType"
              :events="events"
              :event-more="false"
              :key="`calendar_${refreshCalendar}_${localRefreshCalendar}`"
              @change="rangeChanged"
              @click:interval="onIntervalClick"
              @click:time="onTimeClick"
              @click:event="onEventClick"
              @click:day="onDayClick"
              @click:date="onDateClick"
              id="schedule_calendar"
              :interval-count="0"
              v-resize="onResize"
          >
            <template v-slot:eventxxx="{ event }">
              <div
                style="overflow: visible;text-wrap: wrap"
              >
                {{event}}                
              </div>
            </template>
            <template v-slot:day="props">
              <div
                :style="cursorStyle"
              >
              <schedule-shift 
                :shifts="dayShifts[props.date]"
                :date="props.date"
                :assignments="getAssignments(dayShifts[props.date], props.date)"
                :personalCoverage="personalCoverageMap"
                :providerMap="providerMap"
                :scroll="false"
                :dayWidth="dayWidth"
                @shiftClick="onShiftClick"
                :key="`shifts_${refreshCalendar}_${localRefreshCalendar}`"
              />
              <schedule-personal-coverage
                :dayWidth="dayWidth"
                :providerMap="providerMap"
                :personalCoverage="personalCoverageMapByDate[props.date]"
                style="cursor: default"
              />
              </div>
            </template>
            <template v-slot:day-body="props">
              <div>daybody-{{props}}</div>
            </template>
            <template v-slot:day-header="props">

              <schedule-shift-day
                :shifts="dayShifts[props.date]"
                :date="props.date"
                :assignments="getAssignments(dayShifts[props.date], props.date)"
                :personalCoverage="personalCoverageMap"
                :providerMap="providerMap"
                @shiftDayEdit="onShiftDayEdit"
                :group_id="group_id"
                :key="`shifts_day_${refreshCalendar}_${localRefreshCalendar}`"
              />

            </template>
          </v-calendar>
       
    </div>

</template>

<script>
import {mapActions, mapGetters} from "vuex";
import { addDays } from 'date-fns'; 
import {formatSimple} from '@/methods/formatters';
import scheduleShift from '@/views/apps/scheduling-new/components/scheduleShift';
import schedulePersonalCoverage from '@/views/apps/scheduling-new/components/schedulePersonalCoverage';
import scheduleProvider from '@/views/apps/scheduling-new/components/scheduleProvider';
import scheduleShiftDay from '@/views/apps/scheduling-new/components/scheduleShiftDay';

    export default {
        name: 'scheduleCalendar',
        components: {
            'schedule-shift': scheduleShift,
            'schedule-personal-coverage': schedulePersonalCoverage,
            'schedule-shift-day': scheduleShiftDay,
            'schedule-provider': scheduleProvider
        },
        props: {          
            startDate: {
                type: Date,
                default: () => new Date()
            },       
            endDate: {
                type: Date,
                default: () => addDays(new Date(), 30)
            },
            shifts: {
                type: Array,
                default: () => []
            },
            assignmentMap: {
                type: Object,
                default: () => {}
            },   

            /*
            events: {
                type: Array,
                default: () => []
            },
            */
            loading: {
                type: Boolean,
                default: false
            }, 
            refreshCalendar: '',
            providers: {
                default: () => [],
                type: Array
            },
            group_id: {
                type: String,
                default: ''
            }, 
            value: {
              type: String,
              default: ''
            },   
            showToolbar: {
                type: Boolean,
                default: true
            },      
            tbSelectedShifts: {
              type: Array,
              default: () => []
            },
            tbSelectedProvider: {
              type: String,
              default: null
            },  
            tbCalendarType: {
              type: String,
              default: 'month'
            },                                      
            tbCurrentRange: {
              type: Object,
              default: () => {}
            },

        },
        data: () => ({
            //calendarDate: null,
            months: ['Jan','Feb','Mar','Apr','May','June','July','Aug','Sept','Oct','Nov','Dec'],
            days: ['Sun','Mon','Tues','Wed','Thur','Fri','Sat'],
            currentRange: null,
            localRefreshCalendar: '',
            viewStart: '',
            viewEnd: '',
            localSelectedShifts: [],
            localSelectedProvider: null,
            cursorUrls: {},
            cursorUrl: '',
            dayWidth: 165

        }),
        created() {

        },
        async mounted() {
          let now = new Date()
          this.calendarDate = formatSimple(now,'yyyy-MM-dd')

          setTimeout(()=> {
            this.dayWidth = this.calculateDayWidth()
          }, 500)

          //this.checkDate()

/*
          setTimeout(() => {
            console.log('scrolling', this.$refs.calendar)
            if (this.$refs.calendar) {
              this.$refs.calendar.scrollToTime('08:00')
            }
          }, 500);
*/          
        },

        methods: {
          onResize () {
            this.dayWidth = this.calculateDayWidth()
          },
          calculateDayWidth () {

            let element = this.$el.querySelector('div.v-calendar-weekly__day')

            if (element) {
              return element.clientWidth
            }
          },
          onShiftDayEdit(params) {
            this.$emit('shiftDayEdit',params)
          },
          onShiftClick(params) {

            if (this.selectedProvider) {

              params.provider = this.selectedProvider

              this.$emit('shiftClick',params)

/*
              let key = `${params.date}_${params.id}_${this.selectedProvider}`

              let currentAssignment = this.assignmentMap[key]


              if (!this.edits[params.date]) {
                this.edits[params.date] = {}
              }
              if (!this.edits[params.date][params.shift.id]) {
                this.edits[params.date][params.shift.id] = {}
              }

              let currentEdit = this.edits[params.date][params.shift.id][this.selectedProvider]

              let currentAssignment = this.dayShifts[params.date]
*/
            }
          },
          createCursorUrl(color) {
            let cursor = document.createElement('canvas')
            let ctx = cursor.getContext('2d');

            cursor.width = 16;
            cursor.height = 16;
            ctx.fillStyle = color[0];
            ctx.fillRect(6, 0, 4, 16);
            ctx.fillRect(0, 6, 16, 4);            
/*
            ctx.strokeStyle = color.bg;

            ctx.lineWidth = 2;
            ctx.moveTo(2, 10);
            ctx.lineTo(2, 2);
            ctx.lineTo(10, 2);
            ctx.moveTo(2, 2);
            ctx.lineTo(30, 30)    
            ctx.stroke();
*/
            let url = cursor.toDataURL()
            return url
          },
            getAssignments (shifts, date) {
              let ret = {}
              if (this.shifts && this.shifts.length) {

                  this.shifts.map(s => {

                    let shiftAssignments = this.assignmentMap[s.id]
                    if (shiftAssignments){
                      let dateAssignments = shiftAssignments[date]

                      if (dateAssignments) {
                        for (let uid in dateAssignments) {
                          let provider = this.providerMap[uid]                      
                          let color = this.getColors[this.getColors.length-1]
                          if (provider) {
                            color = this.getColors[provider.user_index]
                          }
                          if (!ret[s.id]) {
                              ret[s.id] = []
                          } 
                          let assignment = dateAssignments[uid]
                          if (typeof(assignment.state) === 'undefined' || assignment.state) {
                            ret[s.id].push({
                              user_id: uid,
                              id: assignment.id,
                              fg: color[1],
                              bg: color[0],
                              border: color[2]
                            })
                          }
                        }
                      }
                    }

                  })

              }
              return ret

            },
       
            onTimeClick (dateTime) {
              debugger

              if (dateTime.past) {
                //may have to do our own timezone aware checking
                this.$toasted.error('Appointments can not be made in the past');
                return
              }
              //let evenMin = Math.floor(dateTime.minute/15) * 15

              //let appointmentTime = `${dateTime.date} ${dateTime.hour}:${this.twoDigit(evenMin)}:00`

              //let dt = new Date(appointmentTime)

              let timepieces  = dateTime.time.split(':')

              if (timepieces[1] > '45') {
                timepieces[1] = '45'
              } else if (timepieces[1] > '30') {
                timepieces[1] = '30'
              } else if (timepieces[1] > '15') {
                timepieces[1] = '15'
              } else if (timepieces[1] > '0') {
                timepieces[1] = '00'
              }

              this.$emit('createAppointment', {
                date: dateTime.date,
                time: `${timepieces[0]}:${timepieces[1]}`
              })
            },
            onIntervalClick (params1, params2) {
              debugger
            },
            onEventClick (params, nativeEvent) {
              debugger
              if (params.event) {
                if (params.event.eventType === 'appointment') {

                  nativeEvent.preventDefault()
                  nativeEvent.stopPropagation()
                  this.$emit('editAppointment', {
                    id: params.event.id
                  })
              
                }
              }

            },
            onDayClick (date) {
              //debugger
              //this.returnRange = this.currentRange
              //this.calendarType = 'day'     
            },
            onDateClick (date, ev) {

              this.$emit('onDateClick', date)   
            },
            rangeChanged(range) {
              console.log('range start', range.start)
              console.log('range end', range.end)

              this.viewStart = this.$refs.calendar.getStartOfWeek(range.start);
              this.viewEnd = this.$refs.calendar.getEndOfWeek(range.end);

              this.currentRange = range
              this.$emit('getEvents', range)
              this.$emit('update:tbCurrentRange', range)
            },
            sortShifts (shifts) {
              
              if (shifts && shifts.length) {
                let sortType = this.$store.getters['profile/getPreference']('schedule_sort_type', 'time')

                let ret
                if (sortType === 'name') {
                  ret = shifts.sort((a,b) => {
                    let asortable = `${a.personal}_${a.last_name}${a.first_name}`
                    let bsortable = `${b.personal}_${b.last_name}${b.first_name}` 

                    return asortable.localeCompare(bsortable);
                  })
                } else {

                  ret = shifts.sort((a,b) => {
                    let asortable = `${a.personal}_${a.start}_${a.name}_${a.end}`
                    let bsortable = `${b.personal}_${b.start}_${b.name}_${b.end}` 

                    return asortable.localeCompare(bsortable);
                  })

                }
                return ret
              }
              return []
            },            

            setScrollSize() {
/*              
              let element = this.$el.querySelector('#emr_container')

              let tbelement = this.$el.querySelector('#emr_toolbar')
              element.style.height = `${window.innerHeight-tbelement.offsetTop-tbelement.offsetHeight-57}px`
*/
            }
        },
        computed: {
            
            ...mapGetters(
                'schedule',['getColors',]
            ), 
            
            calendarDate : {
                get: function() {                    
                    return this.value
                },
                set: function(newValue) {     
                  this.$emit('input', newValue)
                }
            }, 
            selectedShifts : {
                get: function() {                    
                    return this.showToolbar ?  this.localSelectedShifts : this.tbSelectedShifts ;
                },
                set: function(newValue) {     
                  this.localSelectedShifts = newValue 
                }
            }, 
            selectedProvider : {
                get: function() {              
                    return this.showToolbar ?  this.localSelectedProvider : this.tbSelectedProvider ;
                },
                set: function(newValue) {     
                  this.localSelectedShifts = newValue 
                }
            },                           
            cursorStyle () {
              if (this.cursorUrl) {
                 //return `cursor: pointer ;`
                 return `cursor: url(${this.cursorUrl}), auto;`
              } else {
                return ''
              }
            },          
            providerMap () {
              let ret = {} 
              if (this.providers && this.providers.length) {
                this.providers.map(p => {
                  ret[p.id] = p
                })
              }
              return ret
            },
            personalCoverageMap () {
              let ret = {}
              this.shifts.map(s => {
                if (s.personal && s.personal_coveree) {
                  if (!ret[s.personal_coveree]) {
                    ret[s.personal_coveree] = []
                  }
                  ret[s.personal_coveree].push(s)
                }
              })
              return ret
            },
            personalCoverageMapByDate () {
              let ret = {}

              this.shifts.map(s => {
                if (s.personal && s.personal_coveree) {

                  let start = new Date(s.start_year,s.start_month-1,s.start_day,s.start_hours,s.start_minutes,s.start_seconds)

                  let end = new Date(s.end_year,s.end_month-1,s.end_day,s.end_hours,s.end_minutes,s.end_seconds)

                  let current = start
                  while (current < end) {

                    let key =  formatSimple(current,'yyyy-MM-dd')
                    //console.log('key', key)

                    if (!ret[key]) {
                      ret[key] = []
                    }
                    ret[key].push(s)
                    
                    current = addDays(current, 1)
                  }
         
                }
                
              })
              return ret
            },
            weekdayShifts () {
              let ret = {}
              for (let i = 0;i<7;i++) {
                ret[`wd${i}`] = []
              }
              this.filteredShiftsArray.map(s => {

                if (!s.personal) {
                  if (!s.weekdays || !s.weekdays.length) {
                    for (let i = 0;i<7;i++) {
                      ret[`wd${i}`].push(s)
                    }
                  } else {
                    s.weekdays.map(d => {
                      ret[`wd${d}`].push(s)
                    })
                  }
                }                
              })
              return ret
            },
            dayShifts () {
              let ret = {}
              let weekdayMap = this.weekdayShifts
              if (this.viewStart) {
                let pieces = this.viewStart.date.split('-')
                let startDate = new Date(pieces[0], parseInt(pieces[1])-1, pieces[2])
                let endPieces = this.viewEnd.date.split('-')
                let endDate = new Date(endPieces[0], parseInt(endPieces[1])-1, endPieces[2])

                let current = startDate
                let i = 0
                while (current < endDate && ++i < 50) {

                  let key =  formatSimple(current,'yyyy-MM-dd')
                  //console.log('key', key)
                  let weekDay = current.getDay()
                  //console.log('weekDay', weekDay)

                  ret[key] = weekdayMap[`wd${weekDay}`]

                  current = addDays(current, 1)
                }  
              }

              return ret
               
            },

            events () {
              let arr = []
              /*
              let dates = [new Date(2025,0,15),new Date(2025,0,16)]

              for (let j = 0;j<dates.length;j++) {
                let date = dates[j]
                for (let i = 0; i < 10; i++) {
                  let start = new Date(date)
                  start.setHours(i+1)
                  let end = new Date(start)
                  end.setHours(i+2)
                  arr.push({
                    name: `template ${i}`,
                    start: start,
                    end: end,
                    color: this.colors[i],
                  })
                }

              }
              */
              return arr
            },
            shiftsFilterArray () {
                let arr = []
                if (this.shifts && this.shifts.length){
                    this.shifts.map(s => {
                        if (!s.personal) {
                            arr.push(s)
                        }
                    })
                }

                let ret = this.sortShifts(arr)

                return ret
            },
            selectedShiftsMap () {
              let map = null

              if (this.selectedShifts && this.selectedShifts.length) {
                this.selectedShifts.map(s => {
                  if (!map) {
                    map = {}
                  }
                  map[s] = true
                })
              }

              return map
            },
            filteredShiftsArray () {
                if (!this.shifts || !this.shifts.length) {
                    return []
                }
                let arr = []
                let map = this.selectedShiftsMap

                this.shifts.map(s => {
                  if (!map || map[s.id]) {
                    s.name = s.name.trim()
                    arr.push(s)
                  }
                })

                let ret = this.sortShifts(arr)

                return ret
            },
        },
        watch: {
          /*
          calendarDate (newValue, oldValue) {
            debugger
            this.$emit('input', newValue)
          },
          */
          selectedProvider (newValue, oldValue) {
            let url
            if (newValue) {
              let provider = this.providerMap[newValue]
              if (provider) {

                url = this.cursorUrls[newValue]

                if (url) {
                  this.cursorUrl = url
                } else {
                  let color = this.getColors[provider.user_index]

                  url = this.createCursorUrl(color)

                  this.cursorUrls[newValue] = url
                }
              }
            }
              
            this.cursorUrl = url ? url : ''

          },
        },
    }
</script>
<style scoped>

.v-calendar-weekly {
  display: table;
  table-layout: fixed;
}
.v-calendar-weekly__week {
  height: auto;
  display: table-row;
}
.v-calendar-weekly__day {
  display: table-cell;
  width: calc(100% / 7)
}
.v-calendar-weekly__head {
    height: auto;
    display: table-row;
}
.v-calendar-weekly__head-weekday {
    display: table-cell;
    width: calc(100% / 7)
}
</style>