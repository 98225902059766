import gql from 'graphql-tag'

export default gql`mutation scheduleOperation($operation: String!, $parameters: [NameIdValueInput]) {
	scheduleOperation(operation: $operation, parameters: $parameters )
    {
      operation
      pods {
        facility_ids
        group_id
        id
        name
        user_ids
        providers {
          user_id
          name
          provider_id
        }
        date_created
      }
      shifts {
        catchall
        end
        id
        name
        outpatient
        personal
        personal_coveree
        personal_coverer
        rounding   
        rotation
        start
        type
        rounding_facilities
        weekdays
        assign_new_patient
        assign_temporary
        assignments {
          day
          month
          pod_id
          skip_rotation
          template_id
          user_id
          year
          shift_id
          shift_type
          shift_name
          added_by
          date_added
        }
        rules
        start_year
        start_month
        start_day
        start_hours
        start_minutes
        start_seconds
        end_year
        end_month
        end_day
        end_hours
        end_minutes
        end_seconds
    }
    data {
        name
        id
        value
    }      
    users {
        id
        first_name
        last_name
        group {
          id
          name
        }
        roles
        user_index
        settings {
          preferences {
            name
            value
            id
          }
        }
		  }

  }
}`
