<template>

  <v-list
    dense
    style="height: 170px;overflow-y:auto;"
    class="pa-0 ma-0"
  >
    <template
      v-for="(item,i) in sortedProviders"
    >
      <color-provider-list-item
        :key="`provider_list_item_${item.id}_${i}`"
        :provider="item"
        :groupId="groupId"
        :userIndexEdits="userIndexEdits"
        @close="close"
      />
    </template>
  </v-list>

</template>

<script>
import {mapActions, mapGetters} from "vuex";
import colorProviderListItem from '@/views/apps/scheduling-new/components/colorProviderListItem'
  export default {
    props: {
      providers : {
        type: Array,
        default: () => []
      },
      groupId : {
        type: String,
        default: ''
      },    
      userIndexEdits : {
        type: Object,
        default: () => {}
      }          
    },
    components: {
        'color-provider-list-item': colorProviderListItem
    },    
    data: () => ({
      menu: false
    }),
    mounted() {
    },
    methods: {
      close(params) {
        this.$emit('save', params)
      }
    },
    computed: {
      ...mapGetters(
          'schedule',['getColors',]
      ),   
      sortedProviders () {
        let sorted = this.providers.sort( (a,b) => {
            let asortable = (a.first_name ? a.first_name.trim() : '') + (a.last_name ? a.last_name.trim() : '') 
            let bsortable = (b.first_name ? b.first_name.trim() : '') + (b.last_name ? b.last_name.trim() : '')
            return asortable.localeCompare(bsortable)
        })
        return sorted
      },      
      userIndex () {
        let ui = -1
        
        if (this.provider) {
          ui = this.userIndexEdits[this.provider.id] 

          if (ui >= 0) {
            //it has been edited.  Use it
          } else {
            ui = this.provider.user_index
          }
        }

        return ui

      },
      fgColor () {
        if (this.userIndex >= 0) {
          let color = this.getColors[this.userIndex]
          return color[1]
        }
      },
      bgColor () {
        if (this.userIndex >= 0) {
          let color = this.getColors[this.userIndex]
          return color[0]
        }
      }

    },
    watch: {

    }

  }
</script>

