<template>
      <div class="schedule-list-container">
      <v-data-table
          :headers="headers"
          :items="items"
          dense
          :hide-default-footer="true"
          :disable-pagination="true"
          no-data-text="No Shifts"
          :show-expand="false"
          :item-class="row_classes"
          :fixed-header="true"
          class="filter-table"
          width="100%"
          height="500"
      >

          <template v-slot:item.rounding="{item}">
            <v-icon>{{item.rounding ? 'check_box' : 'check_box_outline_blank'}}</v-icon>
          </template>
          <template v-slot:item.catchall="{item}">
            <v-icon>{{item.catchall ? 'check_box' : 'check_box_outline_blank'}}</v-icon>
          </template>
          <template v-slot:item.outpatient="{item}">
            <v-icon>{{item.outpatient ? 'check_box' : 'check_box_outline_blank'}}</v-icon>
          </template>
          <template v-slot:item.rotation="{item}">
            <v-icon>{{item.rotation ? 'check_box' : 'check_box_outline_blank'}}</v-icon>
          </template>
          <template v-slot:item.assign_new_patient="{item}">
            <v-icon>{{item.assign_new_patient ? 'check_box' : 'check_box_outline_blank'}}</v-icon>
          </template>
          <template v-slot:item.assign_temporary="{item}">
            <v-icon>{{item.assign_temporary ? 'check_box' : 'check_box_outline_blank'}}</v-icon>
          </template>

          
          <template v-slot:item.actions="{item}">

            <v-tooltip 
                        bottom
                        nudge-bottom="5"
                        color="ycmd-black"
                        content-class="fade-0">
              <template v-slot:activator="{ on }">
                <v-icon
                  v-on="on"
                  color="ycmd-blue"
                  class="pointer"
                  :size="22"
                  @click="doSelect(item)"  
                >
                  edit
                </v-icon>
              </template>
              <span>Edit</span>
            </v-tooltip>

          </template>                       

      </v-data-table>
      </div>

</template>

<script>
import {mapActions, mapGetters} from "vuex";

import { parseJSON } from 'date-fns';
  export default {
    props: {
      shifts: {
          type: Array,
          default: () => []
      },
      filters: {
          type: Array,
          default: () => []
      },              
              
    },
    components: {

    },    
    data: () => ({
    }),
    mounted() {
    },
    methods: {
      ...mapGetters('schedule',['getFacilityById']),
      doSelect(item) {
        this.$emit('select', item)
      },
      translateShift(shift) {
        let s = JSON.parse(JSON.stringify(shift))
        s.startFormatted = this.formatTime(s.start)
        s.endFormatted = this.formatTime(s.end)
        if (s.filter) {
          let f = this.filterMap[s.filter]
          s.filterName = f ? f.name : s.filter

        }
        return s
      },
      twoDigit (num) {
        let str = num + ''
        if (str.length === 1) {
            str = '0' + str
        }
        return str
      },
      formatTime(time) {
        let pieces = time.split(':')
        let pm = false

        if (pieces[0] >= 12) {
          pieces[0] -= 12
          pm = true
        }
        return `${this.twoDigit(pieces[0])}:${this.twoDigit(pieces[1])}:${this.twoDigit(pieces[2])} ${pm ? 'PM': 'AM'}`
      },
      row_classes(item) {
        let classes = ['pa-0', 'ma-0']

        if (item.odd) {
          classes.push('odd')
        }

        return classes
      },      
    },
    computed: {
      filterMap(){
        let map={}
        this.filters.map(f => {
          map[f.id] = f
        })

        return map
      },
      items () {
        let arr = []
        let i=0
        this.shifts.map(s => {
          if (!s.personal) {
            let translated = this.translateShift(s)
            translated.odd = i++ % 2
            
            arr.push(translated)
          }
        })

        let sorted = arr.sort( (a,b) => {
            return a.name.localeCompare(b.name)
        })

        return sorted
      },
      headers () {
        let headers = [    
            {
                text: 'Name',
                value: 'name',
                width: '60%',
                sortable: true,
            },
            {
                text: 'Filter',
                value: 'filterName',
                width: '40%',
                sortable: true,
            },
            {
                text: 'Starts',
                value: 'startFormatted',
                width: '120px',
                sortable: true,
            },
            {
                text: 'Ends',
                value: 'endFormatted',
                width: '120px',
                sortable: true,
            },
            {
                text: 'Rounding',
                value: 'rounding',
                width: '80px',
                sortable: false,
            },
            {
                text: 'Catch-all',
                value: 'catchall',
                width: '90px',
                sortable: false,
            },  
            {
                text: 'Outpatient',
                value: 'outpatient',
                width: '80px',
                sortable: false,
            },          
            {
                text: 'Rotating',
                value: 'rotation',
                width: '80px',
                sortable: false,
            }, 
            {
                text: 'Assign New',
                value: 'assign_new_patient',
                width: '80px',
                sortable: false,
            },    
            {
                text: 'Assign Temp',
                value: 'assign_temporary',
                width: '80px',
                sortable: false,
            },                                                               
            {
                text: '',
                value: 'actions',
                width: '10px',
                align: 'center',
                sortable: false,
            },
        ]

        return headers
      },  
      actionList () {
        return []
      },

    },
    watch: {

    }
  }
</script>

