<template>
  <v-dialog
      v-model="showProviders"
      persistent
      :max-width="maxWidth"
      >
      <v-form
          ref="frmValidation"
          style="width: 100%; height: 100%;"
      >      
      <v-card>
          <v-card-title class="text-h5">
            {{title}}
          </v-card-title>
          <v-card-subtitle>
            {{subtitle}}
          </v-card-subtitle>

          <v-card-text>

            <v-container
            class="pa-0 ma-0 "                
            >        
              <v-row
                class="pa-0 ma-0"                     
              >
                <v-col cols="12"
                    class="pa-0 ma-0"
                >
                  <v-select
                      light
                      flat
                      outlined
                      solo
                      class="align-center"
                      :useIcon="false"
                      label="Select Pod/Subgroup"
                      :items="pods"
                      v-model="localPodId"
                      item-value="id"
                      item-text="name"                                    
                      dense
                      style="max-width: 300px;min-width: 234px;"   
                      hide-details  
                      loader-height="4"                        
                  />
                </v-col>
              </v-row>

              <v-row
                class="pa-0 ma-0"                     
              >
                <v-col cols="6"
                    class="pa-0 ma-0"
                >
                  <v-subheader>
                    Pod Providers
                  </v-subheader>    

                    <color-provider-list
                      :providers="podProviders"
                      :groupId="groupId"
                      :userIndexEdits="userIndexEdits"
                      @save="save"
                    />
                </v-col>
                <v-col cols="6"
                    class="pa-0 ma-0"
                >

                  <v-subheader>
                    Group Providers
                  </v-subheader>   

                    <color-provider-list
                      :providers="groupProviders"
                      :groupId="groupId"
                      :userIndexEdits="userIndexEdits"
                      @save="save"
                    />

                </v-col>
              </v-row>

              <v-row
                class="pa-0 ma-0"                     
              >
                <v-col cols="6"
                    class="pa-0 ma-0"
                >
                  <v-subheader>
                    Out of Group Providers
                  </v-subheader>

                    <color-provider-list
                      :providers="outOfGroupProviders"
                      :groupId="groupId"
                      :userIndexEdits="userIndexEdits"
                      @save="save"
                    />

                </v-col>

                <v-col cols="6"
                    class="pa-0 ma-0"
                >
<!--                
                  <user-picker
                      v-model="addUser"
                      label="Add a provider"
                      :roles="['provider']"
                      :include_searching_user="true" 
                      :single="true"
                      :showGroup="true"
                  />
-->
                  <v-subheader>
                    Add Providers
                  </v-subheader>
                  <ycmd-provider-search
                      :providerSearchSelected.sync="addUser"
                      :includeGroupHeaders="true"
                      :excludeUserIds="allUserIds"
                      :roles="['provider']"
                  />      

                  <v-btn
                    :disabled="!addUser"
                    @click="doAddUser"
                    :loading="addingUser"
                    class="float-right"
                    :width="100"
                  >
                    Add User
                  </v-btn>
            
                  
                </v-col>
              </v-row>
            </v-container>


          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
              <v-btn
                :disabled="!isDirty"
                @click="doSave"
                :loading="saving"
                :width="100"                
              >
                Save
              </v-btn>
              <v-btn
                @click="cancel"
                :width="100"
              >
                {{cancelCaption}}
              </v-btn>              

          </v-card-actions>
      </v-card>
      </v-form>
  </v-dialog>

</template>

<script>
import colorProvider from '@/views/apps/scheduling-new/components/colorProvider';
import colorProviderList from '@/views/apps/scheduling-new/components/colorProviderList';
import {mapActions, mapGetters} from "vuex";
import UserPicker from '@/components/searches/user-picker'
import YCMDProviderSearch from '@/components/searches/ycmd-provider';
  export default {
    props: {
      value: {
        default: '',
        type: String
      },
      'max-width': {
        default: '500',
        type: String
      },
      groupId: {
        default: '',
        type: String
      },
      selectedPod: {
        default: '',
        type: String
      },
      pods : {
        type: Array,
        default: () => []
      }   
    },
    components: {
        'color-provider': colorProvider,
        'color-provider-list': colorProviderList,
        'user-picker': UserPicker,
        'ycmd-provider-search': YCMDProviderSearch
    },
    data: () => ({
      selectedFacilities: [],
      selectedProviders: [],
      name: '',
      rules: {
          required: value => !!value || 'Required.',
          arrayRequired: (value) => {
            return !value ||  !value.length ? 'Required.' : true
          }
      },
      localPodId: null,
      userIndexEdits: {},
      showProviders: false,
      saving: false,
      addUser: null,
      addingUser: false
    }),
    mounted() {
    },
    methods: {
      ...mapActions('schedule',['saveColors','addUserToGroup']),   
      async doAddUser() {
        if (this.addUser) {
          this.addingUser = true
          let params = {
            user_id: this.addUser.id,
            group_id: this.groupId
          }

          let result = await this.addUserToGroup(params)

          this.addingUser = false
          this.addUser = null

        }
      },

      save (params) {
        if (params.provider_id) {
          this.$set(this.userIndexEdits,params.provider_id, params.index)
        }
      },
      async doSave (params) {
        this.saving = true
        //let group = this.$store.getters['schedule/getGroupById'](this.groupId)
        //let user_indexes = group.user_indexes
        let edits = this.userIndexEdits

        let result = await this.saveColors({
          groupId: this.groupId,
          edits
        })
        
        this.userIndexEdits={}
        this.saving = false
        this.$emit('cancel','cancel')
        
      },      
      cancel () {
        this.userIndexEdits={}
        this.saving = false
        this.$emit('cancel','cancel')
      },
    },
    computed: {
      cancelCaption () {
        return this.isDirty ? 'Cancel': 'Close'
      },
      isDirty () {
        let ret = false
        for (let id in this.userIndexEdits) {
          ret = true
        }

        return ret
      },
      allUserIds () {
        let ret = []

        let group = this.$store.getters['schedule/getGroupById'](this.groupId)

        if (group && group.users && group.users.length) {

          group.users.map(u => {
            ret.push(u.id)    
          })
        }

        return ret.length ? ret : null

      },
      groupProviders () {

        let ret = []
        let pmap = {}
        let pproviders = this.podProviders
        pproviders.map(p => {
          pmap[p.id] = true
        })

        let group = this.$store.getters['schedule/getGroupById'](this.groupId)

        if (group && group.users && group.users.length) {

          group.users.map(u => {    
            if (u.roleMap['provider']) {

              if (u.group && u.group.id == this.groupId){
                if (!pmap[u.id]) {
                  ret.push(u)
                  pmap[u.id]=true
                }
              } else {

              }
            }
          })
        }
        return ret
      },
      outOfGroupProviders () {

        let ret = []
        let pmap = {}
        let pproviders = this.podProviders
        pproviders.map(p => {
          pmap[p.id] = true
        })

        let group = this.$store.getters['schedule/getGroupById'](this.groupId)

        if (group && group.users && group.users.length) {

          group.users.map(u => {    
            if (u.roleMap['provider']) {

              if (u.group && u.group.id != this.groupId){
                if (!pmap[u.id]) {
                  ret.push(u)
                  pmap[u.id]=true
                }
              } 
            }
          })
        }
        return ret
      },      

      podProviders () {
        let po
        if (this.pods && this.pods.length) { 
          po = this.pods.find(p => {
            return p.id === this.localPodId
          })
        }

        let ret = []
        let group = this.$store.getters['schedule/getGroupById'](this.groupId)

        if (group) {
          let map = group.userMap

          if (po && po.user_ids && po.user_ids.length) {
            po.user_ids.map(id => {
              let provider = map[id]

              if (!provider) {
                provider = {
                  id: id,
                  first_name: 'Unknown',
                  last_name: 'Unknown'
                } 
              }

              ret.push(provider)
            })
          }

        }


        return ret
      },            
      subtitle () {
        return 'Add/edit Pod Providers'
      },
      title () {
        return 'Providers'
      },
      buttons () {
          return [
            {caption: 'OK'},
            {caption: 'Cancel'}
          ]
      },

    },
    watch: {
      value (newValue, oldValue) {

        if (newValue) {
          let pieces = newValue.split('|')
          this.localPodId = pieces[0]
          this.addUser = null
          this.showProviders = true
        } else {
          this.showProviders = false
        }
      }
    }

  }
</script>

